import {
  Box,
  Button,
  Divider,
  Modal,
  Paper,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Formik } from "formik";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import Scrollbar from "src/components/Scrollbar";
import * as Yup from "yup";
import OtpInput from "react-otp-input";
import { useMutation } from "@apollo/client";
import { VERIFY_CODE } from "src/graphql/mutations";
import toast from "react-hot-toast";
import useAuth from "src/hooks/useAuth";
import useMounted from "src/hooks/useMounted";
import { useNavigate } from "react-router";

const useStyles = makeStyles((theme) => ({
  textfield: {
    height: "46px",
    flexShrink: 0,
    borderRadius: "8px",
    "& .MuiOutlinedInput-root": {
      height: "46px",
      flexShrink: 0,
      borderRadius: "8px",
    },
  },
  label: {
    color: "#1B3861",
    fontSize: "14px",
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: 500,
  },
}));
interface RegModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setOtp: Dispatch<SetStateAction<string>>;
  otp: string;
  email: String;
}
const AuthenticationModal: FC<RegModalProps> = ({
  isOpen,
  setIsOpen,
  setOtp,
  otp,
  email,
}) => {
  const { t } = useTranslation();
  const { login } = useAuth() as any;
  const mounted = useMounted();
  const navigate = useNavigate();

  const handleClose = () => {
    // if (reason && reason == "backdropClick") {
    setIsOpen(false);
    // }
  };
  const yupObject = {};

  const validation = yupObject;

  const [handleOTP, { loading }] = useMutation(VERIFY_CODE);

  return (
    <>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backdropFilter: "blur(5px)",
        }}
        sx={{
          "& .MuiBox-root": {
            height: "fit-content",
            outline: "none",
          },
        }}
      >
        <Box
          sx={{
            p: 3,
            maxWidth: "512px",
            borderRadius: "16px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            opacity: 1,
            zIndex: 10,
            height: "100%",
          }}
        >
          <Formik
            enableReinitialize
            initialValues={{}}
            validationSchema={Yup.object().shape(validation)}
            onSubmit={async (
              values,
              { resetForm, setErrors, setStatus, setSubmitting }
            ): Promise<void> => {
              let variables = {
                otp,
                email,
              };

              toast.promise(handleOTP({ variables }), {
                loading: "Please wait...",
                success: (response) => {
                  console.log("response", response);
                  login(response.data.adminOTPVerification);
                  setIsOpen(false);
                  if (mounted.current) {
                    setStatus({ success: true });
                    setSubmitting(false);
                    navigate("/dashboard");
                    setOtp("");
                  }
                  return "Authenticated successfully";
                },
                error: (err) => {
                  return err.message;
                },
              });
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }): JSX.Element => (
              <form onSubmit={handleSubmit}>
                <Paper
                  elevation={12}
                  sx={{
                    width: "100%",
                    maxHeight: "90vh",
                    background: "#FFF",
                    p: 0.5,
                    overflow: "auto",
                    "&::-webkit-scrollbar": {
                      width: "7px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      background: "#aaa",
                      borderRadius: "6px",
                      pl: 3,
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                      background: "#a2a2a2",
                    },
                  }}
                >
                  <Scrollbar>
                    <Box sx={{ display: "flex", justifyContent: "end" }}>
                      <img
                        src="/images/cross.svg"
                        alt=""
                        style={{
                          cursor: "pointer",
                          padding: "10px 10px 0px 0px",
                        }}
                        onClick={handleClose}
                      />
                    </Box>
                    <Box
                      sx={{
                        padding: "00px 20px 0px 20px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Typography color="textPrimary" variant="h2">
                        Authentication required
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          lineHeight: "15.6px",
                          color: "rgba(10, 37, 64, 0.35)",
                          fontFamily: "Inter, Arial, sans-serif",
                          fontSize: "14px",
                          fontWeight: 500,
                          textAlign: "center",
                          mt: "5px",
                        }}
                      >
                        Please enter the code that you have received on your
                        email
                      </Typography>
                    </Box>
                    <Box sx={{ padding: "20px 20px 0px 20px" }}>
                      <Divider />
                    </Box>
                    <Box
                      sx={{
                        padding: "0 20px 20px 20px",
                        width: { sm: "500px", xs: "330px" },
                        mt: 2,
                      }}
                    >
                      <OtpInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        inputType="number"
                        renderSeparator={<span>-</span>}
                        renderInput={(props) => <input {...props} />}
                        inputStyle={{
                          height: "50px", // Customize height
                          margin: "5px", // Spacing between inputs
                          fontSize: "18px", // Adjust font size
                          borderRadius: "8px", // Rounded corners
                          border: "1px solid #ccc", // Border styling
                          textAlign: "center", // Center the text
                          width: "100%",
                        }}
                      />
                      {/* <Box sx={{ width: "100%" }}>
                        <TextField
                          error={Boolean(touched?.passcode && errors?.passcode)}
                          fullWidth
                          helperText={
                            touched?.passcode && (errors?.passcode as any)
                          }
                          label={t("Passcode")}
                          name="passcode"
                          onChange={handleChange}
                          value={values.passcode}
                          variant="outlined"
                          sx={{ mt: 1 }}
                        />
                      </Box> */}
                    </Box>

                    <Box sx={{ px: 3, pb: 3 }}>
                      <Button
                        color="primary"
                        fullWidth
                        variant="contained"
                        sx={{
                          display: "inline-flex",
                          height: "50px",
                          padding: "10px 24px",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "8px",
                          color: "#FFF",
                          background:
                            "linear-gradient(100deg, #FF5733 0.25%, #FD4C26 46.18%, #FA8D76 97.15%)",
                        }}
                        type="submit"
                        disabled={otp.length !== 6 || loading}
                      >
                        {t("Authenticate")}
                      </Button>
                    </Box>
                  </Scrollbar>
                </Paper>
              </form>
            )}
          </Formik>
        </Box>
      </Modal>
    </>
  );
};

export default AuthenticationModal;
