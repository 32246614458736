import { Box, ButtonBase, Popover, Typography } from "@material-ui/core";
import type { FC } from "react";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { PlatformDataArray, platformSettingsArray } from "src/constants";
import useAuth from "src/hooks/useAuth";

const PlatformMenu: FC = () => {
  const auth = useAuth();
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const location = useLocation();

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: "center",
          "svg path": {
            fill: "#86909F",
          },
          "&:hover": {
            "& .text-class": {
              color: "#FF5733",
            },
            "svg path": {
              fill: "#FF5733",
            },
          },
        }}
      >
        <ReactSVG src="/static/superData.svg" style={{ paddingTop: "2px" }} />
        <Typography
          sx={{ color: "#86909F", ml: 1, mt: "1px" }}
          variant="tableHeadFont"
          className="text-class"
        >
          {t("Platform")}
        </Typography>
        <ReactSVG src="/static/Union.svg" style={{ marginLeft: "8px" }} />
      </Box>

      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        sx={{
          "& .MuiPopover-paper,.MuiPaper-elevation8": {
            top: "63px !important",
            border: "1px solid #EDF1F6",
          },
        }}
        PaperProps={{
          sx: {
            width: 240,
            boxShadow: "30px 30px 46px 0px #0000001A",
            py: 1,
          },
        }}
      >
        {[
          {
            icon: "/static/settings1.svg",
            title: t("Platform settings"),
            path: `/dashboard/platforms/settings/${auth?.platformId}`,
            divider: true,
          },
          ,
          ...platformSettingsArray,
        ].map((item: any, index) => (
          <Box
            key={index}
            sx={{
              py: "7px",
              px: "20px",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              justifyContent: "space-between",
              background: location.pathname === item?.path ? "#FF57331A" : "",
              ".profile-text": {
                color: location.pathname === item?.path && "#FF5733",
              },
              "& .count": {
                color: location.pathname === item?.path && "white",
                background: location.pathname === item?.path && "#FF5733",
              },
              "&:hover": {
                ".profile-text": { color: "#FF5733" }, // Change text color on hover
                background: "#FF57331A",
                "& .count": {
                  color: "white",
                  background: "#FF5733",
                },
              },
            }}
            onClick={() => {
              navigate(item?.path);
              handleClose();
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <Typography
                className="profile-text"
                sx={{
                  color: "#0A2540",
                  transition: "color 0.3s", // Smooth color transition
                  lineHeight: "20px",
                }}
                variant="tableHeadFont"
              >
                {t(item?.title)}
              </Typography>
            </Box>

            {item?.count && (
              <Box
                sx={{
                  px: "8px",
                  py: "2px",
                  borderRadius: "20px",
                  background: "#0A25400D",
                  fontSize: "10px",
                  color: "#0A25408C",
                  height: "fit-content",
                }}
                className="count"
              >
                {(item?.sub_Title &&
                  auth?.dashboardStats?.[item?.sub_Title.toLowerCase()]) ??
                  0}
              </Box>
            )}
          </Box>
        ))}
      </Popover>
    </>
  );
};

export default PlatformMenu;
