import { gql } from "@apollo/client";

const UPDATE_LEAGUE_CATEGORY = gql`
  mutation updateCategory(
    $id: Int!
    $name: String!
    $gender: String!
    # $uniqueCode: String!
    $maxTournaments: Float
    $activeFrom: DateTime
    $activeTo: DateTime
    $sort: String!
    $ageLimit: Float
    $ageFrom: Int
    $ageTo: Int
    $ageLimitType: String
    $selectStage: String
    $countries: String!
    $timePeriod: String
    $countAllTournament: Boolean
    $penalty: Boolean
    $penaltyRule: String
    $specificTournament: Boolean
    $fixedPoints: Int
    $isDisplayClub: Boolean
    $onlyThirdPartyRanking: Boolean
    $isDisplayCity: Boolean
    $isDisplayBirthYear: Boolean
    $enableLicense: Boolean
    $ageFromType: String
    $ageToType: String
    $licenses: [Int!]
  ) {
    updateCategory(
      updateCategoryInput: {
        name: $name
        gender: $gender
        ageFrom: $ageFrom
        ageTo: $ageTo
        # uniqueCode: $uniqueCode
        maxTournaments: $maxTournaments
        activeFrom: $activeFrom
        activeTo: $activeTo
        sort: $sort
        ageLimit: $ageLimit
        ageLimitType: $ageLimitType
        selectStage: $selectStage
        id: $id
        countries: $countries
        timePeriod: $timePeriod
        countAllTournament: $countAllTournament
        penalty: $penalty
        penaltyRule: $penaltyRule
        specificTournament: $specificTournament
        fixedPoints: $fixedPoints
        isDisplayClub: $isDisplayClub
        onlyThirdPartyRanking: $onlyThirdPartyRanking
        isDisplayCity: $isDisplayCity
        isDisplayBirthYear: $isDisplayBirthYear
        enableLicense: $enableLicense
        ageFromType: $ageFromType
        ageToType: $ageToType
        licenses: $licenses
      }
    ) {
      id
      name
      gender
      # uniqueCode
      maxTournaments
      activeFrom
      activeTo
      sort
      ageLimit
      ageLimitType
      selectStage
      countries
    }
  }
`;

export default UPDATE_LEAGUE_CATEGORY;
