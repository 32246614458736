import { Box, Divider, Typography } from "@material-ui/core";
import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router";
import { ReactSVG } from "react-svg";
import useAuth from "src/hooks/useAuth";

export default function PlatformSettingsMenu({
  setIsPlatformSettings,
  platformSettingsArray,
  handleSelect,
  selected,
  setMenuName,
}: any) {
  const auth = useAuth();
  const navigate = useNavigate();
  const refDisplay = useRef(null);
  const handleScroll = () => {
    setTimeout(() => {
      if (refDisplay && refDisplay.current) {
        refDisplay.current.scrollIntoView({
          block: "start",
        });
      }
    }, 0);
  };

  useEffect(() => {
    handleScroll();
  }, []);
  return (
    <div>
      <Box ref={refDisplay} />
      <Box sx={{ mt: "15px" }}>
        <Box
          sx={{
            height: "25px",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            width: "100%",
          }}
          onClick={() => {
            setIsPlatformSettings(false);
            setMenuName("");
          }}
        >
          <ReactSVG src="/static/left_angle.svg" style={{ marginTop: "2px" }} />
          <Typography
            variant="tableHeadFont"
            sx={{ color: "#FF5733", ml: "5px" }}
          >
            Menu
          </Typography>
        </Box>
        <Box sx={{ py: "13px", display: { md: "none", xs: "block" } }}>
          <Typography variant="font18" sx={{ color: "#0A2540", px: "12px" }}>
            Settings
          </Typography>
        </Box>
        <Box sx={{ mt: "5px" }}>
          {[
            {
              icon: "/static/settings1.svg",
              title: "Platform settings",
              path: `/dashboard/platforms/settings/${auth?.platformId}`,
              divider: true,
            },
            ...platformSettingsArray,
          ]?.map((item: any, index) => (
            <Box sx={{ position: "relative" }}>
              <Box
                key={item?.title}
                onClick={() => {
                  handleSelect(index);
                  navigate(`${item?.path}?platformTab=settings`);
                }} // Set as selected on click
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  px: "12px",
                  height: "35px",
                  mt: "10px",
                  cursor: "pointer",
                  borderRadius: "8px",
                  transition: "background-color 0.3s ease",
                  "svg path": {
                    // fill: selected === index && "#FF5733",
                    fill:
                      (location.pathname.includes(
                        "/dashboard/platforms/settings/"
                      ) &&
                        index === 0) ||
                      location.pathname === item?.path
                        ? "#FF5733"
                        : "",
                  },
                  backgroundColor:
                    (location.pathname.includes(
                      "/dashboard/platforms/settings/"
                    ) &&
                      index === 0) ||
                    location.pathname === item?.path
                      ? "#FF57331A"
                      : "transparent", // Selected background color
                  "& .count": {
                    color: location.pathname === item?.path && "white",
                    background: location.pathname === item?.path && "#FF5733",
                  },
                  ":hover": {
                    backgroundColor: "#FF57331A", // Hover background color
                    "svg path": {
                      fill: "#FF5733", // Replace with your desired hover color for SVG
                    },
                    "& .title-text": {
                      color: "#FF5733", // Hover color for the title
                    },
                    "& .count": {
                      color: "white",
                      background: "#FF5733",
                    },
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <ReactSVG
                    src={item?.icon}
                    wrapper="svg"
                    style={{
                      height: "16px",
                      width: "16px",
                      transition: "fill 0.3s ease", // Smooth transition for fill
                    }}
                  />
                  <Typography
                    className="title-text" // Class added for hover styling
                    sx={{
                      color:
                        (location.pathname.includes(
                          "/dashboard/platforms/settings/"
                        ) &&
                          index === 0) ||
                        location.pathname === item?.path
                          ? "#FF5733"
                          : "#86909F",
                      fontSize: "13px",
                      fontWeight: "500",
                      ml: 1.2,
                      lineHeight: "20px",
                    }}
                  >
                    {item?.title}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  {item?.count && (
                    <Box
                      sx={{
                        px: "6px",
                        height: "19px",
                        borderRadius: "20px",
                        background: "#0A25400D",
                        fontSize: "10px",
                        color: "#0A25408C",
                        ml: 1.5,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      className="count"
                    >
                      {(item?.sub_Title &&
                        auth?.dashboardStats?.[
                          item?.sub_Title.toLowerCase()
                        ]) ??
                        0}
                    </Box>
                  )}
                </Box>
              </Box>
              {item?.divider && (
                <Divider sx={{ pt: "10px", borderColor: "#EFEFF0" }} />
              )}
            </Box>
          ))}
        </Box>
      </Box>
    </div>
  );
}
