import { useRef, useState } from "react";
import type { FC } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  IconButton,
  ListItemText,
  MenuItem,
  Popover,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { ReactSVG } from "react-svg";
import { BASE_URL } from "src/config";
import useAuth from "src/hooks/useAuth";
import toast from "react-hot-toast";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { Add } from "@material-ui/icons";
import { useQuery } from "@apollo/client";
import { ALLLEAGUESNAMES, LEAGUE_MANAGER_LEAGUE } from "src/graphql/queries";
import LockIcon from "@material-ui/icons/Lock";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

const LeaguePopover: FC = () => {
  const auth = useAuth();
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const token = Cookies.get("token");
  const [leagues, setLeagues] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredLeagues, setFilteredLeagues] = useState<any>([]);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
    setSearchTerm(""); // Reset search when closing popover
    setFilteredLeagues([...leagues]);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);

    if (!term) {
      setFilteredLeagues(leagues);
      return;
    }

    const filtered = leagues.filter((league: any) => {
      const title = league?.title?.toLowerCase() || "";
      return title.includes(term);
    });

    setFilteredLeagues(filtered);
  };

  const {
    loading,
    refetch,
    data: leaguesdata,
  } = useQuery(ALLLEAGUESNAMES, {
    skip: auth?.user?.isLeagueManager,
    variables: {
      filter: {
        // platform: auth.platformId,
      },
    },
    onCompleted: (data) => {
      const leaguesData = data?.allLeaguesNames || [];
      setLeagues(leaguesData);
      setFilteredLeagues(leaguesData);
    },
  });

  const { loading: isLeagueManagerLoading, data: leaguesForDashboard } =
    useQuery(LEAGUE_MANAGER_LEAGUE, {
      skip: !auth?.user?.isLeagueManager,
      onCompleted: (data) => {
        const leaguesData = data?.leaguesForDashboard || [];
        setLeagues(leaguesData);
        setFilteredLeagues(leaguesData);

        if (
          auth?.user?.isLeagueManager &&
          data?.leaguesForDashboard?.length > 0
        ) {
          navigate(
            `/dashboard/leagues/edit/${data?.leaguesForDashboard[0]?.id}?tab=league&activeTab=settings`
          );
        }
      },
    });

  let selectedLeague = leagues?.find(
    (item) => item?.id === auth?.leagueId
  )?.title;

  return (
    <>
      <Button
        size="small"
        color="primary"
        variant="contained"
        onClick={handleOpen}
        ref={anchorRef}
        endIcon={
          <ReactSVG
            src="/static/Union.svg"
            style={{ paddingBottom: "4px", marginLeft: "5px" }}
          />
        }
        sx={{
          height: "32px",
          width: { sm: "126px", xs: "120px" },
          borderRadius: "4px",
          backgroundColor: "#FAFAFA",
          fontSize: "12px",
          color: "#425466",
          border: `1px solid #EFEFF0`,
          "&:hover": {
            backgroundColor: "#FAFAFA",
            boxShadow: "none",
            border: "1px solid #FF5733",
            "& .text-class": {
              color: "#FF5733",
            },
            "svg path": {
              fill: "#FF5733",
            },
          },
        }}
      >
        <Typography
          className="text-class"
          variant="tableHeadFont"
          sx={{
            color: "#0A2540",
            width: { sm: "80px", xs: "70px" },
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            lineHeight: "30px",
          }}
        >
          {t("My Leagues")}
        </Typography>
      </Button>

      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        sx={{
          "& .MuiPopover-paper,.MuiPaper-elevation8": {
            top: "63px !important",
            border: "1px solid #EDF1F6",
          },
        }}
        PaperProps={{
          sx: {
            width: 252,
            maxHeight: "300px",
            boxShadow: "30px 30px 46px 0px #0000001A",
            position: "relative",
          },
        }}
      >
        <Box
          sx={{
            px: "20px",
            position: "fixed",
            zIndex: "11",
            background: "white",
            width: "inherit",
          }}
        >
          <TextField
            type="text"
            placeholder="Search leagues..."
            size="small"
            value={searchTerm}
            onChange={handleSearch}
            sx={{ width: "100%", py: "10px" }}
          />
        </Box>
        <Box sx={{ mt: "60px" }}>
          {loading || isLeagueManagerLoading ? (
            <Box sx={{ p: 2, textAlign: "center" }}>
              <Typography variant="body2">Loading...</Typography>
            </Box>
          ) : filteredLeagues.length > 0 ? (
            filteredLeagues.map((item) => (
              <Box
                key={item?.id}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  py: "7px",
                  px: "20px",
                  position: "relative",
                  ".profile-text": {
                    color: auth?.leagueId === item?.id && "#FF5733",
                  },
                  background: auth?.leagueId === item?.id && "#FF57331A",
                  "&:hover": {
                    ".profile-text": { color: "#FF5733" },
                    background: "#FF57331A",
                    svg: { color: "#FF5733" },
                  },
                }}
                onClick={() => {
                  navigate(
                    `/dashboard/leagues/edit/${item?.id}?tab=league&activeTab=settings`
                  );
                  auth?.getLeagueId(item?.id);
                  auth?.getPlatformId(item?.platform?.id);
                  handleClose();
                }}
              >
                {item.status?.toLowerCase() === "inactive" && (
                  <VisibilityOffIcon
                    sx={{
                      fontSize: "15px",
                      position: "absolute",
                      right: "20px",
                    }}
                  />
                )}

                <Box sx={{ borderRadius: "1.56px" }}>
                  <img
                    src={item?.logo ?? "/images/No_image.png"}
                    alt=""
                    style={{
                      height: "25px",
                      width: "25px",
                      objectFit: "contain",
                      borderRadius: "1.56px",
                      marginTop: "3px",
                    }}
                  />
                </Box>
                <Typography
                  className="profile-text"
                  sx={{
                    ml: 1.5,
                    color: "#0A2540",
                    transition: "color 0.3s",
                    width: "150px",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                  variant="tableHeadFont"
                >
                  {item?.title
                    ? item?.title?.charAt(0)?.toUpperCase() +
                      item?.title?.slice(1)
                    : ""}
                </Typography>
              </Box>
            ))
          ) : (
            <Box sx={{ pb: 2, textAlign: "center" }}>
              <Typography
                className="profile-text"
                sx={{
                  color: "#0A2540",
                  transition: "color 0.3s",
                }}
                variant="tableHeadFont"
              >
                {searchTerm
                  ? `No matching leagues found`
                  : "No leagues available"}
              </Typography>
            </Box>
          )}
        </Box>
      </Popover>
    </>
  );
};

export default LeaguePopover;
