import { Box, TextField, Typography } from "@material-ui/core";
import CustomButton, { getButtonStyles } from "./CustomButton";
import React, { ComponentProps } from "react";
import { SxProps } from "@material-ui/system";

type Props = ComponentProps<typeof TextField> & {
  size?: "large" | "medium" | "small" | "xs";
  parentStyle?: SxProps;
};

const CustomFormInput = ({
  error,
  size = "medium",
  sx = {},
  parentStyle = {},
  label,
  ...props
}: Props) => {
  const btnStyles = getButtonStyles("default", size, "outline");

  return (
    <Box sx={{ position: "relative", ...parentStyle }}>
      <TextField
        sx={{
          width: "100%",
          ".MuiFormHelperText-root": {
            pointerEvents: "none",
            position: "absolute",
            right: "10px",
            paddingInline: "4px",
            bottom: "-8px",
            background: "white",
            color: error ? "red" : "inherit",
          },
          ".MuiOutlinedInput-root": {
            ...btnStyles,
            paddingBlock: 0,
            paddingLeft: 0,
            background: "#FBFBFB",
            "&:active": { opacity: 1 },
            ...(error
              ? {
                  borderColor: "red",
                  "&:hover": { borderColor: "red" },
                  ".MuiOutlinedInput-input::placeholder": { color: "red" },
                }
              : {}),
            "& fieldset": {
              borderWidth: "0px",
            },
            "&:hover fieldset": {
              borderWidth: "0px",
              transform: "none",
            },
            "&.Mui-focused fieldset": {
              borderWidth: "0px",
            },
            "& > .MuiOutlinedInput-input": {
              padding: "0px !important",
              paddingInline:
                "padding" in btnStyles
                  ? `${btnStyles.padding} !important`
                  : "10px !important",
              height: "100% !important",
              color: error ? "red" : "inherit",
            },
            width: "100% !important",
            ...sx,
          },
        }}
        {...props}
      />
      <Typography
        variant="font21"
        sx={{
          position: "absolute",
          top: "-8px",
          left: "10px",
          paddingInline: "4px",
          background: "white",
          color: error ? "red" : "#86909F",
        }}
      >
        {label}
      </Typography>
    </Box>
  );
};

export default CustomFormInput;
