import { gql } from "@apollo/client";

const ALL_PLATFORM_NAMES = gql`
  query {
    allPlatformsNames {
      id
      title
      platformType
      status
      country

      sports {
        id
        sport {
          id
          title
          icon
        }
        createdAt
      }
      uploadFile
      coverPhoto
      lightLogo
      darkLogo
    }
  }
`;

export default ALL_PLATFORM_NAMES;
