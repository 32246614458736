export const THEMES = {
  LIGHT: "LIGHT",
  DARK: "DARK",
  NATURE: "NATURE",
};

export const SPORTS = {
  FISHING: "Fishing",
};

export const USER_ROLES = {
  SUPER_ADMIN: "superAdmin",
  USER: "user",
  ADMIN: "admin",
  ORGANIZER: "organizer",
  REFEREE: "referre",
  ATHLETE: "athlete",
  FEDERATION: "federation",
  COACH: "coach",
  MANAGER: "manager",
  ATHELETE: "athlete",
};

export const ALL_SPORTS = [
  {
    value: "Tennis",
    label: "Tennis",
  },
  {
    label: "Beach Tennis",
    value: "Beach Tennis",
  },
  {
    label: "Beach Volleyball",
    value: "Beach Volleyball",
  },
  {
    label: "Padel",
    value: "Padel",
  },
  {
    label: "Fishing",
    value: "Fishing",
  },
];

export const TournamentSkeletonLimit = [
  { id: 0 },
  { id: 1 },
  { id: 2 },
  { id: 3 },
  { id: 4 },
];

export const skeletonCount = [
  { key: 1 },
  { key: 2 },
  { key: 3 },
  { key: 4 },
  { key: 5 },
  { key: 6 },
  { key: 7 },
  { key: 8 },
  { key: 9 },
  { key: 10 },
];
export const UserRolesSettings = [
  "Athlete",
  "Federation member",
  "Club owner",
  "Club manager",
  "Referee",
  "Tournament organizer",
  "Sport fan",
  "Guest",
  "Other",
];
export const doubleAthletes = [
  {
    id: 1,
    athleteName: "J. Doe",
    athleteName1: "J. Feldmans",
    rank: "First Place",
    country: "Latvia",
    prize: "$1000",
    points: "50.0",
    color: "#FF8E25",
    icon: "/images/trophyn.svg",
  },
  {
    id: 2,
    athleteName: "J. Doe",
    athleteName1: "J. Feldmans",
    rank: "Second Place",
    country: "Latvia",
    prize: "$500",
    points: "47.0",
    color: "#8793B4",
    icon: "/images/trophy1.svg",
  },
  {
    id: 3,
    athleteName: "J. Doe",
    athleteName1: "J. Feldmans",
    rank: "Third Place",
    country: "Latvia",
    prize: "$1000",
    points: "50.0",
    color: "#B08B68",
    icon: "/images/trophy2.svg",
  },
  {
    id: 4,
    athleteName: "J. Doe",
    athleteName1: "J. Feldmans",
    rank: "Third Place",
    country: "Latvia",
    prize: "$1000",
    points: "50.0",
    color: "#B08B68",
  },
  {
    id: 5,
    athleteName: "J. Doe",
    athleteName1: "J. Feldmans",
    rank: "Third Place",
    country: "Latvia",
    prize: "$1000",
    points: "50.0",
    color: "#B08B68",
  },
  {
    id: 6,
    athleteName: "J. Doe",
    athleteName1: "J. Feldmans",
    rank: "Third Place",
    country: "Latvia",
    prize: "$1000",
    points: "50.0",
    color: "#B08B68",
  },
];
export const teamAthletes = [
  {
    id: 1,
    teamName: "Jevgenijs Feldmans",
    rank: "First Place",
    country: "Latvia",
    prize: "$1000",
    points: "50.0",
    color: "#FF8E25",
    icon: "/images/trophyn.svg",
  },
  {
    id: 2,
    teamName: "Jevgenijs Feldmans",
    rank: "Second Place",
    country: "Latvia",
    prize: "$500",
    points: "47.0",
    color: "#8793B4",
    icon: "/images/trophy1.svg",
  },
  {
    id: 3,
    teamName: "Jevgenijs Feldmans",
    rank: "Third Place",
    country: "Latvia",
    prize: "$1000",
    points: "50.0",
    color: "#B08B68",
    icon: "/images/trophy2.svg",
  },
  {
    id: 4,
    teamName: "Jevgenijs Feldmans",
    rank: "Third Place",
    country: "Latvia",
    prize: "$1000",
    points: "50.0",
    color: "#B08B68",
  },
  {
    id: 5,
    teamName: "Jevgenijs Feldmans",
    rank: "Third Place",
    country: "Latvia",
    prize: "$1000",
    points: "50.0",
    color: "#B08B68",
  },
  {
    id: 6,
    teamName: "Jevgenijs Feldmans",
    rank: "Third Place",
    country: "Latvia",
    prize: "$1000",
    points: "50.0",
    color: "#B08B68",
  },
];

export const singleAthletes = [
  {
    id: 1,
    athleteName: "Nick Fisher",
    rank: "First Place",
    country: "Latvia",
    prize: "$1000",
    points: "50.0",
    color: "#FF8E25",
    icon: "/images/trophyn.svg",
  },
  {
    id: 2,
    athleteName: "Nick Fisher",
    rank: "Second Place",
    country: "Latvia",
    prize: "$500",
    points: "47.0",
    color: "#8793B4",
    icon: "/images/trophy1.svg",
  },
  {
    id: 3,
    athleteName: "Nick Fisher",
    rank: "Third Place",
    country: "Latvia",
    prize: "$1000",
    points: "50.0",
    color: "#B08B68",
    icon: "/images/trophy2.svg",
  },
  {
    id: 4,
    athleteName: "Nick Fisher",
    rank: "Third Place",
    country: "Latvia",
    prize: "$1000",
    points: "50.0",
    color: "#B08B68",
  },
  {
    id: 5,
    athleteName: "Nick Fisher",
    rank: "Third Place",
    country: "Latvia",
    prize: "$1000",
    points: "50.0",
    color: "#B08B68",
  },
  {
    id: 6,
    athleteName: "Nick Fisher",
    rank: "Third Place",
    country: "Latvia",
    prize: "$1000",
    points: "50.0",
    color: "#B08B68",
  },
];

export const Status = [
  {
    label: "Active",
    value: "Active",
  },
  {
    label: "Inactive",
    value: "Inactive",
  },
];
export const Statuses = [
  {
    label: "active",
    value: "active",
  },
  {
    label: "inactive",
    value: "inactive",
  },
];

export const OrganizationTypes = [
  "International",
  "Federation",
  "National federation",
  "Local organization",
  "Club",
  "School",
  "Sports ministry",
  "Local organizer",
  "Event company",
  "Other",
];

export const SkeletonData = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 12];

export const ClubSteps = [
  {
    id: 1,
    label: "Appearance & Location",
  },
  {
    id: 2,
    label: "Contact details",
  },
  {
    id: 3,
    label: "Services & Resources",
  },
  {
    id: 4,
    label: "Club page",
  },
  {
    id: 5,
    label: "Management",
  },
];
export const GallerySteps = [
  {
    id: 1,
    label: "General & Appearance",
  },
  {
    id: 2,
    label: "Upload Photos",
  },
  {
    id: 3,
    label: "Manage Photos",
  },
];
export const GalleryAddSteps = [
  {
    id: 1,
    label: "General & Appearance",
  },
];
export const UserSteps = [
  {
    id: 1,
    label: "General",
  },
  {
    id: 2,
    label: "Career",
  },
  {
    id: 3,
    label: "Third party license",
  },
  {
    id: 4,
    label: "Password",
  },

  {
    id: 5,
    label: "Management",
  },
];

export const Gender = [
  {
    value: "Male",
    label: "Male",
  },
  {
    label: "Female",
    value: "Female",
  },
  // {
  //   label: "Other",
  //   value: "Anyone",
  // },
];

export const LeagueSteps = [
  {
    id: 1,
    label: "General & Appearance",
  },
  {
    id: 2,
    label: "Contact details",
  },
  {
    id: 3,
    label: "Fact sheet",
  },
  {
    id: 4,
    label: "Management",
  },
];
export const OrganizationSteps = [
  {
    id: 1,
    label: "Organization details",
  },
  {
    id: 2,
    label: "Custom fees",
  },
];
export const PlatformSteps = [
  {
    id: 0,
    icon: "/images/updated/1.svg",
    label: "Organization account",
  },
  {
    id: 1,
    icon: "/images/updated/2.svg",
    label: "Platform general",
  },
  {
    id: 2,
    icon: "/images/updated/3.svg",
    label: "Appearance",
  },
  {
    id: 3,
    icon: "/images/updated/4.svg",
    label: "Company details",
  },
  {
    id: 4,
    icon: "/images/updated/5.svg",
    label: "Landing & Navigation",
  },
  { id: 5, icon: "/images/updated/6.svg", label: "Integrations" },
  { id: 6, icon: "/images/updated/7.svg", label: "Additional requests" },
  { id: 7, icon: "/images/updated/7.svg", label: "Service fees" },
];

export const OrgTypes = [
  {
    title: "Local organization",
    value: "Local organization",
  },
  {
    title: "National organization",
    value: "National organization",
  },
  {
    title: "International organization",
    value: "International organization",
  },
  {
    title: "Sports club",
    value: "Sports club",
  },
  {
    title: "Local organizer",
    value: "Local organizer",
  },
  {
    title: "Sports ministry",
    value: "Sports ministry",
  },
  {
    title: "School",
    value: "School",
  },
  {
    title: "Union",
    value: "Union",
  },
];

export const Currency = [
  {
    title: "USD",
    value: "usd",
  },
  {
    title: "EUR",
    value: "eur",
  },
  {
    title: "GEL",
    value: "gel",
  },
  {
    title: "AED",
    value: "aed",
  },
];
export const CurrencyArray = [
  "AED",
  "BRL",
  "CHF",
  "CNY",
  "CZK",
  "EUR",
  "GBP",
  "GEL",
  "ILS",
  "INR",
  "JPY",
  "PLN",
  "RUB",
  "SAR",
  "USD",
];

export const SkillsArray = [
  "Beginners",
  "Intermediate",
  "Advanced",
  "Amateurs",
  "Pro",
  "Open",
  "ProAm",
  "1.0",
  "1.5",
  "2.0",
  "2.5",
  "3.0",
  "3.5",
  "4.0",
  "4.5",
  "5.0",
  "5.5",
  "5.5+",
];

export const PlatformTypes = [
  {
    title: "International",
    value: "International",
  },
  {
    title: "National",
    value: "National",
  },
  {
    title: "Local",
    value: "Local",
  },
];
export const SelectSports = [
  {
    img: "/images/updated/sport1.png",
    sport: "Tennis",
    count: "2054 Tournaments",
  },
  {
    img: "/images/updated/sport2.png",
    sport: "Beach Volleyball",
    count: "2054 Tournaments",
  },
  {
    img: "/images/updated/sport3.png",
    sport: "Beach Tennis",
    count: "2054 Tournaments",
  },
  {
    img: "/images/updated/sport4.png",
    sport: "Padel",
    count: "2054 Tournaments",
  },
  {
    img: "/images/updated/sport5.png",
    sport: "Sport Fishing",
    count: "2054 Tournaments",
  },
];
export const IntegrationQuestions = [
  {
    id: 0,
    name: "licenseMemberships",
    question: "Are you looking to have licences/memberships in the platform?",
    desc: "Make sure to get familiar with Tournated service fees",
    checked: false,
  },
  {
    id: 1,
    name: "onlinePayments",
    question: "Are you looking to have online payments for entry fees?",
    desc: "Make sure to get familiar with Tournated service fees",
    checked: false,
  },
  {
    id: 2,
    name: "onlineBookingCourts",
    question:
      "Are you looking to integrate online booking for courts? (coming soon)",
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    checked: false,
  },
  {
    id: 3,
    name: "liveStreaming",
    question: "Are you looking to integrate live streaming? (coming soon)",
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    checked: false,
  },
  {
    id: 4,
    name: "liveScoring",
    question: "Are you looking to integrate live scoring? (coming soon)",
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    checked: false,
  },
];
export const AdditionalServices = [
  {
    id: 0,
    serviceName: "Mobile app",
    price: "200 EUR/ Month",
    checked: false,
  },
  {
    id: 1,
    serviceName: "SMM",
    price: "400 EUR/ Month",
    checked: false,
  },
  {
    id: 2,
    serviceName: "Graphics for SMM",
    price: "300 EUR/ Month",
    checked: false,
  },
];
export const skillsLevels = [
  "Beginners",
  "Intermediate",
  "Advanced",
  "Amateurs Pro",
  "Open",
  "ProAm",
];
export const AgeGroup = [
  "U10",
  "U12",
  "U14",
  "U16",
  "U18",
  "Adults",
  "Seniors",
  "Open",
];
export const Timezones = [
  "Europe/Andorra",
  "Asia/Dubai",
  "Asia/Kabul",
  "Europe/Tirane",
  "Asia/Yerevan",
  "Antarctica/Casey",
  "Antarctica/Davis",
  "Antarctica/DumontDUrville", // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
  "Antarctica/Mawson",
  "Antarctica/Palmer",
  "Antarctica/Rothera",
  "Antarctica/Syowa",
  "Antarctica/Troll",
  "Antarctica/Vostok",
  "America/Argentina/Buenos_Aires",
  "America/Argentina/Cordoba",
  "America/Argentina/Salta",
  "America/Argentina/Jujuy",
  "America/Argentina/Tucuman",
  "America/Argentina/Catamarca",
  "America/Argentina/La_Rioja",
  "America/Argentina/San_Juan",
  "America/Argentina/Mendoza",
  "America/Argentina/San_Luis",
  "America/Argentina/Rio_Gallegos",
  "America/Argentina/Ushuaia",
  "Pacific/Pago_Pago",
  "Europe/Vienna",
  "Australia/Lord_Howe",
  "Antarctica/Macquarie",
  "Australia/Hobart",
  "Australia/Currie",
  "Australia/Melbourne",
  "Australia/Sydney",
  "Australia/Broken_Hill",
  "Australia/Brisbane",
  "Australia/Lindeman",
  "Australia/Adelaide",
  "Australia/Darwin",
  "Australia/Perth",
  "Australia/Eucla",
  "Asia/Baku",
  "America/Barbados",
  "Asia/Dhaka",
  "Europe/Brussels",
  "Europe/Sofia",
  "Atlantic/Bermuda",
  "Asia/Brunei",
  "America/La_Paz",
  "America/Noronha",
  "America/Belem",
  "America/Fortaleza",
  "America/Recife",
  "America/Araguaina",
  "America/Maceio",
  "America/Bahia",
  "America/Sao_Paulo",
  "America/Campo_Grande",
  "America/Cuiaba",
  "America/Santarem",
  "America/Porto_Velho",
  "America/Boa_Vista",
  "America/Manaus",
  "America/Eirunepe",
  "America/Rio_Branco",
  "America/Nassau",
  "Asia/Thimphu",
  "Europe/Minsk",
  "America/Belize",
  "America/St_Johns",
  "America/Halifax",
  "America/Glace_Bay",
  "America/Moncton",
  "America/Goose_Bay",
  "America/Blanc-Sablon",
  "America/Toronto",
  "America/Nipigon",
  "America/Thunder_Bay",
  "America/Iqaluit",
  "America/Pangnirtung",
  "America/Atikokan",
  "America/Winnipeg",
  "America/Rainy_River",
  "America/Resolute",
  "America/Rankin_Inlet",
  "America/Regina",
  "America/Swift_Current",
  "America/Edmonton",
  "America/Cambridge_Bay",
  "America/Yellowknife",
  "America/Inuvik",
  "America/Creston",
  "America/Dawson_Creek",
  "America/Fort_Nelson",
  "America/Vancouver",
  "America/Whitehorse",
  "America/Dawson",
  "Indian/Cocos",
  "Europe/Zurich",
  "Africa/Abidjan",
  "Pacific/Rarotonga",
  "America/Santiago",
  "America/Punta_Arenas",
  "Pacific/Easter",
  "Asia/Shanghai",
  "Asia/Urumqi",
  "America/Bogota",
  "America/Costa_Rica",
  "America/Havana",
  "Atlantic/Cape_Verde",
  "America/Curacao",
  "Indian/Christmas",
  "Asia/Nicosia",
  "Asia/Famagusta",
  "Europe/Prague",
  "Europe/Berlin",
  "Europe/Copenhagen",
  "America/Santo_Domingo",
  "Africa/Algiers",
  "America/Guayaquil",
  "Pacific/Galapagos",
  "Europe/Tallinn",
  "Africa/Cairo",
  "Africa/El_Aaiun",
  "Europe/Madrid",
  "Africa/Ceuta",
  "Atlantic/Canary",
  "Europe/Helsinki",
  "Pacific/Fiji",
  "Atlantic/Stanley",
  "Pacific/Chuuk",
  "Pacific/Pohnpei",
  "Pacific/Kosrae",
  "Atlantic/Faroe",
  "Europe/Paris",
  "Europe/London",
  "Asia/Tbilisi",
  "America/Cayenne",
  "Africa/Accra",
  "Europe/Gibraltar",
  "America/Godthab",
  "America/Danmarkshavn",
  "America/Scoresbysund",
  "America/Thule",
  "Europe/Athens",
  "Atlantic/South_Georgia",
  "America/Guatemala",
  "Pacific/Guam",
  "Africa/Bissau",
  "America/Guyana",
  "Asia/Hong_Kong",
  "America/Tegucigalpa",
  "America/Port-au-Prince",
  "Europe/Budapest",
  "Asia/Jakarta",
  "Asia/Pontianak",
  "Asia/Makassar",
  "Asia/Jayapura",
  "Europe/Dublin",
  "Asia/Jerusalem",
  "Asia/Kolkata",
  "Indian/Chagos",
  "Asia/Baghdad",
  "Asia/Tehran",
  "Atlantic/Reykjavik",
  "Europe/Rome",
  "America/Jamaica",
  "Asia/Amman",
  "Asia/Tokyo",
  "Africa/Nairobi",
  "Asia/Bishkek",
  "Pacific/Tarawa",
  "Pacific/Enderbury",
  "Pacific/Kiritimati",
  "Asia/Pyongyang",
  "Asia/Seoul",
  "Asia/Almaty",
  "Asia/Qyzylorda",
  "Asia/Qostanay", // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
  "Asia/Aqtobe",
  "Asia/Aqtau",
  "Asia/Atyrau",
  "Asia/Oral",
  "Asia/Beirut",
  "Asia/Colombo",
  "Africa/Monrovia",
  "Europe/Vilnius",
  "Europe/Luxembourg",
  "Europe/Riga",
  "Africa/Tripoli",
  "Africa/Casablanca",
  "Europe/Monaco",
  "Europe/Chisinau",
  "Pacific/Majuro",
  "Pacific/Kwajalein",
  "Asia/Yangon",
  "Asia/Ulaanbaatar",
  "Asia/Hovd",
  "Asia/Choibalsan",
  "Asia/Macau",
  "America/Martinique",
  "Europe/Malta",
  "Indian/Mauritius",
  "Indian/Maldives",
  "America/Mexico_City",
  "America/Cancun",
  "America/Merida",
  "America/Monterrey",
  "America/Matamoros",
  "America/Mazatlan",
  "America/Chihuahua",
  "America/Ojinaga",
  "America/Hermosillo",
  "America/Tijuana",
  "America/Bahia_Banderas",
  "Asia/Kuala_Lumpur",
  "Asia/Kuching",
  "Africa/Maputo",
  "Africa/Windhoek",
  "Pacific/Noumea",
  "Pacific/Norfolk",
  "Africa/Lagos",
  "America/Managua",
  "Europe/Amsterdam",
  "Europe/Oslo",
  "Asia/Kathmandu",
  "Pacific/Nauru",
  "Pacific/Niue",
  "Pacific/Auckland",
  "Pacific/Chatham",
  "America/Panama",
  "America/Lima",
  "Pacific/Tahiti",
  "Pacific/Marquesas",
  "Pacific/Gambier",
  "Pacific/Port_Moresby",
  "Pacific/Bougainville",
  "Asia/Manila",
  "Asia/Karachi",
  "Europe/Warsaw",
  "America/Miquelon",
  "Pacific/Pitcairn",
  "America/Puerto_Rico",
  "Asia/Gaza",
  "Asia/Hebron",
  "Europe/Lisbon",
  "Atlantic/Madeira",
  "Atlantic/Azores",
  "Pacific/Palau",
  "America/Asuncion",
  "Asia/Qatar",
  "Indian/Reunion",
  "Europe/Bucharest",
  "Europe/Belgrade",
  "Europe/Kaliningrad",
  "Europe/Moscow",
  "Europe/Simferopol",
  "Europe/Kirov",
  "Europe/Astrakhan",
  "Europe/Volgograd",
  "Europe/Saratov",
  "Europe/Ulyanovsk",
  "Europe/Samara",
  "Asia/Yekaterinburg",
  "Asia/Omsk",
  "Asia/Novosibirsk",
  "Asia/Barnaul",
  "Asia/Tomsk",
  "Asia/Novokuznetsk",
  "Asia/Krasnoyarsk",
  "Asia/Irkutsk",
  "Asia/Chita",
  "Asia/Yakutsk",
  "Asia/Khandyga",
  "Asia/Vladivostok",
  "Asia/Ust-Nera",
  "Asia/Magadan",
  "Asia/Sakhalin",
  "Asia/Srednekolymsk",
  "Asia/Kamchatka",
  "Asia/Anadyr",
  "Asia/Riyadh",
  "Pacific/Guadalcanal",
  "Indian/Mahe",
  "Africa/Khartoum",
  "Europe/Stockholm",
  "Asia/Singapore",
  "America/Paramaribo",
  "Africa/Juba",
  "Africa/Sao_Tome",
  "America/El_Salvador",
  "Asia/Damascus",
  "America/Grand_Turk",
  "Africa/Ndjamena",
  "Indian/Kerguelen",
  "Asia/Bangkok",
  "Asia/Dushanbe",
  "Pacific/Fakaofo",
  "Asia/Dili",
  "Asia/Ashgabat",
  "Africa/Tunis",
  "Pacific/Tongatapu",
  "Europe/Istanbul",
  "America/Port_of_Spain",
  "Pacific/Funafuti",
  "Asia/Taipei",
  "Europe/Kiev",
  "Europe/Uzhgorod",
  "Europe/Zaporozhye",
  "Pacific/Wake",
  "America/New_York",
  "America/Detroit",
  "America/Kentucky/Louisville",
  "America/Kentucky/Monticello",
  "America/Indiana/Indianapolis",
  "America/Indiana/Vincennes",
  "America/Indiana/Winamac",
  "America/Indiana/Marengo",
  "America/Indiana/Petersburg",
  "America/Indiana/Vevay",
  "America/Chicago",
  "America/Indiana/Tell_City",
  "America/Indiana/Knox",
  "America/Menominee",
  "America/North_Dakota/Center",
  "America/North_Dakota/New_Salem",
  "America/North_Dakota/Beulah",
  "America/Denver",
  "America/Boise",
  "America/Phoenix",
  "America/Los_Angeles",
  "America/Anchorage",
  "America/Juneau",
  "America/Sitka",
  "America/Metlakatla",
  "America/Yakutat",
  "America/Nome",
  "America/Adak",
  "Pacific/Honolulu",
  "America/Montevideo",
  "Asia/Samarkand",
  "Asia/Tashkent",
  "America/Caracas",
  "Asia/Ho_Chi_Minh",
  "Pacific/Efate",
  "Pacific/Wallis",
  "Pacific/Apia",
  "Africa/Johannesburg",
];
export const languageOptions = [
  { name: "Latvian", value: "lat" },
  { name: "English American", value: "eng_us" },
  { name: "English British", value: "eng_uk" },
  { name: "Spain", value: "esp" },
];
export const languages = [
  "Arabic",
  "Chinese",
  "Croatian",
  "Czech",
  "English",
  "Estonian",
  "French",
  "Georgian",
  "German",
  "Hindi",
  "Hungarian",
  "Italian",
  "Japanese",
  "Latvian",
  "Lithuanian",
  "Polish (Polski)",
  "Portuguese",
  "Russian",
  "Spanish",
  "Vietnamese",
];

export const thirdPartyLicenseSubtypes = [
  { label: "ITF Pro", value: "PRO" },
  { label: "ITF Juniors", value: "JUN" },
  { label: "ITF Wheelchair", value: "WCT" },
  { label: "ITF Masters Tour", value: "SEN" },
  { label: "ITF Beach Tennis", value: "BEA" },
  { label: "TE U16", value: "TEU16" },
  { label: "TE U14", value: "TEU14" },
];
export const thirdPartyLicenseDuprIdSubtypes = [
  { label: "Singles", value: "Singles" },
  { label: "Doubles", value: "Doubles" },
  { label: "Highest", value: "Highest" },
];

export const Templates = [
  {
    img: "/images/temp.png",
    selected: true,
  },

  // "/images/temp.png",
  // "/images/temp.png",
];

export const SportTypes = {
  fishing: "SPORTS_FISHING",
  pickleBall: "PICKLEBALL",
  beachVollyBall: "BEACH_VOLLYBALL",
  padel: "PADEL",
  beachTennis: "BEACH_TENNIS",
  tennis: "TENNIS",
};
export const segmentOptions = [
  {
    label: "Main",
    value: "MD",
  },
  {
    label: "Consolation",
    value: "consolation",
  },
  {
    label: "Qualification",
    value: "Q",
  },
];

export const DrawFormates = [
  {
    label: "Single-elimination draw",
    value: "single_elimination_draw",
  },
  {
    label: "Single-elimination draw + Groups",
    value: "single_elimination_draw_+_groups",
  },
  {
    label: "Double-elimination draw",
    value: "double_elimination_draw",
  },
  {
    label: "Live standings",
    value: "live_standing",
  },
  {
    label: "Manual results",
    value: "manual_results",
  },
];
export const LoginSocialIcons = [
  {
    key: "google",
    path: "/images/loginGoogle.svg",
  },
  {
    key: "facebook",
    path: "/images/loginFb.svg",
  },
  {
    key: "twitter",
    path: "/images/loginTwitter.svg",
  },
];

export const StatusArray = ["Active", "Inactive"];
export const rowPerPage = 25;

export const isProd = window.location.host === "dashboard.tournated.com";
export const isDev = window.location.host === "dashboard.spadasoft.com";
export const Period = {
  TWELVE_MONTHS: "12 months",
  UNTIL_END_OF_CURRENT_YEAR: "Until end of current year",
};
export const EntryFeeData = [
  {
    tournatedFixedEntryFee: "",
    partnerFixedEntryFee: "",
    tournatedEntryFee: "4.5",
    partnerEntryFee: "",
    rangeStart: 1,
    rangeEnd: 9.99,
    minFee: "1",
    maxFee: "",
    partnerMin: "",
    partnerMax: "",
  },
  {
    tournatedFixedEntryFee: "",
    partnerFixedEntryFee: "",
    tournatedEntryFee: "4.5",
    partnerEntryFee: "",
    rangeStart: 10,
    rangeEnd: 24.99,
    minFee: "1",
    maxFee: "",
    partnerMin: "",
    partnerMax: "",
  },
  {
    tournatedFixedEntryFee: "",
    partnerFixedEntryFee: "",
    tournatedEntryFee: "4.5",
    partnerEntryFee: "",
    rangeStart: 25,
    rangeEnd: 49.99,
    minFee: "1",
    maxFee: "",
    partnerMin: "",
    partnerMax: "",
  },
  {
    tournatedFixedEntryFee: "",
    partnerFixedEntryFee: "",
    tournatedEntryFee: "4.5",
    partnerEntryFee: "",
    rangeStart: 50,
    rangeEnd: 99.99,
    minFee: "1",
    maxFee: "",
    partnerMin: "",
    partnerMax: "",
  },
  {
    tournatedFixedEntryFee: "",
    partnerFixedEntryFee: "",
    tournatedEntryFee: "4.5",
    partnerEntryFee: "",
    rangeStart: 100,
    rangeEnd: 199.99,
    minFee: "1",
    maxFee: "",
    partnerMin: "",
    partnerMax: "",
  },
  {
    tournatedFixedEntryFee: "",
    partnerFixedEntryFee: "",
    tournatedEntryFee: "4.5",
    partnerEntryFee: "",
    rangeStart: 200,
    rangeEnd: 499.99,
    minFee: "1",
    maxFee: "",
    partnerMin: "",
    partnerMax: "",
  },
  {
    tournatedFixedEntryFee: "",
    partnerFixedEntryFee: "",
    tournatedEntryFee: "4.5",
    partnerEntryFee: "",
    rangeStart: 500,
    rangeEnd: 100000,
    minFee: "1",
    maxFee: "",
    partnerMin: "",
    partnerMax: "",
  },
];
export const LicenseFeeData = [
  {
    tournatedFixedEntryFee: "",
    partnerFixedEntryFee: "",
    tournatedEntryFee: "12",
    partnerEntryFee: "",
    rangeStart: 1,
    rangeEnd: 9.99,
    minFee: "2.5",
    maxFee: "",
    partnerMin: "",
    partnerMax: "",
  },
  {
    tournatedFixedEntryFee: "",
    partnerFixedEntryFee: "",
    tournatedEntryFee: "12",
    partnerEntryFee: "",
    rangeStart: 10,
    rangeEnd: 24.99,
    minFee: "2.5",
    maxFee: "",
    partnerMin: "",
    partnerMax: "",
  },
  {
    tournatedFixedEntryFee: "",
    partnerFixedEntryFee: "",
    tournatedEntryFee: "12",
    partnerEntryFee: "",
    rangeStart: 25,
    rangeEnd: 49.99,
    minFee: "2.5",
    maxFee: "",
    partnerMin: "",
    partnerMax: "",
  },
  {
    tournatedFixedEntryFee: "",
    partnerFixedEntryFee: "",
    tournatedEntryFee: "12",
    partnerEntryFee: "",
    rangeStart: 50,
    rangeEnd: 99.99,
    minFee: "2.5",
    maxFee: "",
    partnerMin: "",
    partnerMax: "",
  },
  {
    tournatedFixedEntryFee: "",
    partnerFixedEntryFee: "",
    tournatedEntryFee: "12",
    partnerEntryFee: "",
    rangeStart: 100,
    rangeEnd: 199.99,
    minFee: "2.5",
    maxFee: "",
    partnerMin: "",
    partnerMax: "",
  },
  {
    tournatedFixedEntryFee: "",
    partnerFixedEntryFee: "",
    tournatedEntryFee: "12",
    partnerEntryFee: "",
    rangeStart: 200,
    rangeEnd: 499.99,
    minFee: "2.5",
    maxFee: "",
    partnerMin: "",
    partnerMax: "",
  },
  {
    tournatedFixedEntryFee: "",
    partnerFixedEntryFee: "",
    tournatedEntryFee: "12",
    partnerEntryFee: "",
    rangeStart: 500,
    rangeEnd: 100000,
    minFee: "2.5",
    maxFee: "",
    partnerMin: "",
    partnerMax: "",
  },
];

export const superDataArray = [
  {
    title: "Platforms",
    path: "/dashboard/platforms",
  },
  {
    title: "Organizations",
    path: "/dashboard/organization-account",
  },
  {
    title: "Leagues",
    path: "/dashboard/leagues",
  },
  {
    title: "Users",
    path: "/dashboard/users",
  },
  {
    title: "Tournaments",
    path: "/dashboard/allTournaments",
  },
  {
    title: "Sports",
    path: "/dashboard/sports",
  },
  {
    title: "Disciplines",
    path: "/dashboard/disciplines",
  },
  {
    title: "Third-party licences",
    path: "/dashboard/third-party-license",
  },
  {
    title: "Partners",
    path: "/dashboard/partners",
  },
  {
    title: "Transactions",
    path: "/dashboard/transactions",
  },
  {
    title: "Subscription",
    path: "/dashboard/subscriptions",
  },
];
export const financeArray = [
  {
    title: "Payments history",
    path: "#",
  },
  {
    title: "Reports",
    path: "#",
  },
  {
    title: "Transactions",
    path: "#",
  },
  {
    title: "Billing details",
    path: "#",
  },
  {
    title: "Deposit / Withdraw ",
    path: "#",
  },
];

export const helpArray = [
  {
    title: "Documentation",
    type: "redirect",
    link: "https://tournated.gitbook.io/tournated/tournated-portals/tournated-dashboard",
  },
  {
    title: "Contact us",
    type: "open",
    link: "hello@tournated.com",
  },
  {
    title: "Submit a ticket",
    type: "redirect",
    link: "https://discord.gg/mzhdJAU9NX",
  },
];

export const PlatformDataArray = [
  {
    title: "Organizations",
    path: "/dashboard/platform-organization-account",
  },
  {
    title: "Leagues",
    path: "/dashboard/platform-leagues",
  },
  {
    title: "Users",
    path: "/dashboard/platform-users",
  },
  {
    title: "Tournaments",
    path: "/dashboard/platform-tournaments",
  },
  {
    title: "Sports",
    path: "/dashboard/platform-sports",
  },
  {
    title: "Disciplines",
    path: "/dashboard/platform-disciplines",
  },
  {
    title: "Third-party licences",
    path: "/dashboard/platform-third-party-license",
  },
  {
    title: "Partners",
    path: "/dashboard/platform-partners",
  },
  {
    title: "Transactions",
    path: "/dashboard/platform-transactions",
  },
];

export const leagueSetupData = [
  {
    title: "settings",
    path: "settings",
  },
  {
    title: "tournaments",
    path: "tournaments",
  },
  {
    title: "tournament categories",
    path: "tournament_categories",
  },
  {
    title: "ranking categories",
    path: "ranking_categories",
  },
  {
    title: "ranking points",
    path: "ranking_points",
  },
  {
    title: "rankings",
    path: "rankings",
  },
  {
    title: "rankings history",
    path: "rankings_history",
  },
  {
    title: "tournament results",
    path: "tournament_results",
  },
  {
    title: "partners",
    path: "partners",
  },
];

export const platformSetupData = [
  {
    title: "General",
    path: "general",
  },
  {
    title: "Sports",
    path: "sports",
  },
  {
    title: "Disciplines",
    path: "disciplines",
  },
  {
    title: "Third-party licences",
    path: "third_party_licences",
  },
  // {
  //   title: "Team members",
  //   path: "team_members",
  // },
];

export const leagueTips = [
  {
    title: "Unlimited Categories",
    descritpion:
      "Set up as many tournament and ranking categories as you need. Define filters for age, gender, third-party affiliations, licenses, and more. We offer complete flexibility to suit your needs.",
  },
  {
    title: "Automated Rankings",
    descritpion:
      "Create a custom ranking points system and update rankings in just a few clicks. Our platform integrates with international rankings and rating systems, enabling you to build fairer and more accurate rankings.",
  },
  {
    title: "Licenses & Monetization",
    descritpion:
      "Design custom licenses and memberships, configure service fees, and boost your organization’s revenue. Enabling licenses makes it easier to manage users and streamline participant selection when manually adding entries.",
  },
];
export const onboardingTips = [
  {
    title: "Team & Suborganizations",
    descritpion:
      "Build your team, including platform managers, organization managers, organizers, and referees. Connect associated clubs and suborganizations. Adding accounts to suborganizations grants them dashboard access, allowing them to set up and manage their own leagues.",
  },
  {
    title: "Unlimited Leagues",
    descritpion:
      "Set up as many leagues or tours as needed and assign team members accordingly. Within each league, create unlimited categories, configure rankings, manage licenses, track transactions, and more.",
  },
  {
    title: "Unlimited Tournaments",
    descritpion:
      "Create unlimited tournaments with advanced settings and registration features. Assign unlimited organizers and referees. Support singles, doubles, and team formats. Enable online payments, partner searches, and other integrations to enhance the tournament experience.",
  },
  {
    title: "Licenses & Monetization",
    descritpion:
      "Design custom licenses and memberships, configure service fees, and boost your organization’s revenue. Enabling licenses makes it easier to manage users and streamline participant selection when manually adding entries.",
  },
];

export const newFeatures = [
  {
    title: "Team Format",
    descritpion: `We have implemented a basic team format. Platform users can now create teams, add members, and register for events. Team names and members are displayed across all tournament screens. The next stage is to develop the "Davis Cup" team format.`,
  },
  {
    title: "Full DUPR Integration",
    descritpion:
      "We have fully integrated DUPR. By making the DUPR connection mandatory for your event, players cannot register for the tournament without the proper connection. All matches are easily exportable to DUPR. Additionally, you can download scorecard templates from the Order of Play page by clicking the download icon.",
  },
  {
    title: "Auto-Generate Draws from Groups",
    descritpion: `Once the group stage is complete, you can now click "Generate Draws from Groups," select the relevant template, and automatically generate draws. More templates will be added soon.`,
  },
  {
    title: "Stripe Connect",
    descritpion:
      "We have implemented a feature allowing sports organizations to automatically receive funds directly into their accounts, eliminating the need to send invoices manually.",
  },
];

export const platformSettingsArray = [
  {
    icon: "/static/leagues1.svg",
    title: "Sub-organizations",
    sub_Title: "organizations",
    path: "/dashboard/platform-organization-account",
    count: 25,
  },
  {
    icon: "/static/teams.svg",
    title: "Team",
    sub_Title: "Team",
    path: "/dashboard/platform-members",
    count: 22,
    divider: true,
  },
  {
    icon: "/static/tournaments.svg",
    title: "Users",
    sub_Title: "Users",

    path: "/dashboard/platform-users",
    count: 49023,
  },
  {
    icon: "/static/coaches.svg",
    title: "Coaches",
    sub_Title: "Coaches",

    path: "/dashboard/coaches",
    count: 32,
  },
  {
    icon: "/static/clubs.svg",
    title: "Clubs",
    sub_Title: "Clubs",

    path: "/dashboard/clubs",
    count: 12,
  },
  {
    icon: "/static/leagues1.svg",
    title: "Leagues",
    sub_Title: "Leagues",

    path: "/dashboard/platform-leagues",
    count: 54,
  },
  {
    icon: "/static/tournaments.svg",
    title: "Tournaments",
    sub_Title: "Tournaments",

    path: "/dashboard/platform-tournaments",
    count: 324,
  },
  {
    icon: "/static/news.svg",
    title: "News",
    sub_Title: "News",

    path: "/dashboard/posts",
    count: 12,
  },
  {
    icon: "/static/teams.svg",
    title: "Teams",
    sub_Title: "Teams",

    path: "/dashboard/teams",
    count: 3,
    divider: true,
  },
  {
    icon: "/static/transactions.svg",
    title: "Transactions",
    sub_Title: "Transactions",
    count: 3,
    path: "/dashboard/platform-transactions",
  },
  {
    icon: "/static/document.svg",
    title: "Licences history",
    sub_Title: "purchased",
    count: 3,

    path: "/dashboard/platform-purchased-licenses",
    divider: true,
  },
  {
    icon: "/static/banners.svg",
    title: "Banners",
    sub_Title: "Banners",

    path: "/dashboard/banners",
  },
  {
    icon: "/static/partner.svg",
    title: "Partners",
    sub_Title: "Partners",

    path: "/dashboard/platform-partners",
  },
  {
    icon: "/static/document.svg",
    title: "Documents",
    sub_Title: "Documents",

    path: "/dashboard/documents",
  },

  {
    icon: "/static/gallery.svg",
    title: "Gallery",
    sub_Title: "Gallery",

    path: "/dashboard/gallery",
  },
];

export const platformTabs = [
  {
    label: "Organization account",
    value: "organization_account",
  },
  {
    label: "Platform general",
    value: "platform_general",
  },
  {
    label: "Appearance",
    value: "appearance",
  },
  {
    label: "Billing details",
    value: "company_details",
  },
  // {
  //   label: "Landing & Navigation",
  //   value: "landing_navigation",
  // },
  // {
  //   label: "Integrations",
  //   value: "integrations",
  // },
  {
    label: "Additional requests",
    value: "additional_requests",
  },
  {
    label: "Service fees",
    value: "service_fees",
  },
];
export function customEncodeURIComponent(str) {
  str = str?.replace(/\s+/g, "-");
  // Encode the string
  return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
    return "%" + c.charCodeAt(0).toString(16);
  });
}
export const onboardingArray = [
  {
    title: "Organization",
    icon: "/static/organization.svg",
    description: "Submit mandatory organization details",
    remaining: 0,
    orgComponents: [
      {
        icon: "/static/organization_details.svg",
        title: "Complete configuration",
        path: "/dashboard/organization-account/new",
        complete: true,
      },
      // {
      //   icon: "/static/team.svg",
      //   title: "Add team",
      //   path: "/dashboard/leagues-members/new",
      //   complete: false,
      // },
      // {
      //   icon: "/static/license_org.svg",
      //   title: "Licenses",
      //   path: "/dashboard/licenses/new",
      //   complete: false,
      // },
    ],
  },
  {
    title: "Create platform",
    icon: "/static/create_platform.svg",
    description: "Complete platform setup",
    remaining: 2,
    orgComponents: [
      {
        icon: "/static/add_platform.svg",
        title: "Create the platform",
        path: "/dashboard/organization-account/new",
        complete: false,
      },
      {
        icon: "/static/team.svg",
        title: "Set up the team (platform managers)",
        path: "/dashboard/platform-members/new",
        complete: false,
      },
      // {
      //   icon: "/static/add_club.svg",
      //   title: "Add club",
      //   path: "/dashboard/clubs/new",
      //   complete: false,
      // },
      // {
      //   icon: "/static/sub_org.svg",
      //   title: "Add sub organization",
      //   path: "/dashboard/platform-members/new",
      //   complete: false,
      // },
    ],
  },
  {
    title: "League",
    icon: "/static/league-menu.svg",
    description: "Create and set up your first league",
    remaining: 6,
    orgComponents: [
      {
        icon: "/static/add_platform.svg",
        title: "Create a league",
        path: "/dashboard/leagues/new",
        complete: false,
      },
      {
        icon: "/static/add_club.svg",
        title: "Set up tournament categories",
        path: "/dashboard/tournament-categories/new",
        complete: false,
      },
      {
        icon: "/static/team.svg",
        title: "Set up the team (managers, organizers, referees)",
        path: "/dashboard/leagues-members/new",
        complete: false,
      },
      {
        icon: "/static/ranking_category.svg",
        title: "Configure ranking categories",
        path: "/dashboard/ranking-categories/new",
        complete: false,
      },
      {
        icon: "/static/ranking_point.svg",
        title: "Assign ranking points",
        path: "/dashboard/ranking-points",
        complete: false,
      },
      {
        icon: "/static/license_org.svg",
        title: "Set up licenses",
        path: "/dashboard/licenses/new",
        complete: false,
      },
    ],
  },
  {
    title: "Other",
    icon: "/static/create_platform.svg",
    description: "Enhance your platform with content",
    remaining: 8,
    orgComponents: [
      {
        icon: "/static/add_platform.svg",
        title: "Venues",
        path: "/dashboard/clubs",
        complete: false,
      },
      {
        icon: "/static/team.svg",
        title: "Communities",
        path: "/dashboard/clubs",
        complete: false,
      },
      {
        icon: "/static/team.svg",

        title: "Teams",
        path: "/dashboard/teams",
        complete: false,
      },
      {
        icon: "/static/add_club.svg",
        title: "Coaches",
        path: "/dashboard/coaches",
        complete: false,
      },
      {
        icon: "/static/sub_org.svg",
        title: "News",
        path: "/dashboard/posts",
        complete: false,
      },
      {
        icon: "/static/ranking_category.svg",
        title: "Gallery",
        path: "/dashboard/gallery",
        complete: false,
      },
      {
        icon: "/static/ranking_point.svg",
        title: "Banners",
        path: "/dashboard/banners",
        complete: false,
      },
      {
        icon: "/static/license_org.svg",
        title: "Partners",
        path: "/dashboard/platform-partners",
        complete: false,
      },
    ],
  },
];

export const YEARS = [
  "2020",
  "2021",
  "2022",
  "2023",
  "2024",
  "2025",
  "2026",
  "2027",
  "2028",
  "2029",
  "2030",
];
