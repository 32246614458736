import { gql } from "@apollo/client";

const STATISTICS = gql`
  query allStatsData(
    $date: String
    $platform: Int
    $federationId: Int
    $startDate: String
    $endDate: String
  ) {
    allStatsData(
      date: $date
      platform: $platform
      federationId: $federationId
      endDate: $endDate
      startDate: $startDate
    )
  }
`;

export default STATISTICS;
