import { gql } from "@apollo/client";

const GET_COACHES = gql`
  query platformCoaches(
    $name: String
    $country: String
    $sports: [Int!]
    $filter: ListCoachInput
    $page: Int
    $limit: Int
  ) {
    platformCoaches(
      name: $name
      country: $country
      sports: $sports
      filter: $filter
      page: $page
      limit: $limit
    ) {
      platformCoaches {
        id
        userRole
        category
        platform {
          id
          title
        }
        category
        sport{
          title
          icon
        }

        clubs{
          clubName
          logo
        }
        dateFrom
        dateTo
        user {
          id
          name
          surname
          email
          nation
          city
          avatar
          citizenship
        }
        status
        sport {
          id
          icon
        }
        students {
          id
          user {
            id
            name
            surname
            email
          }
        }
        clubs {
          id
          clubName
          city
          country
          logo
          tournaments {
            id
          }
          clubServices {
            id
            services {
              sport {
                id
                title
                icon
              }
            }
          }
          federation {
            id
          }
          members {
            id
            user {
              id
              name
              email
            }
            userRole
          }
        }
      }
      total
    }
  }
`;

export default GET_COACHES;
