import {
  useEffect,
  useState,
  useMemo,
  useCallback,
  Fragment,
  useRef,
} from "react";
import type { ChangeEvent, FC } from "react";
import {
  Link as RouterLink,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Drawer,
  ListItem,
  ListItemText,
  Menu,
  Autocomplete,
  TextField,
  CircularProgress,
  IconButton,
  Typography,
  Tabs,
  Tab,
  Divider,
  Tooltip,
  InputAdornment,
} from "@material-ui/core";
import { useLazyQuery, useQuery } from "@apollo/client";
import SearchIcon from "src/icons/Search";
import Cookies from "js-cookie";

import type { Theme } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useAuth from "../../hooks/useAuth";
import NavSection from "../NavSection";
import Scrollbar from "../Scrollbar";
import { ChevronLeft } from "@material-ui/icons";
import {
  ALLLEAGUESNAMES,
  ALL_PLATFORM_NAMES,
  DASHBOARD_STATS,
  LEAGUES,
  LEAGUE_BY_ID,
  LEAGUE_MANAGER_LEAGUE,
  ONBOARDING_DATA,
  ORGANIZATION_PLATFORMS,
  USER_ROLE,
} from "src/graphql/queries";
import { ReactSVG } from "react-svg";
import { USER_ROLES, platformSettingsArray, rowPerPage } from "src/constants";
import { ChevronRight } from "@material-ui/icons";
import ChevronDown from "src/icons/ChevronDown";
import { PLATEFORM } from "src/graphql/queries/plateform";
import useDebouncedTerm from "src/hooks/useDebouncedTerm";
import PLATFORM_BY_ID from "src/graphql/queries/getplatformById";
import CustomAutocomplete from "../customComponents/CustomAutocomplete";

import React from "react";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import SidebarResponsive from "./mobileScreens/sidebarMenu";
import PlatformSettingsMenu from "./mobileScreens/platformSettings";
import OrganizationLeagueMenu from "./mobileScreens/organizationLeagueMenu";
import SuperDataMenu from "./mobileScreens/superDataMenu";
import FinancialMenu from "./mobileScreens/financeMenu";
import HelpMenu from "./mobileScreens/helpMenu";
import DASHBOARD_ORGANIZATION_STATS from "src/graphql/queries/dashboardOrganizationsStats";
import LanguagePopover from "./LanguagePopover";
import PlatformSelect from "../platformSelection";
import { useTranslation } from "react-i18next";
import {
  isFederationEmail,
  isLeagueManager,
  isPlatformManager,
} from "src/utils/helper-functions";
import LeaguesMenu from "./mobileScreens/LeaguesMenu";
import PlatformMenu from "./mobileScreens/PlatformMenu";

const BorderLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 5,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: "#FF57331A",
    },
    bar: {
      borderRadius: 5,
      backgroundColor: "#FF5733",
    },
  })
)(LinearProgress);

interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

const sidebarMenu = [
  {
    icon: "/static/team_member1.svg",
    title: "Team members",
    identity: "Team members",
    path: "/dashboard/leagues-members",
  },
  {
    icon: "/static/licenses1.svg",
    title: "Licenses",
    identity: "Licenses",
    path: "/dashboard/licenses",
    divider: true,
  },
  {
    icon: "/static/leagues1.svg",
    title: "Leagues",
    identity: "Leagues",

    path: "/dashboard/organization_leagues",
    count: 54,
  },
  {
    icon: "/static/tournaments.svg",
    title: "Tournaments",
    identity: "Tournaments",

    path: "/dashboard/tournaments",
    count: 324,
  },
  {
    icon: "/static/clubs.svg",
    title: "Clubs",
    identity: "Clubs",

    path: "/dashboard/organzaition-clubs",
    count: 12,
  },
  {
    icon: "/static/coaches.svg",
    title: "Coaches",
    identity: "Coaches",

    path: "/dashboard/coaches",
    count: 32,
  },
  {
    icon: "/static/news.svg",
    title: "News",
    identity: "News",

    path: "/dashboard/organization-posts",
    count: 12,
  },

  {
    icon: "/static/purchase_license.svg",
    title: "Licences history",
    identity: "licenses",

    path: "/dashboard/purchased-licenses",
    count: 12,
  },

  {
    icon: "/static/transactions.svg",
    title: "Transactions",
    identity: "Transactions",

    path: "/dashboard/organization-transactions",
    count: 12,
    divider: true,
  },
  {
    icon: "/static/gallery.svg",
    title: "Gallery",
    identity: "Gallery",

    path: "/dashboard/organization-gallery",
  },
];

const tabs = [
  {
    label: "Settings",
    value: "setting",
  },
  {
    label: "Leagues",
    value: "league",
  },
];

const sections2 = [
  {
    title: "Super Data",
    icon: "/images/tournament-icon.svg",
    rolesAllowed: [USER_ROLES.SUPER_ADMIN, USER_ROLES.SUPER_ADMIN],
    subItems: [
      {
        title: "Platforms",
        path: "/dashboard/platforms",
        new: "/dashboard/platforms/new",
        icon: "/images/super-admin.svg",
      },
      {
        title: "Leagues",
        path: "/dashboard/leagues",
        icon: "/images/leagues.svg",
      },
      {
        title: "Organization Accounts",
        path: "/dashboard/organization-account",
        new: "/dashboard/organization-account/new",
        icon: "/images/user-gear.svg",
      },
      {
        title: "Users",
        path: "/dashboard/users",
        new: "/dashboard/users/new",
        icon: "/images/user-gear.svg",
      },
      {
        title: "All Tournaments",
        path: "/dashboard/allTournaments",
        icon: "/images/tournaments.svg",
      },
      {
        title: "Sports",
        path: "/dashboard/sports",
        new: "/dashboard/sports/new",
        icon: "/images/tournaments.svg",
      },
      {
        title: "Disciplines",
        path: "/dashboard/disciplines",
        new: "/dashboard/disciplines/new",
        icon: "/images/tournaments.svg",
      },
      {
        title: "Third-party licences",
        path: "/dashboard/third-party-license",
        new: "/dashboard/third-party-license/new",
        icon: "/images/tournaments.svg",
      },
      {
        title: "Partners",
        path: "/dashboard/partners",
        new: "/dashboard/partners/new",
        icon: "/images/partners.svg",
      },

      // {
      //   title: "Pricing",
      //   path: "/dashboard/pricing",
      //   icon: "/images/tournaments.svg",
      // },
      // {
      //   title: "Subscriptions",
      //   path: "/dashboard/subscriptions",
      //   icon: "/images/tournaments.svg",
      // },
      {
        title: "Transactions",
        path: "/dashboard/transactions",
        icon: "/images/tournaments.svg",
      },
    ],
  },
];

const sections = [
  // {
  //   title: "Related Data",
  //   rolesAllowed: [USER_ROLES.FEDERATION],
  //   icon: "/images/tournament-icon.svg",
  //   subItems: [
  //     {
  //       title: "Platforms",
  //       path: "/dashboard/platforms",
  //     },
  //     {
  //       title: "Leagues",
  //       path: "/dashboard/leagues",
  //     },
  //   ],
  // },
  {
    title: "Data",
    icon: "/images/data-icon.svg",
    rolesAllowed: [
      USER_ROLES.ADMIN,
      USER_ROLES.SUPER_ADMIN,
      // USER_ROLES.ORGANIZER,
      // USER_ROLES.FEDERATION,
      // USER_ROLES.ATHELETE,
      // USER_ROLES.USER,
    ],
    subItems: [
      {
        title: "Pending Tournaments",
        path: "/dashboard/pending-tournaments",
        icon: "/images/license.svg",
      },

      {
        title: "Athletes",
        path: "/dashboard/athletes",
        new: "/dashboard/athletes/new",
        icon: "/images/athlete-icon.svg",
      },
    ],
  },
  {
    title: "Financials",
    icon: "/images/financials-icon.svg",
    rolesAllowed: [
      USER_ROLES.ADMIN,
      USER_ROLES.SUPER_ADMIN,
      // USER_ROLES.ORGANIZER,
      USER_ROLES.FEDERATION,
      // USER_ROLES.ATHELETE,
      // USER_ROLES.USER,
    ],
    disable: true,
    subItems: [
      {
        title: "Accounting",
        path: "/dashboard/accounting",
        icon: "/images/license.svg",
      },
    ],
  },
  {
    title: "Extra",
    icon: "/images/extra-icon.svg",
    rolesAllowed: [
      USER_ROLES.ADMIN,
      USER_ROLES.SUPER_ADMIN,
      // USER_ROLES.ORGANIZER,
      USER_ROLES.FEDERATION,
      // USER_ROLES.ATHELETE,
      // USER_ROLES.USER,
    ],
    disable: true,
    subItems: [
      {
        title: "Live scoring",
        path: "/dashboard/live-score",
        icon: "/images/member.svg",
      },
      {
        title: "Live stream",
        path: "/dashboard/live-stream",
        icon: "/images/member.svg",
      },
    ],
  },
];

const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  const { getOrganizationId, getOrganizationUserId } = useAuth();
  const auth = useAuth();
  const { getUserPlatformRole } = useAuth();
  const { getOnboardingData } = useAuth();
  useQuery(USER_ROLE, {
    skip: !auth?.platformId,
    variables: {
      platformId: auth?.platformId,
    },
    onCompleted: (userRoleData) => {
      getUserPlatformRole(userRoleData?.userRole);
    },
  });

  // onboarding API
  const { loading, data: onboardingData } = useQuery(ONBOARDING_DATA, {
    skip:
      !auth?.platformId &&
      !auth?.organizationUserId &&
      !auth?.platformRelationalId &&
      !auth?.userPlatformRole,
    variables: {
      platform: auth?.platformId,
      organizationPlatformId: auth?.platformRelationalId,
      userId: auth?.organizationUserId,
      userRole: auth?.userPlatformRole,
    },
    onCompleted: (data) => {
      getOnboardingData(data?.fetchOnboardingDetails);
      let isSkipOnboarding = Cookies.get("isSkipOnboarding");
      if (
        parseInt(data?.fetchOnboardingDetails?.percentage) < 100 &&
        !isSkipOnboarding
      ) {
        if (
          auth?.user?.role === "federation" &&
          auth?.organizationUserId === auth?.user?.id
        ) {
          navigate("/dashboard/onboarding");
        }
      }
    },
    fetchPolicy: "network-only",
  });

  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab"); // Get the 'tab' query parameter
  const [platformId, setPlatformId] = useState<any>("");
  const [leagueId, setLeagueId] = useState<any>("");
  const [organizationId, setOrganizationId] = useState<any>("");
  const [organizationUserId, setOrganizationUserId] = useState<any>("");
  const [currentTab, setCurrentTab] = useState<string>(
    tab === "league" ? "league" : "setting"
  );
  const [isDisplayPlatform, setIsDisplayPlatform] = useState(false);

  const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
    if (value === "league") {
      setIsLeagues(true);

      navigate(`${window.location?.pathname}?tab=league&activeTab=settings`);
    } else {
      setIsLeagues(false);
      navigate(`${window.location?.pathname}?tab=settings`);
    }
    setCurrentTab(value);
  };

  const [selected, setSelected] = useState(null); // State to track the selected div
  useEffect(() => {
    if (isLeagueManager(auth)) {
      setCurrentTab("league");
      setIsLeagues(true);
    } else {
      if (tab === "league") {
        setCurrentTab("league");
        setIsLeagues(true);
      } else {
        setIsLeagues(false);
        setCurrentTab("setting");
      }
    }
  }, [tab]);

  const handleSelect = (index) => {
    setSelected(index); // Update the selected div index
  };
  const [friends, setFriends] = useState<any[]>(auth?.user?.friends || []);
  // const [open, setOpen] = useState(false);
  // console.log("open @@@", open);

  useEffect(() => {
    if (auth?.platformId) {
      setPlatformId(auth?.platformId);
    }
    if (auth?.leagueId) {
      setLeagueId(auth.leagueId);
    }
    if (auth?.organizationId) {
      setOrganizationId(auth?.organizationId);
    }
    if (auth?.organizationUserId) {
      setOrganizationUserId(auth?.organizationUserId);
    }
  }, [
    auth?.platformId,
    auth.leagueId,
    auth?.organizationId,
    auth?.organizationUserId,
  ]);
  const [TopSections, setTopSections] = useState<any>([]);
  const [platformSection, setPlatformSection] = useState([]);
  const [platformSectionArray, setPlatformSectionArray] = useState([]);
  useEffect(() => {
    if (platformId && auth.user.role) {
      const isFederationEmail =
        auth?.user?.email === auth?.platformData?.federation?.user?.email;
      const isMemberOfPlatform = auth?.user?.member_of_plateforms?.some(
        (item) => item.platform?.id === platformId
      );
      setPlatformSectionArray([
        {
          title: "Platform",
          rolesAllowed:
            // auth?.user?.role !== "superAdmin" &&
            // auth?.user?.role !== "admin" &&
            // parseInt(process.env.REACT_APP_TOURNATED_PLATFORM_ID) ===
            //   auth?.platformId
            isFederationEmail
              ? [USER_ROLES.FEDERATION, USER_ROLES.SUPER_ADMIN]
              : isMemberOfPlatform
              ? [
                  USER_ROLES.FEDERATION,
                  USER_ROLES.SUPER_ADMIN,
                  USER_ROLES.ADMIN,
                  USER_ROLES.ATHELETE,
                  USER_ROLES.COACH,
                  USER_ROLES.ORGANIZER,
                  USER_ROLES.REFEREE,
                  USER_ROLES.USER,
                ]
              : [USER_ROLES.SUPER_ADMIN],

          icon: "/images/tournament-icon.svg",
          subItems: [
            {
              title: "Platform Settings",
              path: `/dashboard/platforms/settings/${platformId}`,
              icon: "/images/leagues.svg",
            },

            {
              title: "Team",
              path: "/dashboard/platform-members",
              new: "/dashboard/platform-members/new",
              icon: "/images/user-gear.svg",
            },
            // {
            //   title: "Leagues",
            //   path: "/dashboard/platform-leagues",
            //   icon: "/images/user-gear.svg",
            // },
            {
              title: "Organization Accounts",
              path: "/dashboard/platform-organization-account",
              icon: "/images/user-gear.svg",
            },
            {
              title: "Users",
              path: "/dashboard/platform-users",
              new: "/dashboard/platform-users/new",
              icon: "/images/user-gear.svg",
            },
            {
              title: "Coaches",
              path: "/dashboard/coaches",
              new: "/dashboard/coaches/new",
              icon: "/images/coaches.svg",
            },
            {
              title: "Tournaments",
              path: "/dashboard/platform-tournaments",
              icon: "/images/tournaments.svg",
            },
            {
              title: "Sports",
              path: "/dashboard/platform-sports",
              // new: "/dashboard/sports/new",
              icon: "/images/tournaments.svg",
            },
            {
              title: "Disciplines",
              path: "/dashboard/platform-disciplines",
              new: "/dashboard/platform-disciplines/new",
              icon: "/images/tournaments.svg",
            },
            {
              title: "Third-party licences",
              path: "/dashboard/platform-third-party-license",
              new: "/dashboard/third-party-license/new",
              icon: "/images/tournaments.svg",
            },
            {
              title: "Partners",
              path: "/dashboard/platform-partners",
              new: "/dashboard/platform-partners/new",
              icon: "/images/partners.svg",
            },
            {
              title: "Posts",
              path: "/dashboard/posts",
              new: "/dashboard/posts/new",

              icon: "/images/news.svg",
            },
            {
              title: "Clubs",
              path: "/dashboard/clubs",
              new: "/dashboard/clubs/new",

              icon: "/images/clubs.svg",
            },
            // {
            //   title: "Purchased Licenses",
            //   path: "/dashboard/purchased-licenses",
            //   new: "/dashboard/purchased-licenses/new",

            //   icon: "/images/license.svg",
            // },
            {
              title: "Teams",
              path: "/dashboard/teams",
              new: "/dashboard/teams/new",
              icon: "/images/teams.svg",
            },

            // {
            //   title: "Clubs",
            //   path: "/dashboard/clubs",
            //   new: "/dashboard/clubs/new",

            //   icon: "/images/clubs.svg",
            // },
            {
              title: "Banners",
              path: "/dashboard/banners",
              new: "/dashboard/banners/new",

              icon: "/images/clubs.svg",
            },
            {
              title: "Documents",
              path: "/dashboard/documents",
              new: "/dashboard/documents/new",
              icon: "/images/partners.svg",
            },
            {
              title: "Gallery",
              path: "/dashboard/gallery",
              new: "/dashboard/gallery/new",
              icon: "/images/partners.svg",
            },
            {
              title: "Transactions",
              path: "/dashboard/platform-transactions",
              icon: "/images/tournaments.svg",
            },
            // {
            //   title: "Platforms",
            //   path: "/dashboard/organization-platforms",
            //   icon: "/images/user-gear.svg",
            // },

            // {
            //   title: "Platform Members",
            //   path: "/dashboard/platform-members",
            //   new: "/dashboard/organization-account/new",
            //   icon: "/images/user-gear.svg",
            // },
          ],
        },
        {
          title: "Organization",
          rolesAllowed: [
            USER_ROLES.FEDERATION,
            USER_ROLES.SUPER_ADMIN,
            USER_ROLES.MANAGER,
          ],

          icon: "/images/federations.svg",
          subItems: [
            {
              title: "Organization details",
              path: `/dashboard/organization-account/settings/${organizationId}?userId=${organizationUserId}`,
              icon: "/images/leagues.svg",
            },
            {
              title: "Platforms",
              path: `/dashboard/my-platforms`,
              icon: "/images/leagues.svg",
            },

            {
              title: "Leagues",
              path: "/dashboard/platform-leagues",
              icon: "/images/user-gear.svg",
            },
            {
              title: "Team Members",
              path: "/dashboard/leagues-members",
              new: "/dashboard/leagues-members/new",

              icon: "/images/member.svg",
            },

            {
              title: "Tournaments",
              path: "/dashboard/tournaments",
              icon: "/images/license.svg",
            },
            {
              title: "Licenses",
              path: "/dashboard/licenses",
              new: "/dashboard/licenses/new",
              icon: "/images/license.svg",
            },

            {
              title: "Purchased Licenses",
              path: "/dashboard/purchased-licenses",
              new: "/dashboard/purchased-licenses/new",

              icon: "/images/license.svg",
            },
            {
              title: "Clubs",
              path: "/dashboard/organzaition-clubs",
              new: "/dashboard/organzaition-clubs/new",

              icon: "/images/clubs.svg",
            },
            {
              title: "Gallery",
              path: "/dashboard/organization-gallery",
              new: "/dashboard/gallery/new",
              icon: "/images/partners.svg",
            },
            {
              title: "Transactions",
              path: "/dashboard/organization-transactions",
              icon: "/images/tournaments.svg",
            },
          ],
        },

        {
          title: "Control Panel",
          icon: "/images/generel-settings-icon.svg",
          rolesAllowed: [USER_ROLES.FEDERATION, USER_ROLES.SUPER_ADMIN],
          subItems: [
            {
              title: "Instructions",
              path: "/dashboard",
              icon: "/images/leagues.svg",
            },
            {
              title: "Create League",
              path: "/dashboard/leagues/new",
              new: "/dashboard/leagues/new",
              icon: "/images/leagues.svg",
            },
          ],
        },
      ]);
    }
    setTopSections([
      {
        title: "League",
        icon: "/images/leagues-icon.svg",
        // rolesAllowed: [USER_ROLES.FEDERATION, USER_ROLES.SUPER_ADMIN],
        subItems: [
          {
            title: "Settings",
            path: `/dashboard/leagues/settings/${leagueId}`,
            icon: "/images/leagues.svg",
          },
          {
            title: "Tournaments",
            path: "/dashboard/league-tournaments",
            icon: "/images/license.svg",
          },

          {
            title: "Tournament Categories",
            path: "/dashboard/tournament-categories",
            new: "/dashboard/tournament-categories/new",

            icon: "/images/tournaments.svg",
          },
          {
            title: "Ranking Categories",
            path: "/dashboard/ranking-categories",
            new: "/dashboard/ranking-categories/new",

            icon: "/images/rank-cats.svg",
          },
          {
            title: "Ranking Points",
            path: "/dashboard/ranking-points",

            icon: "/images/ranking.svg",
          },

          {
            title: "Rankings",
            path: "/dashboard/rankings",
            icon: "/images/ranking.svg",
          },
          {
            title: "Rankings History",
            path: "/dashboard/rankings-history",
            icon: "/images/partners.svg",
          },
          {
            title: "Tournament Results",
            path: "/dashboard/tournament-results",
            icon: "/images/partners.svg",
          },
          {
            title: "Partners",
            path: "/dashboard/league-partners",
            new: "/dashboard/league-partners/new",
            icon: "/images/partners.svg",
          },
        ],
      },
    ]);
  }, [platformId, leagueId, auth.user, auth?.platformData, organizationId]);

  const navigate = useNavigate();
  const { onMobileClose, openMobile } = props;
  const { t } = useTranslation();
  const location = useLocation();
  const {
    user,
    getLeagueId,
    getLeaguesData,
    getPlatformId,
    getSportId,
    getPlatformLanguages,
    getPlatformData,
    getPlatformRelationalId,
  } = useAuth();
  const [menuName, setMenuName] = useState("");
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));
  const [open, setOpen] = useState(false);
  const [data, setData] = useState<any>([]);
  const [isLeagues, setIsLeagues] = useState(tab === "league" ? true : false);
  const [searchLeague, setSearchLeague] = useState("");

  const handleGetId = (id: number | string, index?: number) => {
    getPlatformId(id);
    let organizationData;
    if (auth.user.role === "superAdmin") {
      organizationData = platforms.find((item) => item?.id === id);
      getPlatformRelationalId(organizationData?.[0]?.id);
    } else {
      organizationData = OrganizationPlatforms?.organizationPlatforms.find(
        (item) => item?.platform?.id === id
      );
      getPlatformRelationalId(organizationData?.id);
    }
    if (location.pathname.includes("/dashboard/platforms/settings")) {
      navigate(`/dashboard/platforms/settings/${id}${window.location?.search}`);
    }
  };

  const setLeague = (leagues) => {
    let findedLeague = leagues?.find((item) => item?.id === auth?.leagueId);
    // if there is league length is zero then return and not excute the funtion
    if (leagues?.length === 0) return;

    // Letast code for new design

    if (!findedLeague) {
      if (
        location.pathname.includes("/dashboard/leagues/edit") ||
        location.pathname === "/dashboard/leagues/new"
      ) {
        if (leagues[0]?.id !== undefined) {
          navigate(
            `/dashboard/leagues/edit/${leagues[0]?.id}?tab=league&activeTab=settings`
          );
        } else {
          navigate(`/dashboard/leagues/new?tab=league&activeTab=settings`);
        }
      }
      getLeagueId(leagues[0]?.id);
    }
    if (findedLeague) {
      getLeagueId(findedLeague?.id);
      if (
        location.pathname.includes("/dashboard/leagues/edit") ||
        location.pathname === "/dashboard/leagues/new"
      ) {
        navigate(
          `/dashboard/leagues/edit/${findedLeague?.id}?tab=league&activeTab=settings`
        );
      }
    }
  };
  // const {
  //   loading,
  //   refetch,
  //   data: leaguesdata,
  // } = useQuery(ALLLEAGUESNAMES, {
  //   skip: auth?.user?.isLeagueManager,
  //   variables: {
  //     filter: {
  //       platform: auth.platformId,
  //     },
  //   },
  //   onCompleted: (data) => {
  //     setLeague(data?.allLeaguesNames);
  //     // let findedLeague = data?.allLeaguesNames?.find(
  //     //   (item) => item?.id === auth?.leagueId
  //     // );
  //     // if (!auth?.leagueId || !findedLeague) {
  //     //   if (location.pathname.includes("/dashboard/leagues/settings")) {
  //     //     if (data?.allLeaguesNames[0]?.id !== undefined) {
  //     //       navigate(
  //     //         `/dashboard/leagues/settings/${data?.allLeaguesNames[0]?.id}`
  //     //       );
  //     //     }
  //     //   }

  //     //   getLeagueId(data?.allLeaguesNames[0]?.id);
  //     // }
  //   },
  // });

  const { loading: isLeagueManagerLoading, data: leaguesForDashboard } =
    useQuery(LEAGUE_MANAGER_LEAGUE, {
      skip: !auth?.user?.isLeagueManager,
      onCompleted: (data) => {
        setLeague(data?.leaguesForDashboard);
      },
      fetchPolicy: "network-only",
    });

  const [leagueIndex, setLeagueIndex] = useState<number | string>(
    auth.leagueId
  );
  const [userSectionsState, setUserSections] = useState(sections);
  const [adminSectionsState, setAdminSections] = useState(sections2);

  useEffect(() => {
    if (!isLeagueManagerLoading) {
      if (leaguesForDashboard?.leaguesForDashboard.length > 0) {
        setData(leaguesForDashboard?.leaguesForDashboard);
      } else {
        setData([]);
        // getLeagueId(undefined);
      }
    }
  }, [leaguesForDashboard, isLeagueManagerLoading]);
  const { data: leagueDataRes } = useQuery(LEAGUES, {
    skip: auth?.user?.isLeagueManager,
    variables: {
      filter: {
        platform: auth?.platformId,
      },
      federationId: auth?.organizationId,
      relations: [
        "owner.federation",
        "tournaments",
        "athlete",
        "sports.discipline",
        "platform",
      ],
    },
    onCompleted: (leagueDataResponse) => {
      setData(leagueDataResponse?.leagues);
      setLeague(leagueDataResponse?.leagues);
    },
    context: {
      queryDeduplication: false,
    },
    fetchPolicy: "no-cache",
  });
  // useEffect(() => {
  //   if (auth.platformId) {
  //     refetch();
  //   }
  // }, [auth.platformId, auth?.leagueId]);
  // useEffect(() => {
  //   if (leaguesdata?.allLeaguesNames.length > 0) {
  //     if (auth.leagueId) {
  //       let findedLeague = leaguesdata?.allLeaguesNames?.find(
  //         (item) => item?.id === auth?.leagueId
  //       );
  //       getSportId(findedLeague?.sports?.id);
  //     }
  //   }
  // }, [leaguesdata?.allLeaguesNames, auth.leagueId]);

  const selectUserSections = useCallback((user) => {
    if (user) {
      const filteredArray = sections.filter((element: any) =>
        element?.rolesAllowed?.some((subElement) =>
          subElement?.includes(user.role)
        )
      );

      setUserSections(filteredArray);
    } else {
      setUserSections(sections);
    }
  }, []);

  const selectAdminSections = useCallback((user) => {
    if (user) {
      const filteredArray = sections2.filter((element: any) =>
        element?.rolesAllowed?.some((subElement) =>
          subElement?.includes(user.role)
        )
      );

      setAdminSections(filteredArray);
    } else {
      setAdminSections(sections2);
    }
  }, []);
  const selectCommonSections = useCallback((user) => {
    if (user) {
      const filteredArray = TopSections.filter((element: any) =>
        element?.rolesAllowed?.some((subElement) =>
          subElement?.includes(user.role)
        )
      );

      setTopSections(filteredArray);
    } else {
      setTopSections(TopSections);
    }
  }, []);

  useEffect(() => {
    if (user && platformSectionArray.length > 0) {
      const filteredArray = platformSectionArray.filter((element: any) =>
        element?.rolesAllowed?.some((subElement) =>
          subElement?.includes(user.role)
        )
      );
      setPlatformSection(filteredArray);
    } else {
      setPlatformSection(platformSection);
    }
  }, [user, platformSectionArray]);

  useEffect(() => {
    selectUserSections(auth.user);
    selectAdminSections(auth.user);
    selectCommonSections(auth.user);
  }, [
    selectUserSections,
    selectAdminSections,
    selectCommonSections,
    auth.user,
  ]);

  const selectedLeague: any = useMemo(() => {
    if (auth?.leagueId) {
      return data?.find((league: any) => league.id === auth.leagueId);
    }
  }, [auth?.leagueId, data]);
  const handleClick = () => {
    setOpen(!open);
  };
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  useEffect(() => {
    if (
      !auth.leagueId &&
      data?.length > 0 &&
      auth?.user?.role === "federation"
    ) {
      setLeagueIndex(data[0].id);
    }
  }, [auth.leagueId, data]);

  const [sideBarWidth, setSideBarWidth] = useState(250);
  const [openSideBarState, setOpenSideBarState] = useState(true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [federationArray, setFederationArray] = useState<any>([]);
  const [organizationsId, setOrganizationsId] = useState(null);

  const handleOpenSideBar = () => {
    setOpenSideBarState(!openSideBarState);
    if (!openSideBarState) {
      setSideBarWidth(250);
    } else {
      setSideBarWidth(88);
    }
  };

  const [platforms, setPlatforms] = useState<any>([]);
  const { data: allPlatformNames } = useQuery(ALL_PLATFORM_NAMES, {
    onCompleted: (platformsData) => {
      setPlatforms(platformsData?.allPlatformsNames);

      if (auth?.user?.isLeagueManager) {
        if (!auth?.platformId) {
          getPlatformId(platformsData?.allPlatformsNames[0]?.id);
        }
      }
    },
  });

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 1280) {
        setOpenSideBarState(true);
        setSideBarWidth(252);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const selectedPlatform: any = useMemo(() => {
    if (auth?.platformId) {
      return platforms?.find(
        (platform: any) => platform.id === auth.platformId
      );
    }
  }, [auth?.platformId, platforms]);

  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [searchTitle, setSearchTitle] = useState("");
  const debouncedSearch = useDebouncedTerm(searchTitle, 600);
  const [isPlatformSettings, setIsPlatformSettings] = useState(false);
  const platformaTab = searchParams.get("platformTab"); // Get the 'tab' query parameter

  // const { loading: isLoadingPlatforms, data: plateform } = useQuery(PLATEFORM, {
  //   variables: {
  //     // page: page,
  //     // limit: rowPerPage,
  //     filter: {
  //       ...(debouncedSearch && { title: debouncedSearch }),
  //       status: "active",
  //     },
  //   },
  //   skip: auth?.user?.role !== "superAdmin", // Change here
  //   onCompleted: (data) => {
  //     // setHasMore(data?.platforms?.platforms?.length > 0);
  //     setPlatforms((prevPlatforms) => {
  //       const newPlatforms = data?.platforms?.platforms?.filter(
  //         (platform) =>
  //           !prevPlatforms.find(
  //             (prevPlatform) => prevPlatform?.id === platform?.id
  //           )
  //       );
  //       return [...prevPlatforms, ...newPlatforms];
  //     });
  //   },
  //   fetchPolicy: "network-only",
  // });

  useEffect(() => {
    if (platformaTab === "settings") {
      setIsPlatformSettings(true);
    }
  }, [navigate]);

  // const { loading: isLoadingParamTournament } = useQuery(PLATEFORM, {
  //   // Skip if platform is not included in platforms list
  //   skip:
  //     !platformId ||
  //     platforms?.length === 0 ||
  //     platforms?.find((tournament: any) => tournament?.id === platformId),
  //   variables: {
  //     filter: {
  //       id: platformId,
  //     },
  //   },
  //   onCompleted: (data) => {
  //     setPlatforms(
  //       (prevPlatforms) =>
  //         [...prevPlatforms, ...data?.platforms?.platforms] || []
  //     );
  //   },
  // });

  const { loading: isLoadingOrgPlatforms, data: OrganizationPlatforms } =
    useQuery(ORGANIZATION_PLATFORMS, {
      variables: {
        filter: {
          federation: auth.user.id,
        },
      },
      // onCompleted: (data) => {
      //   const activePlatforms = data?.organizationPlatforms
      //     .filter((item) => item?.platform?.status === "active")
      //     .map((item) => ({
      //       title: item?.platform?.title,
      //       id: item?.platform?.id,
      //       lightLogo: item?.platform?.lightLogo,
      //     }));

      //   setPlatforms(activePlatforms);
      // },
      // context: {
      //   queryDeduplication: false, // Ensures queries don't deduplicate
      // },
      fetchPolicy: "no-cache",
    });
  useEffect(() => {
    if (auth?.user.role !== "superAdmin") {
      if (auth?.platformId) {
        const organizationData =
          OrganizationPlatforms?.organizationPlatforms?.find(
            (item) => item?.platform?.id === auth?.platformId
          );
        getOrganizationUserId(auth?.user?.id);
      }
    }
  }, [OrganizationPlatforms, auth?.platformId]);

  const handleGetPlatformId = (id: number | string, index?: number) => {
    getPlatformId(id);
    handleGetId(id);
  };

  const observer: any = useRef();

  const lastOptionElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(async (entries) => {
        if (entries[0].isIntersecting && hasMore && !isLoadingOrgPlatforms) {
          setPage((page) => page + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoadingOrgPlatforms]
  );

  /////////////////////// update organization into auth if there is no league in the sidebar  /////////////////////
  useQuery(PLATFORM_BY_ID, {
    skip: !auth?.platformId,
    variables: {
      platformId: auth?.platformId,
    },
    onCompleted: (platformData) => {
      if (auth?.user?.role === "superAdmin") {
        if (auth.leagueId === undefined) {
          getOrganizationUserId(
            platformData?.organizationPlatformByFields?.federation?.user?.id
          );
          if (
            location.pathname.includes(
              "/dashboard/organization-account/settings"
            )
          ) {
            navigate(
              `/dashboard/organization-account/settings/${platformData?.organizationPlatformByFields?.federation?.id}?userId=${platformData?.organizationPlatformByFields?.federation?.user?.id}`
            );
          }
        }
      }

      getPlatformLanguages(
        platformData?.organizationPlatformByFields?.platform?.language
      );
      getPlatformData(platformData?.organizationPlatformByFields);
    },
    fetchPolicy: "network-only",
  });
  /////////////////////// update organization into auth if there is a league is the sidebar  /////////////////////

  useQuery(LEAGUE_BY_ID, {
    skip: !auth?.leagueId,
    variables: {
      id: auth?.leagueId,
    },
    onCompleted: (data) => {
      if (auth?.user?.role === "superAdmin") {
        getOrganizationId(data?.league?.owner?.federation?.id);
        getOrganizationUserId(data?.league?.owner?.federation?.user?.id);

        if (
          location.pathname.includes("/dashboard/organization-account/settings")
        ) {
          navigate(
            `/dashboard/organization-account/settings/${data?.league?.owner?.federation?.id}?userId=${data?.league?.owner?.federation?.user?.id}`
          );
        }
      }
      //  else if (auth?.user?.isLeagueManager) {
      //   getOrganizationId(data?.league?.owner?.federation?.id);
      // }
    },
  });
  const getPlatformName = (id) => {
    let findedObject = platforms?.find((item) => item?.id === id);
    return findedObject;
  };

  const { loading: loader, data: organizationsData } = useQuery(
    ORGANIZATION_PLATFORMS,
    {
      skip: !auth.user?.id && !auth?.platformData?.federation?.user,
      variables: {
        filter: {
          // ...(auth?.user?.role === "superAdmin" && {
          platform: auth.platformId,
          // }),

          // ...(auth?.user?.role !== "superAdmin" &&
          //   auth?.user?.email !==
          //     auth?.platformData?.federation?.user?.email && {
          //     federation: auth.user?.id,
          //   }),
          ...(!isFederationEmail(auth) &&
          !isPlatformManager(auth) &&
          auth?.user?.role !== "superAdmin"
            ? { federation: auth.user?.id }
            : {}),
        },
      },
      onCompleted: (data) => {
        // setFederationArray([]);
      },
      context: {
        queryDeduplication: false, // Ensures queries don't deduplicate
      },
      fetchPolicy: "no-cache",
    }
  );
  useEffect(() => {
    if (organizationsData) {
      setFederationArray([]);
      // create the shape of data and ensure no duplicates
      setFederationArray(
        organizationsData?.organizationPlatforms
          .reduce((unique, item) => {
            // Check if item with this federation ID already exists
            const exists = unique.find(
              (u) => u.federation?.id === item.federation?.id
            );
            if (!exists) {
              unique.push(item);
            }
            return unique;
          }, [])
          .map((item) => ({
            id: item?.federation?.id,
            title: item?.federation?.organizationName,
            relationalId: item?.id,
            logo: item?.federation?.logo,
            userId: item?.federation?.user?.id,
          }))
      );

      // find the login user in the selected platform federations list
      if (organizationsData?.organizationPlatforms.length > 0) {
        let finded = organizationsData?.organizationPlatforms.find(
          (item) => item?.federation?.user?.id === auth?.user?.id
        );
        if (finded) {
          setOrganizationsId(finded?.federation?.id);
          getOrganizationId(finded?.federation?.id);
          getOrganizationUserId(finded?.federation?.user?.id);
        } else {
          if (auth?.user?.role === "superAdmin") {
            let finded = organizationsData?.organizationPlatforms.find(
              (item) =>
                item?.federation?.user?.id ===
                auth?.platformData?.federation?.user?.id
            );
            setOrganizationsId(finded?.federation?.id);
            getOrganizationId(finded?.federation?.id);
            getOrganizationUserId(finded?.federation?.user?.id);
          } else {
            setOrganizationsId(
              organizationsData?.organizationPlatforms[0]?.federation?.id
            );
            getOrganizationId(
              organizationsData?.organizationPlatforms[0]?.federation?.id
            );
            getOrganizationUserId(
              organizationsData?.organizationPlatforms[0]?.federation?.user?.id
            );
          }
        }
      }
    }
  }, [organizationsData?.organizationPlatforms, auth?.platformData]);

  const selectedOrg: any = useMemo(() => {
    if (organizationsId) {
      return federationArray?.find(
        (league: any) => league.id === organizationsId
      );
    }
  }, [federationArray, organizationsId]);
  const token = Cookies.get("token");

  const [query, setQuery] = useState("");
  const [search, setSearch] = useState("");

  // Filter platforms based on the search query
  const filteredPlatforms = platforms?.filter((platform) =>
    platform?.title?.toLowerCase().includes(search?.toLowerCase())
  );

  const filteredLeagues = data.filter((league) =>
    league?.title?.toLowerCase().includes(searchLeague?.toLowerCase())
  );
  const isMobile = useMediaQuery("(max-width:700px)"); //

  const { data: StatsData } = useQuery(DASHBOARD_STATS, {
    variables: {
      platform: auth?.platformId,
      ...(auth?.platformId === 14 && {
        isAdmin: true,
      }),
    },
    onCompleted: (data) => {
      auth?.getDashboardStats(data?.allDashboardStats);
    },
    fetchPolicy: "network-only",
  });

  useQuery(DASHBOARD_ORGANIZATION_STATS, {
    variables: {
      organizationId: auth?.organizationId,
      ...(auth?.platformId === 14 && {
        isAdmin: true,
      }),
    },
    onCompleted: (data) => {
      auth?.getDashboardOrganizationStats(data?.statsForOrganization);
      // auth?.getDashboardStats(data?.allDashboardStats);
    },
  });

  // login user and selected platform user same then allow the access of platform
  const isPlatformFederationEmail =
    auth?.user?.role === "superAdmin" ||
    auth?.user?.email !== auth?.platformData?.federation?.user?.email;
  //allow the access if the login user is the member of platform
  const isMemberOfPlatform =
    auth?.user?.role === "superAdmin" ||
    auth?.user?.member_of_plateforms?.some(
      (item) => item.platform?.id === platformId
    );
  const [leagues, setLeagues] = useState<any>([]);
  useQuery(ALLLEAGUESNAMES, {
    skip: auth?.user?.isLeagueManager,
    variables: {
      filter: {
        // platform: auth.platformId,
      },
    },
    onCompleted: (data) => {
      setLeagues(data?.allLeaguesNames);
    },
  });

  useQuery(LEAGUE_MANAGER_LEAGUE, {
    skip: !auth?.user?.isLeagueManager,
    onCompleted: (data) => {
      setLeagues(data?.leaguesForDashboard);
      console.log("data?.leaguesForDashboard  ", data?.leaguesForDashboard);

      if (auth?.user?.isLeagueManager && data?.leaguesForDashboard.length > 0) {
        navigate(
          `/dashboard/leagues/edit/${data?.leaguesForDashboard?.[0]?.id}?tab=league&activeTab=settings`
        );
      }
    },
  });

  const content = (
    <Fragment>
      <Box
        sx={{
          position: "sticky",
          // display: lgUp ? "block" : "none",
          background: "#ffff",
          top: 0,
          zIndex: 9,
          height: "100vh",
          pt: "10px",
          overflow: "hidden",
        }}
      >
        {/* <Tooltip title="Under development">
          <Box
            sx={{
              position: "absolute",
              right: "-18px",
              top: 44,
              width: "28px",
              height: "28px",
              padding: "6px",
              borderRadius: "8px",
              gap: "8px",
              border: "1px solid #EFEFF0",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 9999,
              backgroundColor: "#FFFFFF",
              cursor: "not-allowed",
              display: { lg: "flex", xs: "none" },
            }}
            // onClick={handleOpenSideBar}
          >
            {!openSideBarState ? (
              <ChevronRight fontSize="small" />
            ) : (
              <ChevronLeft fontSize="small" />
            )}
          </Box>
        </Tooltip> */}

        <Box
          sx={{
            height: "calc(100%)",
            overflow: "hidden",
          }}
        >
          <Box>
            <Box
              sx={{
                position: "sticky",
                display: lgUp ? "block" : "none",
                background: "#ffff",
                top: 0,
                zIndex: 9,
                px: "10px",
              }}
            >
              <RouterLink to="/dashboard">
                <Box
                  sx={{
                    pt: "7px",
                    pb: "17px",
                    borderBottom: "1px solid #EFEFF0",
                    mb: 2,
                    overflow: "hidden",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: openSideBarState ? "flex-start" : "center",
                  }}
                >
                  {openSideBarState ? (
                    <img
                      src="/static/tournatedLogoBig.svg"
                      style={{ height: "25px" }}
                    />
                  ) : (
                    <ReactSVG
                      src="/images/tournated-icon.svg"
                      style={{ height: "25px" }}
                    />
                  )}
                </Box>
              </RouterLink>
            </Box>
            <Box sx={{ px: "10px" }}>
              <Box sx={{ background: "#fafafa", borderRadius: "8px" }}>
                <PlatformSelect
                  isLoadingParamTournament={isLoadingOrgPlatforms}
                  platforms={platforms}
                  getPlatformName={getPlatformName}
                  setQuery={setQuery}
                  lastOptionElementRef={lastOptionElementRef}
                  setSearchTitle={setSearchTitle}
                />
                {/* <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    pt: 3,
                    cursor: "pointer",
                    height: "70px",
                    width: "70px",
                    m: "auto",
                  }}
                >
                  <img
                    src={
                      auth?.platformData?.platform?.lightLogo ||
                      "/static/tournatedLogo.svg"
                    }
                    style={{
                      objectFit: "cover",
                    }}
                    alt=""
                  />
                </Box> */}
                {/* <Box sx={{ position: "relative" }}>
                  <Box
                    sx={{
                      position: "fixed",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      display: isDisplayPlatform ? "block" : "none",
                    }}
                    onClick={() => {
                      setIsDisplayPlatform(!isDisplayPlatform);
                      setOpen(false);
                    }}
                  ></Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      mt: "14px",
                    }}
                    onClick={() => {
                      setIsDisplayPlatform(!isDisplayPlatform);
                    }}
                  >
                    <Tooltip
                      title={
                        getPlatformName(auth?.platformId)?.title
                          ? getPlatformName(auth?.platformId)?.title
                          : "Select platform"
                      }
                    >
                      <Typography
                        variant="heading1"
                        sx={{
                          lineHeight: "20.16px",
                          mr: "10.24px",
                          maxWidth: "137px",
                          whiteSpace: "nowrap", // Ensures text does not wrap
                          overflow: "hidden", // Hides overflow text
                          textOverflow: "ellipsis", // Adds ellipsis when text overflows
                        }}
                      >
                        {getPlatformName(auth?.platformId)?.title
                          ? getPlatformName(auth?.platformId)?.title
                          : "Select platform"}
                      </Typography>
                    </Tooltip>
                    <ReactSVG src="/static/Union.svg" />
                  </Box>
                  <Box
                    sx={{
                      position: "absolute",
                      width: "100%",
                      background: "white",
                      top: "30px",
                      left: 0,
                      right: 0,
                      border: "1px solid #EDF1F6",
                      p: "10px",
                      borderRadius: "8px",
                      zIndex: 999,
                      display: isDisplayPlatform ? "block" : "none",
                      maxHeight: "232px",
                      boxShadow: "15px 15px 46px 0px #0000001A",
                    }}
                  >
                    <TextField
                      fullWidth
                      size="small"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          height: "40px",
                          padding: "8px 12px 10px 15px",
                          borderRadius: "8px",
                          background: "#FAFAFA",
                          color: "#0A2540",

                          "& fieldset": {
                            border: "1px solid #EDF1F6",
                          },
                          "&:hover fieldset": {
                            border: "1px solid red",
                            transform: "none",
                          },
                          "&.Mui-focused fieldset": {
                            border: "1px solid red",
                          },
                        },
                        "& .MuiOutlinedInput-input": {
                          padding: "0px !important",
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          // @ts-ignore
                          <InputAdornment>
                            <SearchIcon
                              fontSize="small"
                              sx={{
                                color: "black",
                                marginTop: "2px",
                                marginLeft: "-5px",
                                marginRight: "10px",
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                      value={query}
                      onChange={(e) => {
                        setSearch(e.target.value);
                        setQuery(e.target.value);
                      }} // Update the query state
                      placeholder={"Search"}
                      variant="outlined"
                    />
                    <Box
                      sx={{
                        pt: "10px",
                        maxHeight: "170px",
                        overflow: "scroll",
                      }}
                    >
                      {filteredPlatforms.length > 0 ? (
                        filteredPlatforms.map((item, index) => (
                          <Box
                            key={index}
                            sx={{
                              py: "7px",
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                              borderRadius: "6px",
                              backgroundColor:
                                auth?.platformId === item?.id && "#FF57331A",
                              "&:hover": {
                                backgroundColor: "#FF57331A", // Replace with your desired hover color
                              },
                            }}
                            onClick={() => {
                              handleGetPlatformId(item?.id);
                              setQuery(item?.title);
                              setIsDisplayPlatform(false);
                              setSearch("");
                              Cookies.remove("lang");
                            }}
                          >
                            <Typography
                              variant="tableHeadFont"
                              sx={{
                                color: "#0A2540",
                                px: "10px",
                                lineHeight: "18px",
                              }}
                            >
                              {item?.title}
                            </Typography>
                          </Box>
                        ))
                      ) : (
                        <Box
                          sx={{
                            py: "7px",
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                            borderRadius: "6px",
                            "&:hover": {
                              backgroundColor: "#FF57331A", // Replace with your desired hover color
                            },
                          }}
                        >
                          <Typography
                            variant="tableHeadFont"
                            sx={{
                              color: "#0A2540",
                              px: "10px",
                              lineHeight: "18px",
                            }}
                          >
                            No platform found
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box> */}
                <Box
                  sx={{
                    display: "flex",
                    padding: "0px 10px 15px 11.5px",
                  }}
                  gap={"3px"}
                >
                  <Box
                    sx={{
                      "svg path": {
                        fill:
                          location.pathname === "/dashboard"
                            ? "#FF5733"
                            : "#86909F",
                      },
                    }}
                  >
                    <Button
                      onClick={() => {
                        navigate("/dashboard");
                        setIsPlatformSettings(false);
                      }}
                      disabled={isLeagueManager(auth)}
                      startIcon={
                        <ReactSVG
                          src="/static/dashboard.svg"
                          style={{ paddingLeft: "5px", paddingBottom: "2px" }}
                        />
                      }
                      sx={{
                        background:
                          location.pathname === "/dashboard" ? "#FF57331A" : "",
                        color:
                          location.pathname === "/dashboard"
                            ? "#FF5733"
                            : "#0A254059",
                        fontSize: "12px !important",
                        fontWeight: "500",
                        width: "101px",
                        height: "35px",
                        lineHeight: "14px", // Corrected lineHeight
                      }}
                    >
                      {"Dashboard"}
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      "svg path": {
                        fill:
                          platformaTab === "settings" ? "#FF5733" : "#86909F",
                      },
                    }}
                  >
                    <Button
                      onClick={() => {
                        navigate(
                          `/dashboard/platforms/settings/${auth?.platformId}?platformActiveTab=general&platformTab=settings`
                        );
                        setIsPlatformSettings(true);
                      }}
                      startIcon={<ReactSVG src="/static/settings_red.svg" />}
                      sx={{
                        color:
                          platformaTab === "settings" ? "#FF5733" : "#0A254059",
                        background:
                          platformaTab === "settings" ? "#FF57331A" : "",
                        fontSize: "12px !important",
                        fontWeight: "500",
                        width: "101px",
                        height: "35px",
                        borderRadius: "6px",
                      }}
                      disabled={
                        isPlatformFederationEmail && !isMemberOfPlatform
                      }
                    >
                      {t("Settings")}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
            {!isPlatformSettings ? (
              <Box>
                <Box sx={{ px: "10px" }}>
                  <Box sx={{ mt: "15px" }}>
                    <Box
                      sx={{
                        color: "#FF5733",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography variant="font15">
                        {t("Setup progress")}
                      </Typography>
                      <Typography variant="font15">
                        {auth?.onboardingData?.percentage
                          ? parseInt(
                              auth?.onboardingData?.percentage
                            )?.toFixed()
                          : 0}
                        %
                      </Typography>
                    </Box>
                    <BorderLinearProgress
                      sx={{ mt: "10px" }}
                      variant="determinate"
                      value={auth?.onboardingData?.percentage || 0}
                    />
                    <Button
                      sx={{ width: "100%", mt: "15px", height: "32px" }}
                      variant="outlined"
                      onClick={() => navigate(`/dashboard/onboarding`)}
                      disabled={
                        auth?.userPlatformRole === "platformManager" ||
                        auth?.userPlatformRole === "leagueManager"
                      }
                    >
                      {auth?.onboardingData?.percentage < 100
                        ? t("Complete setup")
                        : t("Control panel")}
                    </Button>
                  </Box>
                </Box>
                <OrganizationLeagueMenu
                  federationArray={federationArray}
                  setOrganizationsId={setOrganizationsId}
                  selectedOrg={selectedOrg}
                  handleTabsChange={handleTabsChange}
                  currentTab={currentTab}
                  tabs={tabs}
                  isLeagues={isLeagues}
                  searchLeague={searchLeague}
                  filteredLeagues={filteredLeagues}
                  setSearchLeague={setSearchLeague}
                  id={id}
                  organizationId={organizationId}
                  organizationUserId={organizationUserId}
                  sidebarMenu={sidebarMenu}
                  handleSelect={handleSelect}
                />
              </Box>
            ) : (
              <Scrollbar>
                <Box
                  sx={{
                    px: "10px",
                    height: "calc(100vh - 222px)",
                    // overflow: "scroll",
                    pb: "10px",
                    "&::-webkit-scrollbar": {
                      width: "6px", // Scrollbar width
                    },
                    "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb":
                      {
                        borderRadius: 8,
                        backgroundColor: "#6b6b6b",
                        border: "3px solid #2b2b2b",
                      },
                    "&::-webkit-scrollbar-thumb:hover": {
                      backgroundColor: "#555", // Scrollbar hover effect
                    },
                    "&::-webkit-scrollbar-track": {
                      backgroundColor: "#f0f0f0", // Track color
                      borderRadius: "10px", // Match scrollbar radius
                    },
                    scrollbarWidth: "thin", // For Firefox
                    scrollbarColor: "#888 #f0f0f0",
                  }}
                >
                  <PlatformSettingsMenu
                    setIsPlatformSettings={setIsPlatformSettings}
                    platformSettingsArray={platformSettingsArray}
                    handleSelect={handleSelect}
                    selected={selected}
                  />
                </Box>
              </Scrollbar>
            )}
          </Box>
        </Box>
      </Box>
    </Fragment>
  );

  const contentMobile = (
    <Fragment>
      <Box
        sx={{
          position: "sticky",
          // display: lgUp ? "block" : "none",
          background: "#ffff",
          top: 0,
          zIndex: 9,
          px: "10px",
          pt: "10px",
        }}
      >
        <Box
          sx={{
            // height: { md: "calc(100vh - 73px)", xs: "calc(100vh + 120px)" },

            height: "100vh",
            overflow: "scroll",
            // pb: "30px",
            "&::-webkit-scrollbar": {
              width: "6px", // Scrollbar width
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#888", // Scrollbar color
              borderRadius: "10px", // Rounded corners
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "#555", // Scrollbar hover effect
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#f0f0f0", // Track color
              borderRadius: "10px", // Match scrollbar radius
            },
            scrollbarWidth: "thin", // For Firefox
            scrollbarColor: "#888 #f0f0f0",
          }}
        >
          {!menuName && (
            <Box>
              <Box sx={{ background: "#fafafa", borderRadius: "8px" }}>
                <PlatformSelect
                  isLoadingParamTournament={isLoadingOrgPlatforms}
                  platforms={platforms}
                  getPlatformName={getPlatformName}
                  setQuery={setQuery}
                  lastOptionElementRef={lastOptionElementRef}
                  setSearchTitle={setSearchTitle}
                />

                <Box sx={{ px: "10px" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      height: "35px",
                      px: "12px",
                      width: "100%",
                      "svg path": {
                        fill: "#86909F",
                      },
                    }}
                    onClick={() => navigate("/dashboard")}
                  >
                    <ReactSVG src="/static/dashboard.svg" />
                    <Typography
                      variant="tableHeadFont"
                      sx={{ ml: "12px", color: "#86909F" }}
                    >
                      Dashboard
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      height: "35px",
                      px: "12px",
                      width: "100%",
                      "svg path": {
                        fill: "#86909F",
                      },
                    }}
                    onClick={() => setMenuName("setting")}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <ReactSVG
                        src="/static/settings1.svg"
                        style={{ marginTop: "4px" }}
                      />
                      <Typography
                        variant="tableHeadFont"
                        sx={{ ml: "12px", color: "#86909F" }}
                      >
                        Settings
                      </Typography>
                    </Box>
                    <ReactSVG
                      src="/static/right_angle.svg"
                      style={{ marginTop: "4px" }}
                    />
                  </Box>
                </Box>
                <Box sx={{ px: "10px", py: "10px" }}>
                  <Divider sx={{ color: "#EDF1F6" }} />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: "35px",
                    px: "20px",
                    pb: "10px",
                    width: "100%",
                    "svg path": {
                      fill: "#86909F",
                    },
                  }}
                  onClick={() => setMenuName("organization_league")}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <ReactSVG
                      src="/static/leagues1.svg"
                      style={{
                        height: "16px",
                        width: "18px",
                        marginBottom: "5px",
                      }}
                    />
                    <Typography
                      variant="tableHeadFont"
                      sx={{ ml: "12px", color: "#86909F" }}
                    >
                      Organizations & Leagues
                    </Typography>
                  </Box>
                  <ReactSVG
                    src="/static/right_angle.svg"
                    style={{ marginTop: "4px" }}
                  />
                </Box>
              </Box>

              <Box>
                <Box sx={{ mt: "15px", px: "12px" }}>
                  <Box
                    sx={{
                      color: "#FF5733",
                      fontWeight: "500",
                      fontSize: "12px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="font15">Setup progress</Typography>
                    <Typography variant="font15">
                      {auth?.onboardingData?.percentage
                        ? parseInt(auth?.onboardingData?.percentage)?.toFixed()
                        : 0}
                      %
                    </Typography>
                  </Box>
                  <BorderLinearProgress
                    sx={{ mt: "10px" }}
                    variant="determinate"
                    value={50}
                  />
                  <Button
                    sx={{ width: "100%", mt: "15px", height: "32px" }}
                    variant="outlined"
                    onClick={() => navigate(`/dashboard/onboarding`)}
                  >
                    Complete setup
                  </Button>
                </Box>
                <Box sx={{ py: "20px" }}>
                  <Divider sx={{ color: "#EDF1F6" }} />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: "35px",
                    px: "15px",
                    width: "100%",
                    "svg path": {
                      fill: "#86909F",
                    },
                  }}
                  onClick={() => setMenuName("super_data")}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <ReactSVG
                      src="/static/super_mobile.svg"
                      style={{
                        marginTop: "4px",
                      }}
                    />
                    <Typography
                      variant="tableHeadFont"
                      sx={{ ml: "12px", color: "#86909F" }}
                    >
                      Super Data
                    </Typography>
                  </Box>
                  <ReactSVG
                    src="/static/right_angle.svg"
                    style={{ marginTop: "4px" }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: "35px",
                    px: "15px",
                    width: "100%",
                    "svg path": {
                      fill: "#86909F",
                    },
                  }}
                  onClick={() => setMenuName("platform_menu")}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <ReactSVG
                      src="/static/settings1.svg"
                      style={{
                        marginTop: "4px",
                      }}
                    />
                    <Typography
                      variant="tableHeadFont"
                      sx={{ ml: "12px", color: "#86909F" }}
                    >
                      Platform
                    </Typography>
                  </Box>
                  <ReactSVG
                    src="/static/right_angle.svg"
                    style={{ marginTop: "4px" }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: "35px",
                    px: "15px",
                    width: "100%",
                    "svg path": {
                      fill: "#86909F",
                    },
                  }}
                  onClick={() => setMenuName("financial_data")}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <ReactSVG
                      src="/static/finance_mobile.svg"
                      style={{ marginTop: "4px" }}
                    />
                    <Typography
                      variant="tableHeadFont"
                      sx={{ ml: "12px", color: "#86909F" }}
                    >
                      Financial
                    </Typography>
                  </Box>
                  <ReactSVG
                    src="/static/right_angle.svg"
                    style={{ marginTop: "4px" }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: "35px",
                    px: "15px",
                    width: "100%",
                    "svg path": {
                      fill: "#86909F",
                    },
                  }}
                  onClick={() => {
                    setMenuName("help_data");
                    window.scrollTo(0, 0);
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <ReactSVG
                      src="/static/help_mobile.svg"
                      style={{ marginTop: "4px" }}
                    />
                    <Typography
                      variant="tableHeadFont"
                      sx={{ ml: "12px", color: "#86909F" }}
                    >
                      Help
                    </Typography>
                  </Box>
                  <ReactSVG
                    src="/static/right_angle.svg"
                    style={{ marginTop: "4px" }}
                  />
                </Box>
                <Box sx={{ py: "20px" }}>
                  <Divider />
                </Box>
                <Box>
                  <Button
                    sx={{
                      width: "100%",
                      height: "32px",
                      background: "#FAFAFA",
                      borderRadius: "6px",
                      color: "#0A2540",
                      border: "1px solid #EFEFF0",
                      "svg path": {
                        fill: "#0A2540",
                      },
                    }}
                    endIcon={
                      <ReactSVG
                        src="/static/right_angle_small.svg"
                        style={{ marginTop: "4px" }}
                      />
                    }
                    onClick={() => setMenuName("leagues_menu")}
                  >
                    My leagues
                  </Button>
                  {/* <Button
                    sx={{
                      width: "100%",
                      mt: "15px",
                      height: "32px",
                      background: "#FF57331A",
                      borderRadius: "6px",
                    }}
                    endIcon={
                      <ReactSVG
                        src="/static/right_angle_small.svg"
                        style={{ marginTop: "4px" }}
                      />
                    }
                  >
                    My subscription
                  </Button> */}
                </Box>
                <Box sx={{ mt: 2 }}>
                  <LanguagePopover />
                </Box>
              </Box>
            </Box>
          )}
          {menuName === "setting" && (
            <Box sx={{ height: "120%" }}>
              <PlatformSettingsMenu
                setIsPlatformSettings={setIsPlatformSettings}
                platformSettingsArray={platformSettingsArray}
                handleSelect={handleSelect}
                selected={selected}
                setMenuName={setMenuName}
              />
            </Box>
          )}
          {menuName === "organization_league" && (
            <OrganizationLeagueMenu
              federationArray={federationArray}
              setOrganizationsId={setOrganizationsId}
              selectedOrg={selectedOrg}
              handleTabsChange={handleTabsChange}
              currentTab={currentTab}
              tabs={tabs}
              isLeagues={isLeagues}
              searchLeague={searchLeague}
              filteredLeagues={filteredLeagues}
              setSearchLeague={setSearchLeague}
              id={id}
              organizationId={organizationId}
              organizationUserId={organizationUserId}
              sidebarMenu={sidebarMenu}
              handleSelect={handleSelect}
              setIsPlatformSettings={setIsPlatformSettings}
              setMenuName={setMenuName}
            />
          )}

          {menuName === "super_data" && (
            <SuperDataMenu setMenuName={setMenuName} />
          )}
          {menuName === "platform_menu" && (
            <PlatformMenu setMenuName={setMenuName} />
          )}

          {menuName === "financial_data" && (
            <FinancialMenu setMenuName={setMenuName} />
          )}

          {menuName === "help_data" && <HelpMenu setMenuName={setMenuName} />}
          {menuName === "leagues_menu" && (
            <LeaguesMenu setMenuName={setMenuName} leagues={leagues} />
          )}
        </Box>
      </Box>
    </Fragment>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            // backgroundColor: "#171819",
            position: "relative",
            zIndex: 9,
            overflowY: "initial",
            // height: "calc(100% - 64px) !important",
            // top: "80px !Important",
            // width: 280,
            borderRight: "1px solid #EDF1F6",
          },
        }}
        // sx={{ width: sideBarWidth, transition: "width 0.5s ease-in-out" }}
        sx={{ width: sideBarWidth }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Box>
      {isMobile ? (
        <Box
          sx={{
            position: "absolute",
            top: 62,
            left: 0,
            width: "100vw",
            height: openMobile ? "calc(100vh - 62px)" : "0", // Animate height
            zIndex: 1300, // Ensure it's above other elements
            overflowY: "auto",
            display: "block", // Always keep the element in the DOM
            transition: "height 0.3s ease", // Smooth height transition
          }}
        >
          <Box
            sx={{
              backgroundColor: "#FFFFFF",
              width: "100%",
              height: "100%", // Proper spacing around the minus operator
              overflow: "scroll", // Prevent overflow during the transition
            }}
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the menu
          >
            {contentMobile}
          </Box>
        </Box>
      ) : (
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          PaperProps={{
            sx: {
              position: "relative",
              width: 250,
            },
          }}
          variant="temporary"
        >
          {content}
        </Drawer>
      )}
    </Box>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default DashboardSidebar;
