import { Box, Button, Popover, Typography } from "@material-ui/core";
import Cookies from "js-cookie";
import type { FC } from "react";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import useAuth from "src/hooks/useAuth";

const CreatePopover: FC = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const auth = useAuth();
  const token = Cookies.get("token");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleOpen = (): void => {
    setOpen(true);
  };
  const handleClose = (): void => {
    setOpen(false);
  };
  return (
    <>
      <Button
        size="small"
        color="primary"
        variant="contained"
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          height: "32px",
          borderRadius: "4px",
          backgroundColor: "#FAFAFA",
          fontSize: "12px",
          color: "#425466",
          border: `1px solid #EFEFF0`,
          "&:hover": {
            backgroundColor: "#FAFAFA",
            boxShadow: "none",
            border: "1px solid #FF5733",
            "& .text-class": {
              color: "#FF5733",
            },
            "svg path": {
              fill: "#FF5733",
            },
          },
        }}
        startIcon={
          <ReactSVG src="/static/Add1.svg" style={{ marginTop: "2px" }} />
        }
      >
        <Typography
          className="text-class"
          variant="tableHeadFont"
          sx={{ color: "#0A2540", lineHeight: "30px" }}
        >
          {t("Create")}
        </Typography>
      </Button>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        sx={{
          "& .MuiPopover-paper,.MuiPaper-elevation8": {
            top: "63px !important",
            border: "1px solid #EDF1F6",
          },
        }}
        PaperProps={{
          sx: {
            width: 240,
            padding: "10px 0px 10px 0px",
            boxShadow: "30px 30px 46px 0px #0000001A",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            py: "7px",
            px: "20px",
            "svg path": {
              fill: "#0A2540",
            },
            "&:hover": {
              ".profile-text": { color: "#FF5733" }, // Change text color on hover
              background: "#FF57331A",
              "svg path": {
                fill: "#FF5733", // Replace with your desired hover color for SVG
              }, // Change SVG color on hover if supported
            },
          }}
          onClick={() =>
            navigate(
              "/dashboard/leagues/new?tab=league&activeTab=create_league"
            )
          }
        >
          <ReactSVG
            style={{ marginTop: "3px", height: "20px", width: "20px" }}
            src="/static/leagues.svg"
          />
          <Typography
            className="profile-text"
            sx={{
              ml: "7px",
              color: "#0A2540",
              transition: "color 0.3s", // Smooth color transition
            }}
            variant="tableHeadFont"
          >
            {" "}
            {t("League")}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            py: "7px",
            px: "17px",
            "svg path": {
              fill: "#0A2540",
            },
            "&:hover": {
              ".profile-text": { color: "#FF5733" }, // Change text color on hover
              background: "#FF57331A",
              "svg path": {
                fill: "#FF5733", // Replace with your desired hover color for SVG
              }, // Change SVG color on hover if supported
            },
          }}
          onClick={() => {
            window.open(
              `${process?.env?.REACT_APP_TOURNAMENT_URL}/dashboard/all-tournaments/new?leagueId=${auth?.leagueId}&token=${token}`
            );
          }}
        >
          <ReactSVG
            style={{ height: "20px", width: "20px" }}
            src="/static/tournaments.svg"
          />
          <Typography
            className="profile-text"
            sx={{
              ml: "10px",
              color: "#0A2540",
              transition: "color 0.3s", // Smooth color transition
            }}
            variant="tableHeadFont"
          >
            {t("Tournament")}
          </Typography>{" "}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            py: "7px",
            px: "20px",
            "svg path": {
              fill: "#0A2540",
            },
            "&:hover": {
              ".profile-text": { color: "#FF5733" }, // Change text color on hover
              background: "#FF57331A",
              "svg path": {
                fill: "#FF5733", // Replace with your desired hover color for SVG
              }, // Change SVG color on hover if supported
            },
          }}
          onClick={() => {
            navigate(`/dashboard/clubs/new`);
          }}
        >
          <ReactSVG
            style={{ height: "20px", width: "20px" }}
            src="/static/clubs.svg"
          />
          <Typography
            className="profile-text"
            sx={{
              ml: 1,
              color: "#0A2540",
              transition: "color 0.3s", // Smooth color transition
            }}
            variant="tableHeadFont"
          >
            {t("Club")}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            py: "7px",
            px: "20px",
            "svg path": {
              fill: "#0A2540",
            },
            "&:hover": {
              ".profile-text": { color: "#FF5733" }, // Change text color on hover
              background: "#FF57331A",
              "svg path": {
                fill: "#FF5733", // Replace with your desired hover color for SVG
              }, // Change SVG color on hover if supported
            },
          }}
          onClick={() => {
            navigate(`/dashboard/posts/new`);
          }}
        >
          <ReactSVG
            style={{ marginTop: "3px", height: "20px", width: "20px" }}
            src="/static/post.svg"
          />
          <Typography
            className="profile-text"
            sx={{
              ml: 1,
              color: "#0A2540",
              transition: "color 0.3s", // Smooth color transition
            }}
            variant="tableHeadFont"
          >
            {" "}
            {t("News")}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            py: "7px",
            px: "20px",
            "svg path": {
              fill: "#0A2540",
            },
            "&:hover": {
              ".profile-text": { color: "#FF5733" }, // Change text color on hover
              background: "#FF57331A",
              "svg path": {
                fill: "#FF5733", // Replace with your desired hover color for SVG
              }, // Change SVG color on hover if supported
            },
          }}
          onClick={() => {
            navigate(`/dashboard/platform-members/new`);
          }}
        >
          <ReactSVG
            style={{ height: "20px", width: "20px" }}
            src="/static/team_member1.svg"
          />
          <Typography
            className="profile-text"
            sx={{
              ml: 1,
              color: "#0A2540",
              transition: "color 0.3s", // Smooth color transition
            }}
            variant="tableHeadFont"
          >
            {" "}
            {t("Team")}
          </Typography>
        </Box>
      </Popover>
    </>
  );
};
export default CreatePopover;
