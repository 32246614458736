import { Box, Divider, Tooltip, Typography } from "@material-ui/core";
import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router";
import { ReactSVG } from "react-svg";
import { financeArray, superDataArray } from "src/constants";

export default function FinancialMenu({ setMenuName }: any) {
  const navigate = useNavigate();
  const refDisplay = useRef(null);
  const handleScroll = () => {
    setTimeout(() => {
      if (refDisplay && refDisplay.current) {
        refDisplay.current.scrollIntoView({
          block: "start",
        });
      }
    }, 0);
  };

  useEffect(() => {
    handleScroll();
  }, []);
  return (
    <div>
      <Box ref={refDisplay} sx={{ height: "10px" }} />
      <Box
        sx={{
          height: "25px",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          width: "100%",
        }}
        onClick={() => {
          setMenuName("");
        }}
      >
        <ReactSVG src="/static/left_angle.svg" style={{ marginTop: "4px" }} />
        <Typography sx={{ color: "#FF5733", ml: "5px" }}>Menu</Typography>
      </Box>
      <Box sx={{ py: "13px", display: { sm: "none", xs: "block" } }}>
        <Typography variant="font18" sx={{ color: "#0A2540", px: "12px" }}>
          Financial
        </Typography>
      </Box>
      {financeArray.map((item, index) => (
        <Box>
          <Tooltip title="Coming soon">
            <Box
              key={index}
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                py: "12px",
                px: "12px",
                "&:hover": {
                  ".profile-text": { color: "#FF5733" }, // Change text color on hover
                  background: "#FF57331A",
                  "svg path": {
                    fill: "#FF5733", // Replace with your desired hover color for SVG
                  }, // Change SVG color on hover if supported
                },
              }}
              onClick={() => {
                navigate(item?.path);
              }}
            >
              <Typography
                className="profile-text"
                sx={{
                  color: "#86909F",
                  transition: "color 0.3s", // Smooth color transition
                }}
                variant="tableHeadFont"
              >
                {item?.title}
              </Typography>
            </Box>
          </Tooltip>
        </Box>
      ))}
    </div>
  );
}
