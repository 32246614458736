import { gql } from "@apollo/client";

const ONBOARDING_DATA = gql`
  query fetchOnboardingDetails(
    $platform: Int
    $organizationPlatformId: Int
    $userId: Int
    $userRole: String
  ) {
    fetchOnboardingDetails(
      platform: $platform
      organizationPlatformId: $organizationPlatformId
      userId: $userId
      userRole: $userRole
    )
  }
`;

export default ONBOARDING_DATA;
