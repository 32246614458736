import { useEffect, useState } from "react";
import type { ChangeEvent, FC, MouseEvent } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";

import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "react-beautiful-dnd";
import SearchIcon from "src/icons/Search";
import type { Customer } from "src/types/customer";
import Scrollbar from "../../Scrollbar";
import { ReactSVG } from "react-svg";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { UPDATE_DISCIPLINE_POSITION } from "src/graphql/mutations";
import { DISCIPLINES } from "src/graphql/queries";
import { useTranslation } from "react-i18next";
import { rowPerPage } from "src/constants";
import CustomTooltip from "src/components/customComponents/CustomTooltip";
import CustomChip from "src/components/customComponents/CustomChip";

type Sort = "updatedAt|desc" | "updatedAt|asc" | "orders|desc" | "orders|asc";

interface SortOption {
  value: Sort;
  label: string;
}

const sortOptions: SortOption[] = [
  {
    label: "All",
    value: "updatedAt|desc",
  },
  {
    label: "Last update (oldest)",
    value: "updatedAt|asc",
  },
  {
    label: "Total orders (highest)",
    value: "orders|desc",
  },
  {
    label: "Total orders (lowest)",
    value: "orders|asc",
  },
];

const applyFilters = (
  customers: Customer[],
  query: string,
  filters: any
): Customer[] =>
  customers?.filter((customer) => {
    let matches = true;
    if (query) {
      const properties = ["title"];
      let containsQuery = false;

      properties.forEach((property) => {
        if (customer[property]?.toLowerCase()?.includes(query?.toLowerCase())) {
          containsQuery = true;
        }
      });

      if (!containsQuery) {
        matches = false;
      }
    }

    Object.keys(filters).forEach((key) => {
      const value = filters[key];

      if (value && customer[key] !== value) {
        matches = false;
      }
    });

    return matches;
  });

const applyPagination = (
  customers: Customer[],
  page: number,
  limit: number
): Customer[] => customers.slice(page * limit, page * limit + limit);

const descendingComparator = (
  a: Customer,
  b: Customer,
  orderBy: string
): number => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
};

const getComparator = (order: "asc" | "desc", orderBy: string) =>
  order === "desc"
    ? (a: Customer, b: Customer) => descendingComparator(a, b, orderBy)
    : (a: Customer, b: Customer) => -descendingComparator(a, b, orderBy);

const applySort = (customers: Customer[], sort: Sort): Customer[] => {
  const [orderBy, order] = sort.split("|") as [string, "asc" | "desc"];
  const comparator = getComparator(order, orderBy);
  const stabilizedThis = customers?.map((el, index) => [el, index]);

  stabilizedThis?.sort((a, b) => {
    // @ts-ignore
    const newOrder = comparator(a[0], b[0]);

    if (newOrder !== 0) {
      return newOrder;
    }

    // @ts-ignore
    return a[1] - b[1];
  });

  // @ts-ignore
  return stabilizedThis.map((el) => el[0]);
};

const DisciplineTable: FC<any> = (props) => {
  const { tour, ...other } = props;
  const { t } = useTranslation();
  const location = useLocation();
  const [selectedCustomers, setSelectedCustomers] = useState<string[]>([]);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(rowPerPage);
  const [query, setQuery] = useState<string>("");
  const [sort, setSort] = useState<Sort>(sortOptions[0].value);
  const [filters, setFilters] = useState<any>({
    hasAcceptedMarketing: null,
    isProspect: null,
    isReturning: null,
  });
  const filteredCustomers = applyFilters(tour, query, filters);
  const [orderedDiscipline, setOrderedDiscipline] = useState<Customer[]>(tour);
  const [paginatedDiscipline, setPaginatedDiscipline] = useState<Customer[]>(
    []
  );

  const sortedCustomers = applySort(filteredCustomers, sort);
  const paginatedCustomers = applyPagination(sortedCustomers, page, limit);
  const enableBulkActions = selectedCustomers.length > 0;
  const selectedSomeCustomers =
    selectedCustomers.length > 0 && selectedCustomers.length < tour.length;
  const selectedAllCustomers = selectedCustomers.length === tour.length;
  const navigate = useNavigate();

  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setQuery(event.target.value);
    setPage(0);
  };

  const handleSortChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSort(event.target.value as Sort);
  };

  const handleSelectAllCustomers = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    setSelectedCustomers(
      event.target.checked ? tour.map((customer) => customer.id) : []
    );
  };

  const handlePageChange = (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value, 10));
  };

  useEffect(() => {
    setOrderedDiscipline(tour);
  }, [tour]);
  const [updatePosition] = useMutation(UPDATE_DISCIPLINE_POSITION, {
    refetchQueries: [
      {
        query: DISCIPLINES,
      },
    ],
  });
  const onDragEnd = async (result: DropResult) => {
    if (!result.destination) return;
    const startIndex = result.source.index;
    const endIndex = result.destination.index;
    const newOrderedCategories = Array.from(orderedDiscipline);
    const [removed] = newOrderedCategories.splice(startIndex, 1);
    newOrderedCategories.splice(endIndex, 0, removed);
    setOrderedDiscipline(newOrderedCategories);

    const disciplines = newOrderedCategories.map((category, index) => ({
      id: category.id,
      sortingPosition: index,
    }));
    try {
      await updatePosition({
        variables: {
          disciplines,
        },
      });
    } catch (error) {
      console.error("Error updating ranking categories:", error.message);
    }
  };
  useEffect(() => {
    const startIndex = page * limit;
    const endIndex = startIndex + limit;

    setPaginatedDiscipline(orderedDiscipline.slice(startIndex, endIndex));
  }, [orderedDiscipline, page, limit]);
  useEffect(() => {
    setPaginatedDiscipline(paginatedCustomers);
    setPage(0);
  }, [query]);
  return (
    <>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          gap: 2,
          m: -1,
          pt: 2,
          pb: 5,
          px: 1,
        }}
      >
        <Box>
          <Typography variant="heading1">{t("Disciplines")}</Typography>
        </Box>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            gap: 2,
            m: -1,
            px: 1,
          }}
        >
          <Box>
            <TextField
              size="small"
              name="sort"
              onChange={handleSortChange}
              select
              SelectProps={{ native: true }}
              sx={{ width: { sm: "auto", xs: "100px" } }}
              value={sort}
              variant="outlined"
            >
              {sortOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          </Box>
          <Box
            sx={{
              maxWidth: "100%",
              width: 253,
              display: {
                sm: "block",
                xs: "none",
              },
            }}
          >
            <TextField
              fullWidth
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon fontSize="small" sx={{ color: "black" }} />
                  </InputAdornment>
                ),
              }}
              onChange={handleQueryChange}
              placeholder={t("Search")}
              value={query}
              variant="outlined"
            />
          </Box>
          {location.pathname !== "/dashboard/platform-disciplines" && (
            <Box>
              <Button
                size="small"
                color="primary"
                startIcon={
                  <ReactSVG
                    src="/images/addw.svg"
                    style={{
                      marginTop: "4px",
                      paddingLeft: "5px",
                    }}
                  />
                }
                sx={{ height: "36px", textTransform: "uppercase", mt: -0.3 }}
                variant="contained"
                onClick={() => navigate("/dashboard/disciplines/new")}
              >
                {t("ADD NEW DISCIPLINE")}
              </Button>
            </Box>
          )}

          <Box
            sx={{
              maxWidth: "100%",
              width: "100%",
              display: {
                sm: "none",
                xs: "block",
              },
            }}
          >
            <TextField
              fullWidth
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon fontSize="small" sx={{ color: "black" }} />
                  </InputAdornment>
                ),
              }}
              onChange={handleQueryChange}
              placeholder={t("Search")}
              value={query}
              variant="outlined"
            />
          </Box>
        </Box>
      </Box>
      <Card
        {...other}
        sx={{
          boxShadow: "none",

          borderRadius: "0px",
        }}
      >
        <Scrollbar>
          <Box sx={{ minWidth: 700 }}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Table>
                <TableHead>
                  <TableRow sx={{ borderBottom: "1px solid #EDF1F6" }}>
                    <TableCell
                      size="small"
                      sx={{ maxWidth: "240px", borderBottom: "none" }}
                      padding="checkbox"
                    ></TableCell>
                    <TableCell
                      size="small"
                      sx={{ maxWidth: "240px", borderBottom: "none" }}
                    >
                      <Typography variant="tableHeadFont">
                        {t("Icon")}
                      </Typography>
                    </TableCell>
                    <TableCell
                      size="small"
                      sx={{ maxWidth: "240px", borderBottom: "none" }}
                    >
                      <Typography variant="tableHeadFont">
                        {t("Title")}
                      </Typography>
                    </TableCell>

                    <TableCell
                      size="small"
                      sx={{ maxWidth: "240px", borderBottom: "none" }}
                    >
                      <Typography variant="tableHeadFont">
                        {t("Sport name")}
                      </Typography>
                    </TableCell>

                    {location.pathname !==
                      "/dashboard/platform-disciplines" && (
                      <TableCell
                        size="small"
                        sx={{ maxWidth: "240px", borderBottom: "none" }}
                        align="right"
                      ></TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <Droppable droppableId="sports">
                  {(provided) => (
                    <TableBody
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {paginatedDiscipline.map((item, index) => {
                        const isCustomerSelected = selectedCustomers.includes(
                          item.id
                        );

                        return (
                          <Draggable
                            key={item.id}
                            draggableId={item.id.toString()}
                            index={index + page * limit}
                          >
                            {(provided) => (
                              <TableRow
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                hover
                                key={item.id}
                                selected={isCustomerSelected}
                                sx={{
                                  backgroundColor:
                                    index % 2 === 0 ? "#FBFBFB" : "#FFF",
                                }}
                              >
                                <TableCell
                                  sx={{
                                    borderBottom: "1px solid #EDF1F6",
                                    maxWidth: "240px",
                                  }}
                                  size="small"
                                >
                                  <DragIndicatorIcon sx={{ color: "gray" }} />
                                </TableCell>
                                <TableCell
                                  sx={{
                                    borderBottom: "1px solid #EDF1F6",
                                    maxWidth: "240px",
                                  }}
                                  size="small"
                                >
                                  <Avatar src={item.icon} />
                                </TableCell>

                                <TableCell
                                  sx={{
                                    borderBottom: "1px solid #EDF1F6",
                                  }}
                                  size="small"
                                >
                                  <Typography variant="tableFont">
                                    {item?.title}
                                  </Typography>
                                </TableCell>

                                <TableCell
                                  sx={{
                                    borderBottom: "1px solid #EDF1F6",
                                  }}
                                  size="small"
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: 1,
                                    }}
                                  >
                                    <CustomTooltip title={item?.sport?.title}>
                                      <CustomChip
                                        variant="secondary"
                                        size="small"
                                        square
                                        sx={{ padding: 0 }}
                                      >
                                        <img
                                          src={item?.sport?.icon}
                                          alt=""
                                          style={{
                                            height: "20px",
                                            width: "20px",
                                          }}
                                        />
                                        {/* <Box
                                          sx={{
                                            mask: `url(${item?.sport?.icon}) no-repeat center`,
                                            WebkitMask: `url(${item?.sport?.icon}) no-repeat center`,
                                            backgroundColor: "#0A2540",
                                            maskSize: "20px 20px",
                                            width: "20px",
                                            height: "20px",
                                          }}
                                        /> */}
                                      </CustomChip>
                                    </CustomTooltip>
                                    <Typography variant="tableFont">
                                      {`${item?.sport?.title}`}
                                    </Typography>
                                  </Box>
                                </TableCell>

                                {location.pathname !==
                                  "/dashboard/platform-disciplines" && (
                                  <TableCell
                                    sx={{
                                      borderBottom: "1px solid #EDF1F6",
                                    }}
                                    size="small"
                                    align="right"
                                  >
                                    <ReactSVG
                                      src="/images/pencil.svg"
                                      onClick={() =>
                                        navigate(
                                          `/dashboard/disciplines/edit/${item.id}`
                                        )
                                      }
                                      style={{ cursor: "pointer" }} // Adds a pointer cursor to indicate it's clickable
                                    />
                                  </TableCell>
                                )}
                              </TableRow>
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </TableBody>
                  )}
                </Droppable>
              </Table>
            </DragDropContext>
          </Box>
        </Scrollbar>
        <TablePagination
          component="div"
          count={filteredCustomers.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </Card>
    </>
  );
};

DisciplineTable.propTypes = {
  tour: PropTypes.array.isRequired,
};

export default DisciplineTable;
