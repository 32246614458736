import { Close } from "@material-ui/icons";
import CustomButton from "./CustomButton";

const ClearButton = ({ onClear }: { onClear: () => void }) => {
  const handleClear = (e) => {
    e.stopPropagation();
    onClear();
  };

  return (
    <CustomButton
      variant="outline"
      size="xs"
      shape="circle"
      onClick={handleClear}
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Close sx={{ fontSize: "12px" }} />
    </CustomButton>
  );
};

export default ClearButton;
