import { ApolloClient, InMemoryCache } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "@apollo/client/link/context";
import Cookies from "js-cookie";

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        //  * NEED TO DEFINE WHAT TYPE OF DATA WILL BE MERGED
        alltournament: {
          merge: (existing: any, incoming: any) => incoming,
        },
        leagues: {
          merge: (existing: any, incoming: any) => incoming,
        },
        categories: {
          merge: (existing: any, incoming: any) => incoming,
        },
        tournamentCategories: {
          merge: (existing: any, incoming: any) => incoming,
        },
        federations: {
          merge: (existing: any, incoming: any) => incoming,
        },
        partners: {
          merge: (existing: any, incoming: any) => incoming,
        },
        licenses: {
          merge: (existing: any, incoming: any) => incoming,
        },
        purchasedLicenses: {
          merge: (existing: any, incoming: any) => incoming,
        },
        teams: {
          merge: (existing: any, incoming: any) => incoming,
        },
        rankingPoints: {
          merge: (existing: any, incoming: any) => incoming,
        },
        platformUsers: {
          merge: (existing: any, incoming: any) => incoming,
        },
      },
    },
  },
});

const URL = process.env.REACT_APP_BACKEND_URL;
// const URL = "https://tornated-dev-094f13c08ec4.herokuapp.com/graphql";
// const URL = "https://staging-api.tournated.com/graphql"; // STAGING

// const URL = "http://localhost:3000/graphql"; // local
// const URL = "https://4fr3l05v-3000.inc1.devtunnels.ms/graphql";
// const URL = "https://api.tournated.com/graphql";
// const URL =
//   "https://8a5c-2400-adc5-476-fa00-ec80-2bd1-2d3-6892.ngrok-free.app/graphql"; // Ngrok

const authLink = setContext((_, { headers }) => {
  // * GET THE AUTHENTICATION TOKEN FROM LOCAL STORAGE IF IT EXISTS
  const token = Cookies.get("token");
  // * RETURN THE HEADERS TO THE CONTEXT SO HTTP-LINK CAN READ THEM
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
      "apollo-require-preflight": true,
    },
  };
});

const httpLink = createUploadLink({
  uri: URL,
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
});

export default client;
