import { gql } from "@apollo/client";

const TEAMS = gql`
  query teams($filter: ListTeamInput, $page: Int, $limit: Int) {
    teams(filter: $filter, page: $page, limit: $limit) {
      teams {
        id
        createdAt
        title
        sport {
          id
          title
        }
        club {
          id
          clubName
        }
        discipline {
          id
          title
        }
        country
        status
        image
        members {
          member {
            name
          }
          team {
            title
          }
          role
        }
      }
      total
    }
  }
`;

export default TEAMS;
