import { useRef, useState } from "react";
import type { FC } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
} from "@material-ui/core";
import useAuth from "../../hooks/useAuth";
import CogIcon from "../../icons/Cog";
import UserIcon from "../../icons/User";
import { ReactSVG } from "react-svg";
import { useApolloClient, useQuery } from "@apollo/client";
import {
  FEDERATIONS,
  GET_FEDERATION_BY_EMAIL,
  ORGANIZATION_PLATFORMS,
} from "src/graphql/queries";
import { useTranslation } from "react-i18next";

const AccountPopover: FC = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const { user, logout, organizationId, organizationUserId } = useAuth();
  const [federationData, setFederationData] = useState<any>("");
  const client = useApolloClient();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      await logout();
      navigate("/dashboard");
      client.resetStore();
    } catch (err) {
      console.error(err);
      toast.error("Unable to logout.");
    }
  };
  useQuery(GET_FEDERATION_BY_EMAIL, {
    variables: {
      filter: {
        email: user?.email,
      },
    },
    onCompleted: (useFederation) => {
      setFederationData(useFederation?.findFederation);
    },
  });
  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: "center",
          height: "32px",
          width: "32px",
          borderRadius: "50%",
          overflow: "hidden",
        }}
      >
        <img
          src={
            user?.avatar ??
            "https://img.freepik.com/free-icon/user_318-159711.jpg?t=st=1692340130~exp=1692340730%5B%E2%80%A6%5D924b1b7c050412a0a18473166fc00772c5d26d463bf0462427103a45821a3"
          }
          style={{ objectFit: "cover", height: "32px", width: "32px" }}
        />
        {/* <ReactSVG src="/images/menu-small.svg" /> */}
      </Box>

      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        sx={{
          "& .MuiPopover-paper,.MuiPaper-elevation8": {
            top: "63px !important",
            border: "1px solid #EDF1F6",
          },
        }}
        PaperProps={{
          sx: {
            width: 240,
            boxShadow: "30px 30px 46px 0px #0000001A",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            pt: "20px",
          }}
        >
          <Box
            sx={{
              height: "60px",
              width: "60px",
              borderRadius: "50%",
              overflow: "hidden",
            }}
          >
            <img
              src={
                user?.avatar ??
                "https://img.freepik.com/free-icon/user_318-159711.jpg?t=st=1692340130~exp=1692340730%5B%E2%80%A6%5D924b1b7c050412a0a18473166fc00772c5d26d463bf0462427103a45821a3"
              }
              style={{ objectFit: "cover", height: "60px", width: "60px" }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            pt: "10px",
            pb: "18px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography color="textPrimary" variant="heading18">
            {user?.name} {user?.surname}
          </Typography>
        </Box>
        <Box sx={{ px: "15px" }}>
          <Divider sx={{ borderColor: "#EFEFF0" }} />
        </Box>
        <Box sx={{ mt: 1 }}>
          {!user?.isLeagueManager && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                py: "12px",
                cursor: "pointer",
                px: "15px",
                "&:hover": {
                  ".profile-text": { color: "#FF5733" }, // Change text color on hover
                  background: "#FF57331A",
                  "svg path": {
                    fill: "#FF5733", // Replace with your desired hover color for SVG
                  }, // Change SVG color on hover if supported
                },
              }}
              onClick={() =>
                navigate(
                  `/dashboard/organization-account/edit/${
                    federationData?.id ? federationData?.id : organizationId
                  }?userId=${user?.id}`
                )
              }
            >
              <ReactSVG
                style={{ height: "16px", width: "16px" }}
                src="/static/organization_detail1.svg"
              />
              <Typography
                sx={{ color: "#86909F", ml: 1.5 }}
                variant="tableHeadFont"
                className="profile-text"
              >
                {t("Organization settings")}
              </Typography>
            </Box>
          )}

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              py: "6px",
              cursor: "pointer",
              px: "15px",
              "&:hover": {
                ".profile-text": { color: "#FF5733" }, // Change text color on hover
                background: "#FF57331A",
                "svg path": {
                  fill: "#FF5733", // Replace with your desired hover color for SVG
                }, // Change SVG color on hover if supported
              },
            }}
            onClick={() => navigate(`/dashboard/users/edit/${user?.id}`)}
          >
            <ReactSVG
              style={{ marginTop: "4px" }}
              src="/static/settings1.svg"
            />
            <Typography
              sx={{ color: "#86909F", ml: 1.5 }}
              variant="tableHeadFont"
              className="profile-text"
            >
              {t("User settings")}
            </Typography>
          </Box>
        </Box>

        <Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              py: "6px",
              cursor: "pointer",

              px: "15px",
              "&:hover": {
                ".profile-text": { color: "#FF5733" }, // Change text color on hover
                background: "#FF57331A",
                "svg path": {
                  fill: "#FF5733", // Replace with your desired hover color for SVG
                }, // Change SVG color on hover if supported
              },
            }}
            onClick={handleLogout}
          >
            <ReactSVG src="/static/Logout.svg" style={{ marginTop: "2px" }} />
            <Typography
              sx={{ color: "#86909F", ml: 1.5 }}
              variant="tableHeadFont"
              className="profile-text"
            >
              {t("Logout")}
            </Typography>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default AccountPopover;
