import { gql } from "@apollo/client";

const ORGANIZATION_PLATFORMS = gql`
  query organizationPlatforms($filter: ListOrganizationPlatformsInput!) {
    organizationPlatforms(filter: $filter) {
      id
      federation {
        id
        organizationName
        organizationAbbreviation
        contactEmail
        status
        contactPhone
        logo
        user {
          email
          id
          name
          phone
          role
          surname
          website
        }
        about
        createdAt
      }

      platform {
        id
        title
        platformType
        status
        country
        language
        sports {
          id
          sport {
            id
            title
            icon
          }
          createdAt
        }
        uploadFile
        coverPhoto
        lightLogo
        darkLogo
      }
    }
  }
`;

export default ORGANIZATION_PLATFORMS;
