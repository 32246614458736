import { Box, Typography } from "@material-ui/core";
import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router";
import { ReactSVG } from "react-svg";
import { platformSettingsArray, superDataArray } from "src/constants";
import useAuth from "src/hooks/useAuth";

export default function PlatformMenu({ setMenuName }: any) {
  const navigate = useNavigate();
  const auth = useAuth();
  const refDisplay = useRef(null);
  const handleScroll = () => {
    setTimeout(() => {
      if (refDisplay && refDisplay.current) {
        refDisplay.current.scrollIntoView({
          block: "start",
        });
      }
    }, 0);
  };

  useEffect(() => {
    handleScroll();
  }, []);
  return (
    <div>
      <Box ref={refDisplay} sx={{ height: "10px" }} />
      <Box
        sx={{
          height: "25px",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          width: "100%",
        }}
        onClick={() => {
          setMenuName("");
        }}
      >
        <ReactSVG src="/static/left_angle.svg" style={{ marginTop: "4px" }} />
        <Typography sx={{ color: "#FF5733", ml: "5px" }}>Menu</Typography>
      </Box>
      <Box sx={{ py: "13px", display: { md: "none", xs: "block" } }}>
        <Typography variant="font18" sx={{ color: "#0A2540", px: "12px" }}>
          Platform
        </Typography>
      </Box>
      {[
        {
          icon: "/static/settings1.svg",
          title: "Platform settings",
          path: `/dashboard/platforms/settings/${auth?.platformId}`,
          divider: true,
        },
        ,
        ...platformSettingsArray,
      ].map((item: any, index) => (
        <Box
          key={index}
          sx={{
            py: "7px",
            px: "20px",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            justifyContent: "space-between",
            background: location.pathname === item?.path ? "#FF57331A" : "",
            ".profile-text": {
              color: location.pathname === item?.path && "#FF5733",
            },
            "& .count": {
              color: location.pathname === item?.path && "white",
              background: location.pathname === item?.path && "#FF5733",
            },
            "&:hover": {
              ".profile-text": { color: "#FF5733" }, // Change text color on hover
              background: "#FF57331A",
              "& .count": {
                color: "white",
                background: "#FF5733",
              },
            },
          }}
          onClick={() => {
            navigate(item?.path);
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <Typography
              className="profile-text"
              sx={{
                color: "#0A2540",
                transition: "color 0.3s", // Smooth color transition
                lineHeight: "20px",
              }}
              variant="tableHeadFont"
            >
              {item?.title}
            </Typography>
          </Box>

          {item?.count && (
            <Box
              sx={{
                px: "8px",
                py: "2px",
                borderRadius: "20px",
                background: "#0A25400D",
                fontSize: "10px",
                color: "#0A25408C",
                height: "fit-content",
              }}
              className="count"
            >
              {(item?.sub_Title &&
                auth?.dashboardStats?.[item?.sub_Title.toLowerCase()]) ??
                0}
            </Box>
          )}
        </Box>
      ))}
    </div>
  );
}
