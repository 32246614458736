import {
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  Paper,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Formik } from "formik";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import Scrollbar from "src/components/Scrollbar";
import * as Yup from "yup";
import OtpInput from "react-otp-input";
import { useMutation } from "@apollo/client";
import { UPDATE_USER, VERIFY_CODE } from "src/graphql/mutations";
import toast from "react-hot-toast";
import useAuth from "src/hooks/useAuth";
import useMounted from "src/hooks/useMounted";
import { useNavigate } from "react-router";
import ReactPlayer from "react-player";
import CancelIcon from "@material-ui/icons/Cancel";
import CustomButton from "src/components/customComponents/CustomButton";
import Cookies from "js-cookie";

const useStyles = makeStyles((theme) => ({
  textfield: {
    height: "46px",
    flexShrink: 0,
    borderRadius: "8px",
    "& .MuiOutlinedInput-root": {
      height: "46px",
      flexShrink: 0,
      borderRadius: "8px",
    },
  },
  label: {
    color: "#1B3861",
    fontSize: "14px",
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: 500,
  },
}));
interface RegModalProps {
  isOpen?: boolean;
  setIsOpen?: Dispatch<SetStateAction<boolean>>;
  setOtp?: Dispatch<SetStateAction<string>>;
  otp?: string;
  email?: String;
}

const quickAccess = [
  {
    icon: "/static/documentation.svg",
    title: "Documentation",
    description: "Learn how to get the most out of Tournated",
    type: "redirect",
    link: "https://tournated.gitbook.io/tournated/tournated-portals/tournated-dashboard",
  },
  {
    icon: "/static/submit_ticket.svg",
    title: "Submit a Ticket",
    description: "Need help? Reach out to our support team",
    type: "redirect",
    link: "https://discord.gg/mzhdJAU9NX",
  },
  {
    icon: "/static/contact-support.svg",
    title: "Contact Support",
    description: "Get in touch with us directly",
    type: "open",
    link: "hello@tournated.com",
  },
];
const WelcomModal: FC<RegModalProps> = ({
  isOpen,
  setIsOpen,
  setOtp,
  otp,
  email,
}) => {
  const { t } = useTranslation();
  const { login, user } = useAuth() as any;
  const mounted = useMounted();
  const navigate = useNavigate();
  const [updateuser] = useMutation(UPDATE_USER, {});

  const handleClose = async () => {
    // if (reason && reason == "backdropClick") {
    setIsOpen(false);
    let variables = {
      visitedNewOnboarding: true,
      id: user?.id,
    };
    try {
      let response = await updateuser({ variables });
      console.log("response", response);
      let userData = {
        ...user,
        accessToken: Cookies?.get("token"),
        visitedNewOnboarding: true,
      };
      login(userData);
    } catch (error) {
      return error;
    }

    // }
  };
  const yupObject = {};

  const validation = yupObject;

  const handleQuickAccess = (data) => {
    console.log("type", data);
    if (data?.type === "redirect") {
      window.open(data?.link);
    }
    if (data?.type === "open") {
      window.location.href = `mailto:${encodeURIComponent(data?.link)}`;
    }
  };

  return (
    <>
      <Modal
        open={isOpen}
        onClose={(e, reason) => {
          if (reason !== "backdropClick") {
            setIsOpen(false);
          }
        }}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backdropFilter: "blur(5px)",
        }}
        sx={{
          "& .MuiBox-root": {
            height: "fit-content",
            outline: "none",
          },
        }}
      >
        <Box
          sx={{
            p: 3,
            maxWidth: "500px",
            borderRadius: "16px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            opacity: 1,
            zIndex: 10,
            height: "100%",
          }}
        >
          <Formik
            enableReinitialize
            initialValues={{}}
            validationSchema={Yup.object().shape(validation)}
            onSubmit={async (
              values,
              { resetForm, setErrors, setStatus, setSubmitting }
            ): Promise<void> => {}}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }): JSX.Element => (
              <form onSubmit={handleSubmit}>
                <Paper
                  elevation={12}
                  sx={{
                    width: "100%",
                    maxHeight: "95vh",
                    background: "#FFF",
                    overflow: "auto",
                    "&::-webkit-scrollbar": {
                      width: "7px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      background: "#aaa",
                      borderRadius: "6px",
                      pl: 3,
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                      background: "#a2a2a2",
                    },
                  }}
                >
                  <Scrollbar>
                    {/* <Box
                      sx={{
                        position: "absolute",
                        zIndex: 99999,
                        cursor: "pointer",

                        right: 5,
                        top: 5,
                      }}
                    >
                      <CancelIcon
                        onClick={handleClose}
                        sx={{ color: "white", cursor: "pointer" }}
                      />
                    </Box> */}
                    <Box sx={{ p: { sm: "20px", xs: "14px" } }}>
                      <Box>
                        {/* <ReactPlayer
                        url="https://www.youtube.com/watch?v=288AF-85pwc"
                        playing={true}
                        loop={true}
                        width="100%"
                        height="300px" // Set the desired height
                      /> */}
                        {/* <Box
                          component="video"
                          autoPlay
                          controls
                          width="100%"
                          loop
                          muted
                          sx={{
                            height: { sm: "500px", xs: "180px" },
                            borderRadius: "6px",
                          }}
                        >
                          <source src="https://ik.imagekit.io/fv5kbm1cj/video1.mp4?updatedAt=1738912344805" />
                        </Box> */}

                        <video
                          autoPlay
                          controls={true}
                          width="100%"
                          loop={true}
                          muted
                          style={{
                            objectFit: "cover",
                            height: "200px",
                            borderRadius: "6px",
                          }}
                        >
                          <source src="https://ik.imagekit.io/fv5kbm1cj/video1.mp4?updatedAt=1738912344805" />
                        </video>

                        <Box sx={{ mt: { sm: "15px", xs: "5px" }, px: "9px" }}>
                          <Typography
                            variant="heading5"
                            sx={{ lineHeight: { sm: "24px", xs: "18px" } }}
                          >
                            Welcome to Tournated Dashboard V2!
                          </Typography>
                          <Box sx={{ pt: { sm: "10px", xs: "5px" } }}>
                            <Typography
                              variant="font21"
                              sx={{
                                color: "#666666",
                                lineHeight: { sm: "16px", xs: "10px" },
                                textAlign: "center",
                              }}
                            >
                              We've listened to feedback from 100+ sports
                              managers to enhance your dashboard experience.
                            </Typography>
                          </Box>
                          <Box sx={{ mt: { sm: "15px", xs: "5px" } }}>
                            <Typography
                              variant="font21"
                              sx={{
                                color: "#666666",
                                lineHeight: "16px",
                                textAlign: "center",
                              }}
                            >
                              Now, managing your organization, platform, and
                              operations is more seamless than ever.
                            </Typography>
                          </Box>
                          <Box sx={{ mt: { sm: 2.5, xs: 1.2 } }}>
                            <Typography variant="heading3">
                              Quick access:
                            </Typography>
                            {quickAccess.map((item) => (
                              <Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "start",
                                    mt: { sm: "15px", xs: "7px" },
                                  }}
                                >
                                  <img src={item?.icon} alt="" />
                                  <Box
                                    sx={{
                                      display: "flex",
                                      ml: "10px",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        "&:hover": {
                                          color: "#f95734",
                                          textDecoration: "underline",
                                        },
                                        cursor: "pointer",
                                      }}
                                      variant="heading11"
                                      onClick={() => handleQuickAccess(item)}
                                    >
                                      {item?.title}
                                    </Typography>
                                    <Typography
                                      variant="font21"
                                      sx={{
                                        color: "#666666",
                                        lineHeight: "16px",
                                      }}
                                    >
                                      {item?.description}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                            ))}
                          </Box>
                          <Box>
                            <CustomButton
                              onClick={handleClose}
                              variant="primary"
                              size="medium"
                              sx={{
                                minWidth: "90px",
                                width: "100%",
                                fontWeight: "600",
                                mt: { sm: "35px", xs: "15px" },
                              }}
                            >
                              {"I got it"}
                            </CustomButton>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Scrollbar>
                </Paper>
              </form>
            )}
          </Formik>
        </Box>
      </Modal>
    </>
  );
};

export default WelcomModal;
