import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import useAuth from "src/hooks/useAuth";

const useFederationRedirect = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const checkAndRedirect = (platformId, user) => {
    const tournatedPlatformId = parseInt(
      process.env.REACT_APP_TOURNATED_PLATFORM_ID
    );

    if (platformId === tournatedPlatformId && user?.role === "federation") {
      toast.error("You have no access for tournated platform");
      navigate("/dashboard");
    }
  };

  const IsSuperAdmin = () => {
    if (user?.role !== "superAdmin") {
      navigate("/dashboard");
    }
  };

  return { checkAndRedirect, IsSuperAdmin };
};

export default useFederationRedirect;
