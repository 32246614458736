import moment from "moment";
import toast from "react-hot-toast";
import imageCompression from "browser-image-compression";
import useAuth from "src/hooks/useAuth";

export const parseToJSON = (value) => {
  let parsedValue = null;
  try {
    parsedValue = value ? JSON.parse(value) : null;
  } catch (error) {
    console.warn("Failed to parse parseVaue:", error);
    parsedValue = [];
  }
  return parsedValue;
};

export const getDateDifference = (fromDate, toDate) => {
  const start = moment(fromDate);
  const end = moment(toDate);

  if (start.isAfter(end)) {
    throw new Error("The 'fromDate' must be earlier than the 'toDate'");
  }

  // Calculate differences
  const years = end.diff(start, "years");
  start.add(years, "years");

  const months = end.diff(start, "months");
  start.add(months, "months");

  const days = end.diff(start, "days");

  // Build result string
  const parts = [];
  if (years > 0) parts.push(`${years} year${years > 1 ? "s" : ""}`);
  if (months > 0) parts.push(`${months} month${months > 1 ? "s" : ""}`);
  if (days > 0) parts.push(`${days} day${days > 1 ? "s" : ""}`);

  return parts.length > 0 ? parts.join(", ") : "0 days";
};

export const getTournamentStatus = (
  entryDeadlineDate,
  tournamentStartDate,
  tournamentEndDate,
  tournamentStatus
) => {
  const currentDate = new Date();

  const status = (() => {
    if (tournamentStatus === "inactive") return "inactive";
    if (currentDate <= new Date(entryDeadlineDate)) return "registration";
    // if (currentDate >= entryDeadlineDate && currentDate <= tournamentStartDate) return 'deadline past';
    if (
      currentDate >= new Date(tournamentStartDate) &&
      currentDate <= new Date(tournamentEndDate)
    )
      return "in progress";
    if (currentDate >= new Date(tournamentEndDate)) return "completed";
    return undefined;
  })();

  const getStepTitle = (): string => {
    switch (status) {
      case "registration":
        return "Upcoming";
      case "in progress":
        return "In progress";
      case "completed":
        return "Completed";
      case "inactive":
        return "Inactive";
      default:
        return "Confirmation";
    }
  };

  return getStepTitle();
};

export const Compression = async (file: File, maxSizeMB = 1.5) => {
  const initialQuality = 1.1;

  try {
    const { width, height } = await getImageDimensions(file);

    // Maintain aspect ratio by choosing the larger dimension
    const maxWidthOrHeight =
      Math.max(width, height) > 1200 ? 1200 : Math.max(width, height);

    const options = {
      maxSizeMB,
      maxWidthOrHeight, // Dynamically set based on image dimensions
      useWebWorker: true,
      initialQuality,
      alwaysKeepResolution: true,
    };

    const compressedFile = await toast.promise(
      imageCompression(file, options),
      {
        loading: "Compressing...",
        success: "Successfully compressed.",
        error: "Error compressing the file.",
      }
    );

    return compressedFile;
  } catch (error) {
    console.error("Error during compression:", error);
    throw error;
  }
};

export function prettifyDates(
  startDate: string | moment.Moment,
  endDate: string | moment.Moment,
  timeZone?: string | moment.Moment
) {
  const start = typeof startDate === "string" ? moment(startDate) : startDate;
  const end = typeof endDate === "string" ? moment(endDate) : endDate;

  if (start.isSame(end, "day")) {
    return (
      start.format(
        `MMM D, YYYY${timeZone ? ` HH:mm - ${end.format("HH:mm")}` : ""}`
      ) + (timeZone ? ` ${timeZone}` : "")
    );
  } else if (start.year() !== end.year()) {
    return `${start.format(
      `MMM D, YYYY${timeZone ? ` HH:mm` : ""}`
    )} - ${end.format(`MMM D, YYYY${timeZone ? ` HH:mm` : ""}`)} ${
      timeZone ?? ""
    }`;
  } else if (start.month() !== end.month()) {
    return `${start.format(`MMM D${timeZone ? ` HH:mm` : ""}`)} - ${end.format(
      `MMM D, YYYY${timeZone ? ` HH:mm` : ""}`
    )} ${timeZone ?? ""}`;
  } else {
    return `${start.format(`MMM D${timeZone ? ` HH:mm` : ""}`)} - ${end.format(
      `D, YYYY${timeZone ? ` HH:mm` : ""}`
    )} ${timeZone ?? ""}`;
  }
}

export const handleSetImage = (file, setFunction) => {
  Compression(file[0], 1)
    .then((compressedBlob) => {
      const compressedFile = new File([compressedBlob], file[0].name, {
        type: compressedBlob.type,
      });

      setFunction([compressedFile]);
    })
    .catch((error) => {
      console.error("Error while compressing the file:", error);
    });
};
export const getImageDimensions = (
  file: File
): Promise<{ width: number; height: number }> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const url = URL.createObjectURL(file);

    img.onload = () => {
      resolve({ width: img.width, height: img.height });
      URL.revokeObjectURL(url); // Clean up
    };

    img.onerror = (error) => {
      reject(new Error("Failed to load image."));
    };

    img.src = url;
  });
};

export const isPlatformManager = (auth) => {
  //allow the access if the login user is the member of platform
  const isMemberOfPlatform = auth?.user?.member_of_plateforms?.some(
    (item) => item.platform?.id === auth?.platformId
  );

  // console.log("onboardingData @@", auth?.onboardingData);
  return isMemberOfPlatform;
};

export const isFederationEmail = (auth) => {
  // login user and selected platform user same then allow the access of platform
  const isFederationEmail =
    auth?.user?.email === auth?.platformData?.federation?.user?.email;
  return isFederationEmail;
};

export const isLeagueManager = (auth) => {
  return auth?.user?.isLeagueManager;
};
