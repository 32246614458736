import { gql } from "@apollo/client";

const GRAPH_STATISTICS = gql`
  query allStatsGraphData(
    $date: String
    $platform: Int
    $federationId: Int
    $year: Int
  ) {
    allStatsGraphData(
      date: $date
      platform: $platform
      federationId: $federationId
      year: $year
    )
  }
`;

export default GRAPH_STATISTICS;
