import {
  Autocomplete,
  Box,
  Button,
  Divider,
  InputAdornment,
  ListItem,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router";
import { ReactSVG } from "react-svg";
import useAuth from "src/hooks/useAuth";
import ChevronDown from "src/icons/ChevronDown";
import SearchIcon from "src/icons/Search";
import Cookies from "js-cookie";
import LockIcon from "@material-ui/icons/Lock";
import Scrollbar from "src/components/Scrollbar";
import CustomFormInput from "src/components/customComponents/CustomFormInput";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { useTranslation } from "react-i18next";
import { isLeagueManager } from "src/utils/helper-functions";
import toast from "react-hot-toast";

export default function OrganizationLeagueMenu({
  federationArray,
  setOrganizationsId,
  selectedOrg,
  handleTabsChange,
  currentTab,
  tabs,
  isLeagues,
  searchLeague,
  filteredLeagues,
  setSearchLeague,
  id,
  organizationId,
  organizationUserId,
  sidebarMenu,
  handleSelect,
  setIsPlatformSettings,
  setMenuName,
}: any) {
  const {
    getLeagueId,
    getOrganizationId,
    getPlatformRelationalId,
    getOrganizationUserId,
  } = useAuth();
  const auth = useAuth();
  const navigate = useNavigate();
  const token = Cookies.get("token");
  const { t } = useTranslation();
  const refDisplay = useRef(null);
  const handleScroll = () => {
    setTimeout(() => {
      if (refDisplay && refDisplay.current) {
        refDisplay.current.scrollIntoView({
          block: "start",
        });
      }
    }, 0);
  };

  useEffect(() => {
    handleScroll();
  }, []);

  return (
    <div>
      <Box ref={refDisplay} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          // px: openSideBarState ? "24px" : "8px",
          // overflowY: "auto",
        }}
      >
        <Box
          sx={{
            height: "25px",
            display: { md: "none", xs: "flex" },
            alignItems: "center",
            cursor: "pointer",
            width: "100%",
          }}
          onClick={() => {
            setIsPlatformSettings(false);
            setMenuName("");
          }}
        >
          <ReactSVG src="/static/left_angle.svg" style={{ marginTop: "4px" }} />
          <Typography sx={{ color: "#FF5733", ml: "5px" }}>Menu</Typography>
        </Box>
        <Box sx={{ py: "13px", display: { md: "none", xs: "block" } }}>
          <Typography variant="font18" sx={{ color: "#0A2540", px: "12px" }}>
            {t("Organizations & Leagues")}
          </Typography>
        </Box>
        <Box>
          <Box sx={{ px: "10px" }}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={federationArray || []}
              disabled={isLeagueManager(auth)}
              sx={{
                mt: "15px",
                "& .MuiOutlinedInput-root": {
                  height: "40px",
                  padding: "10px 12px 10px 8px",
                  borderRadius: "8px",
                  background: "#FAFAFA",

                  color: "#0A2540",

                  "& fieldset": {
                    border: "1px solid #EDF1F6",
                  },
                  "&:hover fieldset": {
                    border: "1px solid red",
                    transform: "none",
                  },
                  "&.Mui-focused fieldset": {
                    border: "1px solid red",
                  },
                },

                "& .MuiOutlinedInput-input": {
                  padding: "0px !important",
                },
              }}
              noOptionsText={t("No organization found")}
              onChange={(e, value) => {
                if (value?.id) {
                  setOrganizationsId(value?.id);
                  getOrganizationId(value?.id);
                  getOrganizationUserId(value?.userId);

                  getPlatformRelationalId(value?.relationalId);
                  if (
                    location.pathname.includes(
                      "/dashboard/organization-account/settings/"
                    )
                  ) {
                    navigate(
                      `/dashboard/organization-account/settings/${value?.id}?userId=${value?.userId}`
                    );
                  }
                }
              }}
              popupIcon={<ChevronDown sx={{ color: "#86909F" }} />}
              value={selectedOrg ? selectedOrg : null}
              clearIcon={null}
              getOptionLabel={(option) => `${option?.title} #${option?.id} `}
              renderOption={(props, option) => (
                <ListItem
                  style={{
                    fontSize: "12px",
                    display: "flex",
                    alignItems: "center",
                  }}
                  {...props}
                  key={option?.id}
                >
                  <img
                    src={option?.logo || "/static/tournatedLogo.svg"}
                    alt=""
                    style={{
                      height: "16px",
                      width: "20px",
                      objectFit: "contain",
                      padding: "0px 5px 0px 0px",
                    }}
                  />
                  {option?.title}
                </ListItem>
              )}
              renderInput={(params) => (
                <CustomFormInput
                  placeholder={t("Name of organization")}
                  label={t("Organization")}
                  {...params}
                  sx={{
                    "& .MuiAutocomplete-input": {
                      color: "#86909F !important",
                      fontSize: "12px",
                      fontWeight: "500",
                    },
                  }}
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
            />

            <Tabs
              indicatorColor="primary"
              onChange={handleTabsChange}
              textColor="primary"
              value={currentTab}
              // variant="scrollable"
            >
              {tabs.map((tab) => (
                <Tab
                  disabled={isLeagueManager(auth)}
                  sx={{
                    textTransform: "capitalize",
                    minWidth: "114px !important",
                    width: "auto",
                  }}
                  key={tab.value}
                  label={t(tab.label)}
                  value={tab.value}
                />
              ))}
            </Tabs>
            <Divider sx={{ borderColor: "#EFEFF0" }} />
          </Box>
          <Scrollbar>
            <Box
              sx={{
                height: {
                  md: "calc(100vh - 415px)",
                  xs: "calc(100vh - 320px)",
                },

                // height: "calc(100vh - 415px)",
                // overflowY: "scroll",
                px: "10px",
                "&::-webkit-scrollbar": {
                  width: "6px", // Scrollbar width
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#888", // Scrollbar color
                  borderRadius: "10px", // Rounded corners
                  border: "2px solid transparent", // Add space around thumb for smoother visuals
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  backgroundColor: "#555", // Scrollbar hover effect
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#f0f0f0", // Track color
                  borderRadius: "10px", // Match scrollbar radius
                },
                scrollbarWidth: "thin", // For Firefox
                scrollbarColor: "#888 #f0f0f0",
              }}
            >
              {isLeagues ? (
                <Box>
                  <Button
                    onClick={() => {
                      if (isLeagueManager(auth)) {
                        toast.error("Access denied");
                      } else {
                        navigate(
                          `/dashboard/leagues/new?tab=league&activeTab=settings`
                        );
                      }
                    }}
                    startIcon={
                      <ReactSVG
                        src="/static/add_button.svg"
                        style={{ marginTop: "2px" }}
                      />
                    }
                    sx={{
                      color: "#FF5733",
                      mt: "15px",
                      background: "#FF57331A",
                      width: "100%",
                      height: "40px",
                      borderRadius: "6px",
                    }}
                  >
                    {t("Create new")}
                  </Button>
                  <TextField
                    fullWidth
                    size="small"
                    sx={{
                      py: "15px",
                      "& .MuiOutlinedInput-root": {
                        height: "40px",
                        padding: "8px 12px 10px 15px",
                        borderRadius: "4px",
                        background: "#FAFAFA",
                        color: "#0A2540",

                        "& fieldset": {
                          border: "1px solid #EDF1F6",
                        },
                        "&:hover fieldset": {
                          border: "1px solid red",
                          transform: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "1px solid red",
                        },
                      },
                      "& .MuiOutlinedInput-input": {
                        padding: "0px !important",
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        // @ts-ignore
                        <InputAdornment>
                          <SearchIcon
                            fontSize="small"
                            sx={{
                              color: "black",
                              marginTop: "2px",
                              marginLeft: "-5px",
                              marginRight: "10px",
                            }}
                          />
                        </InputAdornment>
                      ),
                    }}
                    value={searchLeague}
                    onChange={(e) => {
                      setSearchLeague(e.target.value);
                    }}
                    placeholder={t("Search")}
                    variant="outlined"
                  />
                  {filteredLeagues?.length > 0 ? (
                    filteredLeagues.map((item, index) => (
                      <Tooltip
                        key={index}
                        title={
                          `${item?.title} ${
                            item?.status.toLowerCase() === "inactive"
                              ? "(In-active)"
                              : ""
                          }` || ""
                        }
                        placement="right"
                        arrow
                      >
                        <Box
                          sx={{
                            height: "40px",
                            border:
                              item?.id === parseInt(id)
                                ? "1px solid transparent"
                                : "1px solid #EDF1F6",
                            background:
                              item?.id === parseInt(id)
                                ? "#FF57331A"
                                : "#FFFFFF",
                            borderRadius: "6px",
                            px: "10px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            cursor: "pointer",
                            mb: "5px",
                            // opacity:
                            //   item.status.toLowerCase() === "inactive" && 0.3,
                            "svg path": {
                              fill: item?.id === parseInt(id) && "#FF5733",
                            },

                            "&:hover": {
                              background: "#FF57331A",
                              border: "1px solid transparent",
                              "svg path": {
                                fill: "#FF5733",
                              },
                            },
                          }}
                          onClick={() => {
                            navigate(
                              `/dashboard/leagues/edit/${item?.id}?tab=league&activeTab=settings`
                            );
                            getLeagueId(item?.id);
                          }}
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <img
                              src={item?.logo ?? "/images/No_image.png"}
                              alt=""
                              style={{
                                width: "25px",
                                height: "25px",
                                borderRadius: "6px",
                                objectFit: "cover",
                              }}
                            />
                            <Typography
                              variant="tableHeadFont"
                              sx={{
                                color: "#0A2540",
                                ml: "10px",
                                width: "150px",
                                overflow: "hidden",
                                whiteSpace: "nowrap", // Change 'pre' to 'nowrap' for single-line text truncation
                                textOverflow: "ellipsis", // Add this to show the 3 dots
                              }}
                            >
                              {t(item?.title)}
                            </Typography>
                          </Box>
                          {item?.status.toLowerCase() === "inactive" ? (
                            <VisibilityOffIcon sx={{ fontSize: "15px" }} />
                          ) : (
                            // <img
                            //   src="/images/eye-close.svg"
                            //   style={{ height: "15px", width: "15px" }}
                            // />
                            <ReactSVG
                              src="/static/right_angle.svg"
                              style={{ marginTop: "4px" }}
                            />
                          )}
                        </Box>
                      </Tooltip>
                    ))
                  ) : (
                    <Box
                      sx={{
                        height: "40px",
                        border: "1px solid #EDF1F6",
                        borderRadius: "6px",
                        px: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        cursor: "pointer",
                        mb: "5px",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          variant="tableHeadFont"
                          sx={{
                            color: "#0A2540",
                            width: "150px",
                            overflow: "hidden",
                            whiteSpace: "nowrap", // Change 'pre' to 'nowrap' for single-line text truncation
                            textOverflow: "ellipsis", // Add this to show the 3 dots
                          }}
                        >
                          {t("No league found")}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Box>
              ) : (
                <Box sx={{ mt: "5px" }}>
                  {[
                    {
                      icon: "/static/organization_detail1.svg",
                      title: t("Organization details"),
                      path: `/dashboard/organization-account/settings/${organizationId}?userId=${organizationUserId}`,
                    },
                    ...sidebarMenu,
                  ].map((item: any, index) => (
                    <Box sx={{ position: "relative" }}>
                      {item?.title === "Leagues" && (
                        <Box
                          sx={{
                            width: "50px",
                            height: "19px",
                            borderRadius: "1000px",
                            border: "1px solid #FF5733",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: "12px",
                            color: "#FF5733",
                            cursor: "pointer",
                            position: "absolute",
                            right: "54px",
                            zIndex: 999,
                            top: "8px",
                          }}
                          onClick={() =>
                            navigate(
                              "/dashboard/leagues/new?tab=league&activeTab=create_league"
                            )
                          }
                        >
                          {t("Add")}
                          <ReactSVG
                            style={{ marginLeft: "2px" }}
                            src="/static/plus.svg"
                          />
                        </Box>
                      )}
                      {item?.title === "Tournaments" && (
                        <Box
                          sx={{
                            width: "50px",
                            height: "19px",
                            borderRadius: "1000px",
                            border: "1px solid #FF5733",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: "12px",
                            color: "#FF5733",
                            cursor: "pointer",
                            position: "absolute",
                            right: "54px",
                            zIndex: 999,
                            top: "8px",
                          }}
                          onClick={() => {
                            window.open(
                              `${process.env.REACT_APP_TOURNAMENT_URL}/dashboard/all-tournaments/new?leagueId=${auth.leagueId}&token=${token}`
                            );
                          }}
                        >
                          {t("Add")}
                          <ReactSVG
                            style={{ marginLeft: "2px" }}
                            src="/static/plus.svg"
                          />
                        </Box>
                      )}
                      <Box
                        key={item?.title}
                        onClick={() => {
                          handleSelect(index);
                          navigate(item?.path);
                        }} // Set as selected on click
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          px: "12px",
                          height: "35px",
                          mt: "10px",
                          cursor: "pointer",
                          borderRadius: "8px",
                          transition: "background-color 0.3s ease",
                          "svg path": {
                            fill:
                              (location.pathname === item?.path ||
                                (location.pathname.includes("/settings") &&
                                  index === 0)) &&
                              "#FF5733", // Replace with your desired hover color for SVG
                          },
                          backgroundColor:
                            location.pathname === item?.path ||
                            (location.pathname.includes("/settings") &&
                              index === 0)
                              ? "#FF57331A"
                              : "transparent", // Selected background color
                          "& .count": {
                            color:
                              (location.pathname === item?.path ||
                                (location.pathname.includes("/settings") &&
                                  index === 0)) &&
                              "white",
                            background:
                              (location.pathname === item?.path ||
                                (location.pathname.includes("/settings") &&
                                  index === 0)) &&
                              "#FF5733",
                          },
                          ":hover": {
                            backgroundColor: "#FF57331A", // Hover background color
                            "svg path": {
                              fill: "#FF5733", // Replace with your desired hover color for SVG
                            },
                            "& .title-text": {
                              color: "#FF5733", // Hover color for the title
                            },
                            "& .count": {
                              color: "white",
                              background: "#FF5733",
                            },
                          },
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <ReactSVG
                            src={item?.icon}
                            wrapper="span"
                            style={{
                              height: "16px",
                              width: "16px",
                              transition: "fill 0.3s ease", // Smooth transition for fill
                            }}
                          />
                          <Typography
                            className="title-text" // Class added for hover styling
                            sx={{
                              color:
                                location.pathname === item?.path ||
                                (location.pathname.includes("/settings") &&
                                  index === 0)
                                  ? "#FF5733"
                                  : "#86909F",
                              fontSize: "13px",
                              fontWeight: "500",
                              ml: 1.2,
                              lineHeight: "20px",
                            }}
                          >
                            {t(item?.title)}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            position: "relative",
                          }}
                        >
                          {item?.count && (
                            <Box
                              sx={{
                                px: "7px",
                                height: "19px",
                                borderRadius: "20px",
                                background: "#0A25400D",
                                fontSize: "11px",
                                color: "#0A25408C",
                                ml: 1.5,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                transition: "background-color 0.3s ease",
                              }}
                              className="count"
                            >
                              {(item?.title &&
                                auth?.organizationStats?.[
                                  item?.identity.toLowerCase()
                                ]) ??
                                0}
                            </Box>
                          )}
                        </Box>
                      </Box>
                      {item?.divider && (
                        <Divider sx={{ pt: "10px", borderColor: "#EFEFF0" }} />
                      )}
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
          </Scrollbar>
        </Box>
      </Box>
    </div>
  );
}
