import {
  Autocomplete,
  Box,
  ListItem,
  Skeleton,
  Stack,
  Typography,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import React, { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router";
import useAuth from "src/hooks/useAuth";
import ChevronDown from "src/icons/ChevronDown";
import CustomButton from "./customComponents/CustomButton";
import CustomFormInput from "./customComponents/CustomFormInput";
import CustomPopover from "./customComponents/CustomPopover";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { isLeagueManager } from "src/utils/helper-functions";

const PlatformSelect: React.FC<{
  isLoadingParamTournament?: boolean;
  loading?: boolean;
  openSideBarState?: boolean;
  setPage?: Dispatch<SetStateAction<number>>;
  setSearchTitle?: Dispatch<SetStateAction<string>>;
  setHasMore?: Dispatch<SetStateAction<boolean>>;
  platforms?: any;
  getPlatformName?: any;
  setQuery?: any;
  lastOptionElementRef?: any;
}> = ({
  isLoadingParamTournament,
  loading,
  openSideBarState,
  setPage,
  setSearchTitle,
  setHasMore,
  platforms,
  getPlatformName,
  setQuery,
  lastOptionElementRef,
}) => {
  const navigate = useNavigate();
  const {
    logout,
    user,
    getPlatformId,
    getOrganizationId,
    getPlatformRelationalId,
    platformId,
    platformData,
  } = useAuth();
  const { t } = useTranslation();
  const auth = useAuth();

  const handleGetId = (id: number | string, index?: number) => {
    getPlatformId(id);
    let organizationData;
    if (user.role === "superAdmin") {
      organizationData = platforms.find((item) => item?.id === id);
      getPlatformRelationalId(organizationData?.[0]?.id);
    } else {
      organizationData = platforms.find((item) => item?.platform?.id === id);
      getPlatformRelationalId(organizationData?.id);
    }
    if (location.pathname.includes("/dashboard/platforms/settings")) {
      navigate(
        `/dashboard/platforms/settings/${id}?platformActiveTab=general&platformTab=settings`
      );
    }
  };

  return (
    <Stack sx={{ backgroundColor: "#FAFAFA", p: "15px", alignItems: "center" }}>
      {/* <ChevronDown sx={{ color: '#86909F', fontSize: '20px' }} /> */}
      {isLoadingParamTournament ? (
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          sx={{ width: "100%" }}
        >
          <Skeleton
            variant="rectangular"
            width={45}
            height={45}
            sx={{ borderRadius: "4px" }}
          />
          <Stack spacing={0.5} sx={{ flex: 1 }}>
            <Skeleton variant="text" width="80%" height={24} />
            <Skeleton variant="text" width="40%" height={20} />
          </Stack>
        </Stack>
      ) : (
        <CustomPopover
          modalOnMobile
          matchTriggerWidth={openSideBarState}
          popoverProps={{
            sx: {
              "& .MuiPopover-paper": {
                maxHeight: "none",
                height: "250px !important",
                overflow: "visible",
                display: "block",
                minWidth: "210px",
              },
              "& .MuiAutocomplete-paper": {
                maxHeight: "none",
                overflow: "visible",
                display: "block",
                border: "none !important",
                boxShadow: "none !important",
                minWidth: "190px",
              },
              "& .MuiAutocomplete-listbox": {
                maxHeight: "190px !important",
              },
              "& .MuiAutocomplete-popper": {
                transform: "translate(11px, 50px) !important",
                position: "absolute",
                inset: "0px auto auto 0px",
                width: "150px",
                margin: "0px",
              },
            },
          }}
          triggerEl={
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              sx={{
                cursor: "pointer",
                display: "flex",
              }}
            >
              <Box
                component="img"
                sx={{
                  minWidth: "45px",
                  minHeight: "45px",
                  width: "45px",
                  height: "45px",
                  backgroundColor: "#ffff",
                  objectFit: "contain",
                }}
                src={
                  getPlatformName(platformId)?.lightLogo ||
                  "/static/tournatedLogo.svg"
                }
                alt="platform"
              />

              <>
                <Stack spacing={0.5}>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#0A2540",
                      fontWeight: 600,
                      fontFamily: "Eudoxus Sans, sans-serif",
                      width: "120px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap", // Corrected property
                    }}
                  >
                    {getPlatformName(platformId)?.title
                      ? getPlatformName(platformId)?.title
                      : t("Select platform")}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#0A25408C",
                      fontWeight: 500,
                      fontFamily: "Eudoxus Sans, sans-serif",
                    }}
                  ></Typography>
                </Stack>
                <ChevronDown sx={{ color: "#86909F", fontSize: "20px" }} />
              </>
              {/* )} */}
            </Stack>
          }
        >
          {(handleClose) => (
            <Box>
              <Autocomplete
                options={platforms ?? []}
                isOptionEqualToValue={(option, value) =>
                  option?.id === value?.id
                }
                value={getPlatformName(platformId) || null}
                getOptionLabel={(option) => option?.title}
                openOnFocus
                disablePortal
                open={true}
                popupIcon={null}
                clearIcon={null}
                disableClearable
                loading={isLoadingParamTournament}
                noOptionsText={
                  <Typography
                    sx={{
                      textAlign: "center",
                      width: "100%",
                      color: "#0A25408C",
                    }}
                  >
                    {t("No platform found")}
                  </Typography>
                }
                renderOption={(props, option) => (
                  <ListItem
                    {...props}
                    key={option?.id}
                    ref={lastOptionElementRef}
                    sx={{
                      "&.MuiAutocomplete-option": {
                        padding: "8px 8px",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "12px",
                        display: "inline-flex",
                      }}
                    >
                      <img
                        src={option?.lightLogo || "/static/tournatedLogo.svg"}
                        alt=""
                        style={{
                          height: "17px",
                          width: "18px",
                          objectFit: "contain",
                          padding: "0px 5px 0px 0px",
                        }}
                      />
                      <span>{option?.title}</span>
                    </Typography>
                  </ListItem>
                )}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: "#FAFAFA",
                    borderColor: "#EDF1F6",
                    color: "#0A2540",
                  },
                  "& .MuiAutocomplete-listbox": {
                    maxWidth: "400px",
                  },
                }}
                onChange={async (e, value) => {
                  handleGetId(value?.id);
                  Cookies.remove("lang");
                  Cookies.remove("isSkipOnboarding");
                  handleClose(); // Close the CustomPopover
                }}
                renderInput={(params) => (
                  <CustomFormInput
                    {...params}
                    onChange={(e) => {
                      if (e.target.value === "") setHasMore(true);
                      // setSearchTitle(e.target.value);
                      setPage(0);
                    }}
                    variant="outlined"
                    size="medium"
                    placeholder={t("Search")}
                    autoFocus
                  />
                )}
              />
            </Box>
          )}
        </CustomPopover>
      )}
    </Stack>
  );
};

export default PlatformSelect;
