import { TextField, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  textfield: {
    flexShrink: 0,
    borderRadius: "8px",
    background: "#FFF",
    "& .MuiOutlinedInput-root": {
      flexShrink: 0,
      borderRadius: "8px",
      "& .MuiInputBase-input": {
        fontSize: "12px",
        paddingTop: "14px",
      },
    },
  },
  inputLabel: {
    color: "#86909F",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "18px",
  },
}));

interface CustomInputProps {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  helperText?: any;
  error?: any;
  value?: string;
  placeholder: string;
  isRequired?: boolean;
  label?: string;
  name?: string;
  type?: string;
  rows?: any;
  disabled?: any;
  InputProps?: any;
}

export default function CustomInput({
  handleChange,
  helperText,
  error,
  value,
  placeholder,
  isRequired,
  label,
  name,
  type,
  rows,
  disabled,
  InputProps,
}: CustomInputProps) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div>
      {label && <Typography variant="font15">{t(label)}</Typography>}

      <TextField
        error={isRequired && error}
        helperText={isRequired && helperText}
        fullWidth
        placeholder={t(placeholder)}
        name={name}
        onChange={handleChange}
        value={value}
        type={type ?? "text"}
        variant="outlined"
        size="small"
        rows={rows}
        disabled={disabled}
        InputProps={{
          ...InputProps,
          sx: {
            "&::placeholder": {
              color: isRequired && error ? "#d32f2f" : "#86909F",
            },
          },
        }}
        sx={{
          mt: "5px",
          "& .MuiOutlinedInput-root": {
            height: rows ? "" : "40px",
            border:
              isRequired && error ? "1px solid #f44336" : "1px solid #EFEFF0",
            background: "#FBFBFB",
            "&:hover": {
              borderColor: isRequired && error ? "#d32f2f" : "#BDBDBD",
            },
          },
          "& .MuiInputBase-input": {
            color: isRequired && error ? "#d32f2f" : "#415A7C",
            "&::placeholder": {
              color: isRequired && error ? "#f44336" : "#415A7C",
            },
          },
          "& .MuiFormHelperText-root": {
            color: isRequired && error ? "#f44336" : "#415A7C",
          },
        }}
        className={`field_error ${classes.textfield}`}
        InputLabelProps={{
          className: classes.inputLabel,
          sx: {
            color: isRequired && error ? "#d32f2f" : "#415A7C",
          },
        }}
      />
    </div>
  );
}
