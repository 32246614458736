import { gql } from "@apollo/client";

const PURCHASED_LICENSE_BY_ID = gql`
  query purchasedLicense($id: Int!) {
    purchasedLicense(id: $id) {
      id
      paid
      currency
      startDate
      endDate
      noOfLicense
      payment_status
      type
      user {
        id
        name
        email
      }
      license {
        id
        title
      }
    }
  }
`;

export default PURCHASED_LICENSE_BY_ID;
