import React, { Fragment } from "react";
import {
  CircularProgress,
  FormHelperText,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  textfield: {
    flexShrink: 0,
    borderRadius: "8px",
    background: "#FFF",
    "& .MuiOutlinedInput-root": {
      flexShrink: 0,
      height: "40px",
      borderRadius: "8px",
      "& .MuiInputBase-input": {
        fontSize: "12px",
        paddingTop: "14px",
        "&::placeholder": {
          color: (props: any) => (props.error ? "red" : "#86909F"), // Dynamic placeholder color
        },
      },
    },
  },
  inputLabel: {
    color: "#86909F",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "18px",
  },
  helperText: {
    marginTop: "4px", // Adjust spacing above the error message
    marginLeft: "10px",
    marginBottom: "8px", // Add spacing below the error message
    color: "red",
  },
}));

interface Option {
  value: string | number;
  title: string;
}

interface CustomSelectProps {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  helperText?: string;
  error?: boolean;
  value: string | number;
  placeholder?: string;
  isRequired?: boolean;
  label?: string;
  name?: string;
  options?: Option[];
  firstOption?: boolean;
  loading?: boolean;
}

export default function CustomSelectInput({
  handleChange,
  helperText,
  error,
  value,
  placeholder,
  isRequired,
  label,
  name,
  options,
  firstOption,
  loading,
}: CustomSelectProps) {
  const { t } = useTranslation();
  const classes = useStyles({ error });

  return (
    <div>
      {label && <Typography variant="font15">{t(label)}</Typography>}

      <TextField
        select
        error={isRequired && error}
        fullWidth
        placeholder={placeholder ? t(placeholder) : undefined}
        name={name}
        onChange={handleChange}
        value={value}
        variant="outlined"
        size="small"
        sx={{
          mt: "5px",
          fontFamily: "Inter",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "normal",
          borderRadius: "6px",
          border: error ? "1px solid red" : "1px solid #EFEFF0", // Highlight border on error
          background: "#FBFBFB",
          height: "40px",
          "& .MuiInputBase-input": {
            fontSize: "12px",
          },
          "& fieldset": {
            borderWidth: "0px",
            height: "44px",
          },
          "&:hover fieldset": {
            borderWidth: "0px",
          },
          "&.Mui-focused fieldset": {
            borderWidth: "0px",
          },
          "& .MuiOutlinedInput-input": {
            padding: "9px 14px",
          },
        }}
        InputLabelProps={{
          className: classes.inputLabel,
        }}
        SelectProps={{
          native: true,
        }}
        InputProps={{
          endAdornment: (
            <Fragment>
              {loading ? (
                <CircularProgress
                  color="inherit"
                  size={16}
                  sx={{
                    background: "#ffff",
                    zIndex: "99",
                    marginRight: "5px",
                    borderRadius: "50%",
                  }}
                />
              ) : null}
            </Fragment>
          ),
          inputProps: {
            style: {
              color: error ? "red" : undefined, // Optional placeholder style fallback
            },
          },
        }}
      >
        {firstOption && (
          <option value="">
            {placeholder ? t(placeholder) : "Select an option"}
          </option>
        )}

        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.title}
          </option>
        ))}
      </TextField>
      {error && helperText && (
        <FormHelperText className={classes.helperText}>
          {helperText}
        </FormHelperText>
      )}
    </div>
  );
}
