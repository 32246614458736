import { gql } from "@apollo/client";

const UPDATE_PURCHASED_LICENSE = gql`
  mutation updatePurchasedLicense(
    $id: Int!
    $paid: Float!
    $basePrice: Float
    $currency: String!
    $user: Int
    $license: Int!
    $startDate: DateTime!
    $endDate: DateTime!
    $type: String!
    $payment_status: LicensePaymentStatus
  ) {
    updatePurchasedLicense(
      updatePurchasedLicenseInput: {
        id: $id
        paid: $paid
        basePrice: $basePrice
        currency: $currency
        user: $user
        license: $license
        startDate: $startDate
        endDate: $endDate
        type: $type
        payment_status: $payment_status
      }
    ) {
      id
    }
  }
`;

export default UPDATE_PURCHASED_LICENSE;
