import { Box, ButtonBase, Popover, Typography } from "@material-ui/core";
import type { FC } from "react";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { superDataArray } from "src/constants";

const SuperData: FC = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: "center",
          "&:hover": {
            "& .text-class": {
              color: "#FF5733",
            },
            "svg path": {
              fill: "#FF5733",
            },
          },
        }}
      >
        <ReactSVG src="/static/superData.svg" style={{ paddingTop: "2px" }} />
        <Typography
          sx={{ color: "#0A2540", ml: 1, mt: "1px" }}
          variant="tableHeadFont"
          className="text-class"
        >
          {t("SuperData")}
        </Typography>
        <ReactSVG src="/static/Union.svg" style={{ marginLeft: "8px" }} />
      </Box>

      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        sx={{
          "& .MuiPopover-paper,.MuiPaper-elevation8": {
            top: "63px !important",
            border: "1px solid #EDF1F6",
          },
        }}
        PaperProps={{
          sx: {
            width: 240,
            padding: "10px 0px 10px 0px",
            boxShadow: "30px 30px 46px 0px #0000001A",
          },
        }}
      >
        {superDataArray.map((item, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              py: "9px",
              px: "20px",
              background: location.pathname === item?.path ? "#FF57331A" : "",
              ".profile-text": {
                color: location.pathname === item?.path && "#FF5733",
              },
              "&:hover": {
                ".profile-text": { color: "#FF5733" }, // Change text color on hover
                background: "#FF57331A",
                "svg path": {
                  fill: "#FF5733", // Replace with your desired hover color for SVG
                }, // Change SVG color on hover if supported
              },
            }}
            onClick={() => {
              navigate(item?.path);
              handleClose();
            }}
          >
            <Typography
              className="profile-text"
              sx={{
                color: "#0A2540",
                transition: "color 0.3s", // Smooth color transition
              }}
              variant="tableHeadFont"
            >
              {t(item?.title)}
            </Typography>
          </Box>
        ))}
      </Popover>
    </>
  );
};

export default SuperData;
