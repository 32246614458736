import { gql } from "@apollo/client";

const LEAGUES = gql`
  query leagues(
    $federationId: Int
    $filter: ListLeagueInput!
    $relations: [String!]
  ) {
    leagues(
      federationId: $federationId
      filter: $filter
      relations: $relations
    ) {
      title
      type
      status
      sports {
        id
        title
        icon
      }
      platform {
        id
        title
      }
      email
      website
      id
      logo
      abbreviationLogo
      owner {
        federation {
          organizationName
        }
      }
      athlete {
        athlete {
          email
          dob
        }
      }
      tournaments {
        id
      }
    }
  }
`;

export default LEAGUES;
