import { gql } from "@apollo/client";

const ORGANIZATION_NEWS = gql`
  query allFederationNews($filter: ListFederationNewsInput) {
    allFederationNews(filter: $filter) {
      news {
        id
        title
        image
        slug

        platform {
          id
          title
        }

        author {
          id
          email
          name
          surname
        }
      }
    }
  }
`;

export default ORGANIZATION_NEWS;
