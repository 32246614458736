import { Box, Divider, Tooltip, Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { ReactSVG } from "react-svg";
import { financeArray, helpArray, superDataArray } from "src/constants";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import useAuth from "src/hooks/useAuth";

export default function LeaguesMenu({ setMenuName, leagues }: any) {
  const navigate = useNavigate();
  const refDisplay = useRef(null);
  const handleScroll = () => {
    setTimeout(() => {
      if (refDisplay && refDisplay.current) {
        refDisplay.current.scrollIntoView({
          block: "start",
        });
      }
    }, 0);
  };

  useEffect(() => {
    handleScroll();
  }, []);
  const auth = useAuth();

  return (
    <div>
      <Box ref={refDisplay} sx={{ height: "10px" }} />
      <Box
        sx={{
          height: "25px",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          width: "100%",
        }}
        onClick={() => {
          setMenuName("");
        }}
      >
        <ReactSVG src="/static/left_angle.svg" style={{ marginTop: "4px" }} />
        <Typography sx={{ color: "#FF5733", ml: "5px" }}>Menu</Typography>
      </Box>
      <Box sx={{ py: "13px", display: { md: "none", xs: "block" } }}>
        <Typography variant="font18" sx={{ color: "#0A2540", px: "12px" }}>
          Leagues
        </Typography>
      </Box>
      <Box
        sx={{
          height: "calc(100vh - 200px)",
          overflow: "scroll",
        }}
      >
        {leagues.length > 0 ? (
          leagues?.map((item) => (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                py: "7px",
                px: "20px",
                position: "relative",
                ".profile-text": {
                  color: auth?.leagueId === item?.id && "#FF5733",
                },
                background: auth?.leagueId === item?.id && "#FF57331A",

                "&:hover": {
                  ".profile-text": { color: "#FF5733" }, // Change text color on hover
                  background: "#FF57331A",
                  svg: { color: "#FF5733" }, // Change text color on hover
                },
              }}
              onClick={() => {
                navigate(
                  `/dashboard/leagues/edit/${item?.id}?tab=league&activeTab=settings`
                );
                auth?.getLeagueId(item?.id);
                auth?.getPlatformId(item?.platform?.id);
              }}
            >
              {item.status.toLowerCase() === "inactive" && (
                <VisibilityOffIcon
                  sx={{ fontSize: "15px", position: "absolute", right: "20px" }}
                />
              )}

              <Box
                sx={{
                  borderRadius: "1.56px",
                }}
              >
                <img
                  src={item?.logo ?? "/images/No_image.png"}
                  alt=""
                  style={{
                    height: "25px",
                    width: "25px",
                    objectFit: "contain",
                    borderRadius: "1.56px",
                    marginTop: "3px",
                  }}
                />
              </Box>
              <Typography
                className="profile-text"
                sx={{
                  ml: 1.5,
                  color: "#0A2540",
                  transition: "color 0.3s", // Smooth color transition
                  width: "150px",
                  overflow: "hidden",
                  whiteSpace: "nowrap", // Change 'pre' to 'nowrap' for single-line text truncation
                  textOverflow: "ellipsis", // Add this to show the 3 dots
                }}
                variant="tableHeadFont"
              >
                {" "}
                {item?.title
                  ? item?.title?.charAt(0)?.toUpperCase() +
                    item?.title?.slice(1)
                  : ""}
              </Typography>
            </Box>
          ))
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              py: "12px",
              px: "20px",
              position: "relative",

              "&:hover": {
                ".profile-text": { color: "#FF5733" }, // Change text color on hover
                background: "#FF57331A",
              },
            }}
          >
            <Typography
              className="profile-text"
              sx={{
                color: "#0A2540",
                transition: "color 0.3s", // Smooth color transition
                // maxWidth: "10px",
              }}
              variant="tableHeadFont"
            >
              {" "}
              No leagues
            </Typography>
          </Box>
        )}
      </Box>
    </div>
  );
}
