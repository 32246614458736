import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locales/en.json";
import la from "./locales/la.json";
import esp from "./locales/esp.json";
import ru from "./locales/ru.json";
import pt from "./locales/pt.json";
import ja from "./locales/ja.json";
import el from "./locales/el.json";
import ee from "./locales/el.json";
import lt from "./locales/lt.json";
import it from "./locales/it.json";

const resources = {
  en: {
    translation: en,
  },
  la: {
    translation: la,
  },
  esp: {
    translation: esp,
  },
  ru: {
    translation: ru,
  },
  pt: {
    translation: pt,
  },
  ja: {
    translation: ja,
  },
  el: {
    translation: el,
  },
  ee: {
    translation: ee,
  },
  lt: {
    translation: lt,
  },
  it: {
    translation: it,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});
