import { gql } from "@apollo/client";

const ADD_ORGANIZATION_PLATFORM = gql`
  mutation createOrganizationPlatform($platform: Int, $federation: Int) {
    createOrganizationPlatform(
      createOrganizationPlatformInput: {
        platform: $platform
        federation: $federation
      }
    ) {
      id
    }
  }
`;

export default ADD_ORGANIZATION_PLATFORM;
