import React, { useState, useEffect } from "react";
import type { FC } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { Formik } from "formik";

import {
  Box,
  Grid,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useLazyQuery } from "@apollo/client";
import { DICIPLINE_BY_ID, DISCIPLINES, SPORTS } from "src/graphql/queries";
import { useMutation } from "@apollo/client";
import { CREATE_DISCIPLINE, UPDATE_DISCIPLINE } from "src/graphql/mutations";
import { useParams } from "react-router-dom";
import SubmitBanner from "src/components/dashboard/submitBanner";
import { handlePreview } from "src/utils/imagePreview";
import UploadFiles from "../uploadFile";
import useAuth from "src/hooks/useAuth";
import { useTranslation } from "react-i18next";
import { Compression } from "src/utils/helper-functions";
import CustomInput from "src/components/customComponents/CustomInput";
import CustomSelectInput from "src/components/customComponents/CustomSelectInput";

const resultType = [
  {
    title: "Custom metrics",
  },
  {
    title: "Location & Weight fixed",
  },
];
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: "32px",
    maxWidth: "1028px",
    border: "1px solid #EDF1F6",
    borderRadius: "8px",
    marginBottom: "40px",
    padding: theme.spacing(4),
  },
  subHeading: {
    fontWeight: 500,
  },
  mainHeading: {
    color: "#0A2540",
    fontSize: "18px",
    fontWeight: 700,
  },
  textfield: {
    background: "#FBFBFB",
    width: "100%",
    marginTop: 8,
  },
  button: {
    background:
      "linear-gradient(100deg, #FF5733 0.25%, #FD4C26 46.18%, #FA8D76 97.15%)",
  },
}));

const DisciplineForm: FC<any> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { id } = useParams();
  const auth = useAuth();
  const [getDiscipline] = useLazyQuery(DICIPLINE_BY_ID);
  const navigate = useNavigate();
  const [isFound, setIsFound] = useState(id ? true : false);

  const [logo, setLogo] = useState<any[]>([]);
  const [data, setData] = useState<any>("");
  const [sportType, setSportType] = useState<any>();
  const [selectedSport, setSelectedSport] = useState<any>("");

  const [update, { loading: loader }] = useMutation(UPDATE_DISCIPLINE, {
    refetchQueries: [
      {
        query: DISCIPLINES,
      },
    ],
    onCompleted: () => {
      navigate(
        `/dashboard/platforms/settings/${auth?.platformId}?platformActiveTab=disciplines&platformTab=settings`
      );
    },
  });

  const [createDiscipline, { loading }] = useMutation(CREATE_DISCIPLINE, {
    refetchQueries: [
      {
        query: DISCIPLINES,
      },
    ],
    onCompleted: () => {
      navigate(
        `/dashboard/platforms/settings/${auth?.platformId}?platformActiveTab=disciplines&platformTab=settings`
      );
    },
  });
  const [SportsFunction, { data: Sports }] = useLazyQuery(SPORTS);
  useEffect(() => {
    SportsFunction({
      variables: {
        filter: {
          enableDiscipline: true,
        },
      },
    });
  }, []);

  const DisciplineById = async (id: string) => {
    const variables = {
      id: parseInt(id),
    };
    try {
      const { data } = await getDiscipline({ variables });
      setData(data?.discipline);
      setSelectedSport(data?.discipline?.sport?.id);
      await handlePreview(data?.discipline?.icon, setLogo);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (id) {
      DisciplineById(id);
    }
  }, [id]);
  useEffect(() => {
    if (Sports?.sports?.length > 0 && selectedSport) {
      setSportType(
        Sports?.sports?.find((item) => item?.id === parseInt(selectedSport))
      );
    }
  }, [Sports, selectedSport]);
  const handleSetImage = (file, setFunction, avatar) => {
    Compression(file[0], 1)
      .then((compressedBlob) => {
        const compressedFile = new File([compressedBlob], file[0].name, {
          type: compressedBlob.type,
        });
        setFunction(avatar, [compressedFile]);
        // setFunction([compressedFile]);
      })
      .catch((error) => {
        console.error("Error while compressing the file:", error);
      });
  };
  return (
    <Formik
      enableReinitialize={id ? true : false}
      initialValues={{
        title: data?.title ?? "",
        resultType: data?.resultType ?? "",
        icon: logo ?? [],
        sport: data?.sport?.id ?? "",
      }}
      validationSchema={Yup.object().shape({
        title: Yup.string().required(t("title is required")),
      })}
      onSubmit={async (
        values,
        { setErrors, setStatus, setSubmitting }
      ): Promise<void> => {
        if (id) {
          let variables = {
            ...values,
            id: parseInt(id),
            icon: values?.icon[0],
            sport: parseInt(values?.sport),
          };
          try {
            await update({ variables });
            toast.success(t("Discipline Updated"));
          } catch (error) {
            toast.error(error.message);
          }
        } else {
          let variables = {
            ...values,
            icon: values?.icon[0],
            sport: parseInt(values?.sport),
            platform: auth?.platformId,
          };
          try {
            await createDiscipline({ variables });
            toast.success(t("Discipline Created"));
          } catch (error) {
            toast.error(error.message);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        isSubmitting,
        touched,
        values,
      }): JSX.Element => (
        <form onSubmit={handleSubmit} {...props}>
          <SubmitBanner
            isSubmitting={isSubmitting}
            title={t("Add New Discipline")}
            id={id}
            path={`/dashboard/platforms/settings/${auth?.platformId}?platformActiveTab=disciplines&platformTab=settings`}
            SecText={t("Edit Discipline")}
            isFound={isFound}
            loading={loading}
            loader={loader}
            // handleAdd={handleAddMember}
          />

          <Grid container xs={12} md={12} lg={12}>
            <Grid item md={8} xs={12}>
              <Grid item container xs={12} spacing={3}>
                <Grid item md={6} xs={12}>
                  <CustomInput
                    handleChange={handleChange}
                    helperText={touched.title && errors.title}
                    error={Boolean(touched.title && errors.title)}
                    value={values.title}
                    placeholder={"Enter Title"}
                    isRequired={true}
                    label="Discipline title"
                    name="title"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Box>
                    <CustomSelectInput
                      handleChange={(event) => {
                        handleChange(event);
                        setSelectedSport(event.target.value);
                      }}
                      value={values.sport}
                      placeholder="Sport"
                      isRequired={true}
                      label="Select sport"
                      name="sport"
                      options={
                        Sports?.sports.length > 0
                          ? Sports?.sports?.map((item) => {
                              return {
                                value: item?.id,
                                title: item?.title,
                              };
                            })
                          : []
                      }
                      firstOption={true}
                    />
                  </Box>
                </Grid>

                {values?.sport && sportType?.displayListResults ? (
                  <Grid item xs={12}>
                    <Box>
                      <CustomSelectInput
                        handleChange={handleChange}
                        value={values.resultType}
                        placeholder="Results type"
                        isRequired={true}
                        label="Results type"
                        name="resultType"
                        options={
                          resultType.length > 0
                            ? resultType?.map((item) => {
                                return {
                                  value: item?.title,
                                  title: item?.title,
                                };
                              })
                            : []
                        }
                      />
                    </Box>
                  </Grid>
                ) : (
                  ""
                )}
              </Grid>

              <Typography
                className={classes.subHeading}
                sx={{ mt: 2, mb: 1 }}
                variant="body1"
              >
                {t("Icon")}
              </Typography>
              <UploadFiles
                maxFiles={1}
                accept="image/*"
                files={values?.icon}
                onDrop={(file) => handleSetImage(file, setFieldValue, "icon")}
                onRemove={(file) => setFieldValue("icon", [])}
                onRemoveAll={() => setFieldValue("icon", [])}
              />
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default DisciplineForm;
