import { gql } from "@apollo/client";

const USER_BY_ID = gql`
  query getUserById($id: Float) {
    user(query: { id: $id }) {
      email
      accessToken
      id
      name
      surname
      role
      user_sports {
        id
        sport {
          id
          title
        }
      }
      gender
      dob
      nation
      avatar
      phone
      street
      city
      facebook
      instagram
      website
      gtw_points
      preferred_language
      user_coach {
        id
        user {
          id
        }
        coaches {
          id
          user {
            id
            email
          }
        }
      }
      user_parents {
        id
        parent {
          id
          name
          email
        }
      }
      user_club_id {
        id
        clubName
      }
      coach_access
      club_access
      parent_access
      createdAt
      linkedIn
      youtube
      tiktok
      citizenship
      user_address
      fideId
      ltsU10Id
      duprId
      teU12Id
      otherPoints
      pdlId
      ipinId
      teId
      atpId
      wtaId
      bonusU14
      bonusU12
      bonusPoints
      bonusU16

      additionalUserRole
      plans {
        id
        plan
      }
      stripe_payments {
        productId
        status
      }
    }
  }
`;

export default USER_BY_ID;
