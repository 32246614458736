import { useEffect, useRef, useState } from "react";
import type { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import Cookies from "js-cookie";
import { ReactSVG } from "react-svg";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import useAuth from "src/hooks/useAuth";
import GetFlag from "src/utils/getFlag";
import CustomSelect from "../customComponents/CustomSelect";

const languageOptions = {
  en: { icon: "united kingdom", label: "English", value: "English" },
  la: { icon: "latvia", label: "Latviešu", value: "Latvian" },
  esp: { icon: "spain", label: "Español", value: "Spanish" },
  pt: { icon: "portugal", label: "Português", value: "Portuguese" },
  ja: { icon: "japan", label: "日本語", value: "Japanese" },
  et: { icon: "estonia", label: "eesti keel", value: "Estonian" },
  fr: { icon: "france", label: "Français", value: "French" },
  pl: { icon: "poland", label: "Polski", value: "Polish (Polski)" },
  ka: { icon: "Georgia", label: "Georgian", value: "Georgian" },
  ru: { icon: "Russia", label: "Русский", value: "Russian" },
  cs: { icon: "czech republic", label: "Čeština", value: "Czech" }, // Czech language added
  hr: { icon: "croatia", label: "Hrvatski", value: "Croatian" },
  lt: { icon: "lithuania", label: "Lietuvių", value: "Lithuanian" },
  hi: { icon: "india", label: "हिन्दी", value: "Hindi" }, // Hindi added
  hu: { icon: "hungary", label: "Magyar", value: "Hungarian" }, // Hungarian added
  "zh-CN": { icon: "china", label: "Chinese", value: "Chinese" },
  "vi-VN": { icon: "Vietnam", label: "Vietnamese", value: "Vietnamese" },
  ar: { icon: "saudi arabia", label: "العربية", value: "Arabic" },
  it: { icon: "italy", label: "Italiano", value: "Italian" },
  de: { icon: "germany", label: "German", value: "German" }, // Added German language
};

const LanguagePopover: FC = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const { platformLanguages } = useAuth();
  const [filteredLanguages, setFilterdLanguage] = useState({});
  // let filteredLanguages = {};

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleChangeLanguage = (lang: string): void => {
    i18n.changeLanguage(lang);
    Cookies.set("lang", lang);
    setOpen(false);
  };
  useEffect(() => {
    // Filter the language options based on the newLanguage array
    const filtered = Object.keys(languageOptions)
      .filter(
        (key) =>
          platformLanguages &&
          JSON.parse(platformLanguages).includes(languageOptions[key].value)
      )
      .reduce((obj, key) => {
        obj[key] = languageOptions[key];
        return obj;
      }, {});

    setFilterdLanguage(filtered);
    let lang = Cookies.get("lang");
    if (lang) {
      i18n.changeLanguage(lang);
    } else {
      if (filtered) {
        const firstEntry = filtered && Object.entries(filtered)[0];
        if (firstEntry) {
          const [firstKey, firstValue] = firstEntry;
          Cookies.set("lang", firstKey);
          i18n.changeLanguage(firstKey);
        }
      }
    }
  }, [platformLanguages]);
  const selectedOption = filteredLanguages && filteredLanguages[i18n.language];
  const isMobile = useMediaQuery("(max-width:600px)"); //

  return (
    <CustomSelect
      placeholder={t("Language")}
      selectedValue={i18n.language}
      options={Object.keys(filteredLanguages)?.map((language) => ({
        value: language,
        leftIcon: GetFlag(filteredLanguages[language]?.icon, {
          width: "15px",
          height: "15px",
          marginBottom: "-3px",
          marginRight: "0px",
          borderRadius: "6px",
        }),
        title: languageOptions[language]?.label,
      }))}
      optionsButtonProps={{
        variant: "menu",
        size: "small",
        sx: {
          justifyContent: "flex-start",
          alignItems: "center",
          fontWeight: 500,
          color: "#0A2540",
          width: "100%",
        },
      }}
      onChange={(value) => handleChangeLanguage(value?.toString())}
      // onClick={() => handleChangeLanguage(language)}
      buttonProps={{
        variant: "transparent",
      }}
    />
  );
};

export default LanguagePopover;
