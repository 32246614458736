import { gql } from "@apollo/client";

const STATS_PAYMENTS = gql`
  query allStatsTransactionData(
    $platform: Int
    $federationId: Int
    $startDate: String
    $endDate: String
    $currency: String
  ) {
    allStatsTransactionData(
      platform: $platform
      federationId: $federationId
      endDate: $endDate
      startDate: $startDate
      currency: $currency
    )
  }
`;

export default STATS_PAYMENTS;
